















































import Vue, { VueConstructor } from "vue"
import { validationMixin } from "vuelidate"
import { required, minLength, email } from "vuelidate/lib/validators"
import { makeAuthenticatedRequest } from "../../util/makeAuthenticatedRequest"
import { checkRequired } from "../../util/validators/checkRequired"
import EngieConfirmationDialogButton from "../EngieConfirmationDialogButton.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import EngieUserEmailInput from "../forms/EngieUserEmailInput.vue"
import { getUserUrl } from "../../util/urls"
import { getAuthenticatedUserId } from "../../services/authService"
import { UserUpdatePayload } from "../../types/UserUpdatePayload"

export default (Vue as VueConstructor<Vue & typeof validationMixin>).extend({
  components: {
    EngieConfirmationDialogButton,
    EngieUserEmailInput,
    EngieTextInput,
  },
  mixins: [validationMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    initialName: {
      type: String,
      default: "",
    },
    initialJobTitle: {
      type: String,
      default: "",
    },
    initialEmail: {
      type: String,
      default: "",
    },
    initialPhoneNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      updatingUserInformation: false,
      name: "",
      email: "",
      jobTitle: "",
      phoneNumber: "",
      errorOccurredSavingInformation: false,
      usableEmailEntered: true,
    }
  },
  computed: {
    nameErrors(): string[] {
      return checkRequired(this.$v.name)
    },
    emailErrors(): string[] {
      if (!this.$v.email.$dirty) {
        return []
      }

      if (!this.$v.email.required) {
        return ["Please enter an email address"]
      }

      if (!this.$v.email.email) {
        return ["Please enter a valid email address"]
      }

      if (!this.$v.email.notTaken) {
        return ["This email address is already in-use"]
      }

      return []
    },
    phoneNumberErrors(): string[] {
      if (!this.$v.phoneNumber.$dirty) {
        return []
      }

      if (!this.$v.phoneNumber.required) {
        return ["Please enter a phone number"]
      }

      if (!this.$v.phoneNumber.minLength) {
        return ["Please enter a valid phone number"]
      }

      return []
    },
    userUpdatePayload(): UserUpdatePayload {
      return {
        name: this.name,
        email: this.email,
        phoneNumber: this.phoneNumber,
        jobTitle: this.jobTitle,
      }
    },
  },
  methods: {
    resetForm() {
      this.name = this.initialName
      this.email = this.initialEmail
      this.phoneNumber = this.initialPhoneNumber
      this.jobTitle = this.initialJobTitle

      this.updatingUserInformation = false
    },
    handleUpdateUserInformationDialogClosed() {
      this.$v.$reset()

      this.resetForm()

      this.$emit("dialog-closed")
    },
    handleUpdateUserInformationDialogOpened() {
      this.resetForm()
      this.$emit("dialog-opened")
    },
    async onConfirmClicked() {
      this.$v.$touch()
      this.errorOccurredSavingInformation = false

      if (!this.$v.$invalid) {
        this.updateUser()
      }
    },
    handleUnusedEmailEntered() {
      this.$v.email.$touch()
      this.usableEmailEntered = true
    },
    handleExistingEmailEntered() {
      this.$v.email.$touch()
      this.usableEmailEntered = false
    },
    async updateUser() {
      try {
        this.updatingUserInformation = true
        const userId = getAuthenticatedUserId()

        await makeAuthenticatedRequest(getUserUrl(userId!), "PUT", this.userUpdatePayload)

        this.$emit("information-updated", this.userUpdatePayload)
      } catch (error) {
        this.updatingUserInformation = false
        this.errorOccurredSavingInformation = true
      } finally {
        this.updatingUserInformation = false
      }
    },
  },
  validations: {
    name: {
      required,
    },
    phoneNumber: {
      required,
      minLength: minLength(9),
    },
    email: {
      required,
      email,
      notTaken() {
        return this.usableEmailEntered
      },
    },
  },
})
