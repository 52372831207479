











import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { PaginatedResponse } from "@/types/PaginatedResponse"
import { SelectOption } from "@/types/SelectOption"
import { ReferenceDataItem } from "@/types/ReferenceDataItem"
import { getReferenceDataListUrl } from "@/util/urls"
import Vue from "vue"
import EngieSelect from "./EngieSelect.vue"

/**
 * This specialized select component is set up to auto-populate its items through the reference data service. :)
 */
export default Vue.extend({
  components: {
    EngieSelect,
  },
  props: {
    label: {
      type: String,
      default: "Label",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    /**
     * The name of the reference data list you want to pull from, i.e. 'plan-types'
     */
    referenceDataName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [] as SelectOption[],
      value: "",
    }
  },
  created() {
    this.fetchReferenceData()
  },
  methods: {
    async fetchReferenceData() {
      // TODO: handle errors
      const response = await makeAuthenticatedRequest<PaginatedResponse<ReferenceDataItem>>(
        getReferenceDataListUrl(this.referenceDataName)
      )

      this.options = response.items.map((item: ReferenceDataItem) => ({ text: item.displayName, value: item.id }))
      const [firstOption] = this.options
      this.value = firstOption.value
      this.handleChange(firstOption.value)
      this.$emit("default-option-fetched", firstOption.value)
    },
    handleChange(value: string) {
      this.$emit("input", value)
    },
  },
})
