<template>
  <div class="categories-container" style="padding-left: 2rem">
    <div v-if="loading" class="loading-container">
      <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <div v-else>
      <div v-for="department in selectedDepartments" :key="department.id" class="category-checkbox-container">
        <h2 class="category-title">{{ department.name }}</h2>
        <div class="checkbox-wrapper">
          <DisciplineCategoryCheckbox
            v-for="category in department.categories"
            :key="category.id"
            :item="category"
            :checked="isCategoryChecked(category.id)"
            :company-id="companyId"
            item-name="category"
            :delete-fnc="() => deleteCategory(department.id, category.id)"
            @change="handleCategoryChecked($event, category, department)"
            @deleted="() => handleCategoryDeleted(department.id, category.id)"
          />
        </div>
        <EngieButton class="create-dept-btn" @click="handleCreateCategoryDialogOpen(department)">
          Create Category
        </EngieButton>
      </div>
    </div>
    <EngieButton class="submit-btn" :disabled="disabled" color="primary" @click="handleSelectCategory()">
      Next: Confirm
    </EngieButton>
  </div>
</template>

<script>
import EngieButton from "../forms/EngieButton.vue"
import DisciplineCategoryCheckbox from "./DisciplineCategoryCheckbox.vue"
import { deleteCategory } from "../../services/projectService"

export default {
  components: { EngieButton, DisciplineCategoryCheckbox },
  props: {
    selectedDepartments: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    selectedCategories: [],
  }),
  computed: {
    disabled() {
      if (this.selectedCategories.length === 0) {
        return true
      }
      return false
    },
  },
  watch: {
    selectedDepartments() {
      this.selectedCategories = []
    },
  },
  methods: {
    deleteCategory(departmentId, categoryId) {
      return deleteCategory(this.companyId, departmentId, categoryId)
    },
    handleCategoryChecked(checked, category, department) {
      if (checked) {
        const index = this.selectedCategories.findIndex(selectedDepartment => selectedDepartment.id === department.id)
        if (index === -1) {
          this.selectedCategories = [...this.selectedCategories, { ...department, categories: [category] }]
        } else {
          this.selectedCategories[index].categories = [...this.selectedCategories[index].categories, category]
        }
      } else {
        const index = this.selectedCategories.findIndex(selectedDepartment => selectedDepartment.id === department.id)
        this.selectedCategories[index].categories = this.selectedCategories[index].categories.filter(
          selectedCategory => selectedCategory.id !== category.id
        )
        if (this.selectedCategories[index].categories.length === 0) {
          this.selectedCategories = this.selectedCategories.filter(
            selectedDepartment => selectedDepartment !== this.selectedCategories[index]
          )
        }
      }
    },

    isCategoryChecked(value) {
      return this.selectedCategories.some(selectedCategories => selectedCategories.id === value)
    },

    handleSelectCategory() {
      this.$emit("submitted", this.selectedCategories)
    },

    handleCreateCategoryDialogOpen(value) {
      this.$emit("create-dialog-open", value)
    },

    handleCategoryDeleted(departmentId, categoryId) {
      this.$emit("category-deleted", { departmentId, categoryId })
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  max-height: 40rem;
  min-height: 10rem;
  overflow: auto;
  padding-left: 1rem;
}

.categories-container {
  min-height: 53rem;
  width: 100%;
}

.create-dept-btn {
  margin-top: 2rem;
}

.submit-btn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: calc(100% - 12rem);
  bottom: 3rem;
}

.category-title {
  color: var(--black);
  font-weight: 400 !important;
}

.loading-container {
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-hex-value {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 2px;
  margin-right: 1rem;
}

.category-checkbox-container {
  margin-bottom: 2rem;
}

.category-checkbox {
  margin-top: 0;
  padding-top: 0;

  .category-label {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--navy);
    font-weight: 400;
    padding-top: 0.35rem;
  }
}

::v-deep {
  .engie-button .v-btn {
    min-height: 0px !important;
    min-width: 0px !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.75px !important;
    line-height: 1.6rem !important;
    padding: 0 1rem !important;
  }
}
</style>
