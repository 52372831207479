


















import Vue from "vue"
import { required as requiredRule } from "vuelidate/lib/validators"

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    animatedLabel: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: "",
    },
    rowHeight: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: "Label",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      requiredRule,
    }
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    handleBlur(value: Event) {
      this.$emit("blur", value)
    },
  },
})
