


















import Vue from "vue"
import GalleryIcon from "@/components/common_icons/GalleryIcon.vue"
import MapViewIcon from "@/components/common_icons/MapViewIcon.vue"

export default Vue.extend({
  components: {
    GalleryIcon,
    MapViewIcon,
  },
  props: {
    shareSwitch: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      mapView: true,
    }
  },
  created() {
    // if the route is on the ProjectPhotos map, the mapView prop should be true; if not, false.
    if (!this.shareSwitch) {
      this.mapView = this.$route.name === "ProjectPhotoDetailMap"
    } else {
      this.mapView = this.$route.name === "ShareProjectPhotoDetailMap"
    }
  },
  methods: {
    handleClick() {
      if (!this.mapView && !this.shareSwitch) {
        this.$router.push({
          name: "ProjectPhotoDetailGallery",
          params: {
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
            categoryId: this.$route.params.categoryId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      } else if (!this.mapView && this.shareSwitch) {
        this.$router.push({
          name: "ShareProjectPhotoDetailGallery",
          params: {
            companyId: this.companyId,
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
            categoryId: this.$route.params.categoryId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      } else if (this.mapView && !this.shareSwitch) {
        this.$router.push({
          name: "ProjectPhotoDetailMap",
          params: {
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
            categoryId: this.$route.params.categoryId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      } else if (this.mapView && this.shareSwitch) {
        this.$router.push({
          name: "ShareProjectPhotoDetailMap",
          params: {
            companyId: this.companyId,
            projectId: this.$route.params.projectId,
            departmentId: this.$route.params.departmentId,
            categoryId: this.$route.params.categoryId,
          },
        })
        this.$emit("sort-clicked", this.mapView)
      }
    },
    handleIconClick() {
      this.mapView = !this.mapView
      this.handleClick()
    },
  },
})
