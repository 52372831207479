import { getCurrentAuthUserRecord } from "@/services/authService"
import { UserRecord } from "@/types/UserRecord"
import { makeAuthenticatedRequest } from "./makeAuthenticatedRequest"

export async function fetchExternalWorkspaces(userId: string) {
  try {
    const response: any = await makeAuthenticatedRequest(
      `${process.env.VUE_APP_COMPANY_SERVICE_URL}/${userId}/workspaces`,
      "GET"
    )
    return response
  } catch (error) {
    return null
  }
}

export async function checkForUserWorkspaces(userId: string): Promise<any> {
  const workspaces = await fetchExternalWorkspaces(userId)

  const groupByWorkspace = (workspacesArray: any) => {
    return workspacesArray.reduce((acc: any, workspace: any) => {
      if (workspace.homeWorkspace) {
        acc.homeWorkspace = { ...workspace }
        return acc
      }
      acc.externalWorkspaces = [{ ...acc.externalWorkspaces, ...workspace }] || []
      return acc
    }, {})
  }

  const userWorkspaces = groupByWorkspace(workspaces)
  return userWorkspaces
}

export function randomGeneratedColor(stringInput: string) {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    // eslint-disable-next-line no-bitwise
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`
}

export async function resetWorkspace(companyId: any) {
  const user: UserRecord = await getCurrentAuthUserRecord()
  localStorage.removeItem(`currentWorkspace[${user.id}]`)
  const userWorkspaces = await checkForUserWorkspaces(user.id)
  const externalWorkspace = userWorkspaces.externalWorkspaces.filter(
    (workspace: any) => workspace.companyId === companyId
  )
  const color = randomGeneratedColor(externalWorkspace[0].name)
  const textColor = "#ffffff"
  const updatedExternalWorkspace = { ...externalWorkspace[0], color, textColor }
  localStorage.setItem(`currentWorkspace[${user.id}]`, JSON.stringify(updatedExternalWorkspace))
}
