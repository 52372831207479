














































































































































































































































































































































































































































































































































import Vue from "vue"
import PublicInfo from "@/components/PublicInfo.vue"
import EngieDefinition from "@/components/EngieDefinition.vue"

export default Vue.extend({
  components: { PublicInfo, EngieDefinition },
})
