<template>
  <div class="project-content">
    <div v-if="!user" class="wrapper">
      <transition name="route" mode="out-in">
        <router-view
          class="project-view"
          :user="user"
          :user-company-id="userCompanyId"
          :company-id="companyId"
          :project="project"
        ></router-view>
      </transition>
      <div class="copyright">© Copyright {{ currentYear }} EngieCam LLC</div>
    </div>

    <div v-else class="wrapper">
      <MainToolbar />
      <main>
        <SideNav v-if="shouldShowNonSuperAdminDashboard" :is-shared="true" />
        <transition name="route" mode="out-in">
          <router-view
            :user="user"
            :user-company-id="userCompanyId"
            :company-id="companyId"
            :project="project"
          ></router-view>
        </transition>
        <div class="copyright">© Copyright {{ currentYear }} EngieCam LLC</div>
      </main>
    </div>
  </div>
</template>

<script>
import MainToolbar from "../Dashboard/MainToolbar.vue"
import SideNav from "../Dashboard/SideNav.vue"

export default {
  components: { MainToolbar, SideNav },
  props: {
    user: {
      type: Object,
      default: null,
    },
    userCompanyId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    shouldShowNonSuperAdminDashboard() {
      return !this.isLoading && !this.isSuperAdmin
    },
  },
}
</script>

<style lang="scss" scoped>
.project-content {
  width: 100%;
  // height: 100%;
}

.copyright {
  color: var(--white);
  background: var(--navy);
  padding: 0.5rem 1rem;
  z-index: var(--z-index-copyright);
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .toolbar {
    box-shadow: 0 0.1rem 0.4rem #00000039;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    z-index: var(--z-index-main-search-bar);

    .logo-button {
      width: 16rem;
    }

    .log-out-button {
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  main {
    height: calc(100% - 8rem);
    display: flex;
    flex-grow: 1;

    .copyright {
      color: var(--white);
      background: var(--navy);
      padding: 0rem 1rem;
      height: 3.2rem;
      z-index: var(--z-index-copyright);
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>
