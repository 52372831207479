












import Vue from "vue"

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInput(event: Event) {
      this.$emit("input", event)
    },
    handleClose() {
      this.$emit("close")
    },
  },
})
