




import Vue from "vue"

export default Vue.extend({
  props: {
    color: {
      default: "secondary",
      type: String,
    },
  },
  methods: {
    handleClick($event: Event) {
      this.$emit("click", $event)
    },
  },
})
