































import Vue from "vue"
import EngieConfirmationDialogButton from "./EngieConfirmationDialogButton.vue"
import EngieTextInput from "./forms/EngieTextInput.vue"

export default Vue.extend({
  components: {
    EngieConfirmationDialogButton,
    EngieTextInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    confirmDialogTitle: {
      type: String,
      required: true,
    },
    actionButtonLabel: {
      type: String,
      required: true,
    },
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    performingAction: {
      type: Boolean,
      default: false,
    },
    dialogConfirmExplanation: {
      type: String,
      required: true,
    },
    /**
     * When this property is set, the value specified will be required to be typed by the user in a text input to confirm.
     */
    typeConfirmation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      typeConfirmationValue: "",
    }
  },
  computed: {
    typeConfirmationValueUppercased(): string {
      return this.typeConfirmation?.toUpperCase()
    },
    confirmationButtonDisabled(): boolean {
      return (
        this.typeConfirmation !== null &&
        this.typeConfirmationValue.toUpperCase() !== this.typeConfirmationValueUppercased
      )
    },
  },
  methods: {
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleDialogClosed() {
      this.$emit("dialog-closed")
    },
    handleConfirmed() {
      this.$emit("confirmed")
    },
  },
})
