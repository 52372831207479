














import Vue from "vue"
import EngieTextButton from "./forms/EngieTextButton.vue"
import EngieSearchInput from "./forms/EngieSearchInput.vue"
import PageSubheader from "./PageSubheader.vue"

export default Vue.extend({
  components: {
    EngieTextButton,
    EngieSearchInput,
    PageSubheader,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    searchLabel: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleCsvDownload() {
      this.$emit("download-csv")
    },
    handleSearchInput(value: string) {
      this.$emit("input", value)
    },
  },
})
