<template>
  <Page title="View Map">
    <template v-if="isUserAdmin" #toolbar>
      <EngieButton @click="handleAddProjectRouteClick">Add Project</EngieButton>
    </template>

    <div id="map-content" class="map-content">
      <div v-if="loading" class="project-photo-detail-map-loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
      </div>
      <div v-else id="map" class="map-container">
        <div v-if="projects.length === 0" class="no-photos">
          <h3 style="font-weight: 400; margin-top: 35%">Add Projects to View Here</h3>
        </div>
        <div v-else class="google-map-container" style>
          <GoogleMap
            :add-search="true"
            :height="'100%'"
            :markers="projects"
            :map-settings="mapSettings"
            :center-on-markers="true"
            @marker-click="handleMarkerClick"
          />
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue"
import { DEFAULT_LAT_LONG } from "@/constants/defaultLatLong"
import { getCurrentUserCompanyId } from "@/services/userInformationService"
import { getCurrentAuthUserRecord, hasAdminRole } from "@/services/authService"
import EngieButton from "@/components/forms/EngieButton.vue"
import GoogleMap from "@/components/Map/GoogleMap.vue"
import { getAllCompanyProjectsNotPaginated } from "@/services/projectService"

export default {
  components: { Page, EngieButton, GoogleMap },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isUserAdmin: false,
    loading: true,
    projects: null,
    mapContainerHeight: "",
    mapSettings: {
      center: DEFAULT_LAT_LONG,
      zoom: 14,
      streetViewControl: true,
      mapTypeControl: true,
      fullscreenControl: true,
      zoomControl: true,
    },
  }),
  watch: {
    companyId(currentValue) {
      if (currentValue === this.userHomeCompanyId) {
        this.loading = true
        this.initialize()
        return true
      }
      this.initialize()
      this.loading = true
      return false
    },
  },
  mounted() {
    window.addEventListener("resize", this.setPageWrapperHeight)
    this.setPageWrapperHeight()
    this.initialize()
  },
  methods: {
    setPageWrapperHeight() {
      const mapContent = document.querySelector(".page-content-wrapper")
      mapContent.style.height = "100%"
    },

    async fetchCompanyId() {
      const response = await getCurrentUserCompanyId()
      return response
    },
    async checkUserRole() {
      const isUserAdmin = await hasAdminRole()
      return isUserAdmin
    },
    async initialize() {
      if (!this.isExternalUser) {
        this.isUserAdmin = await this.checkUserRole()
        const projects = await this.fetchAllCompanyProjects(this.companyId, "", "false", "")
        const updatedProjects = this.updateProjectsWithConnectedUrls(projects.items)
        this.projects = updatedProjects
        this.loading = false
      } else {
        const user = await getCurrentAuthUserRecord()
        const projects = await this.fetchAllCompanyProjects(this.companyId, `${user.id}`, "false", "")
        const updatedProjects = this.updateProjectsWithConnectedUrls(projects.items)
        this.projects = updatedProjects
        this.loading = false
      }
    },
    handleAddProjectRouteClick() {
      this.$router.push(`/projects/${this.companyId}/add-project`)
    },

    async fetchAllCompanyProjects(companyId, userId, paginated, search) {
      const response = await getAllCompanyProjectsNotPaginated(companyId, userId, paginated, search)
      return response
    },
    updateProjectsWithConnectedUrls(projectsArray) {
      const updatedProjects = projectsArray.map(project => {
        if (project.thumbnailImageUrl) {
          return {
            ...project,
            previewImageUrl: project.thumbnailImageUrl,
          }
        }
        return project
      })
      return updatedProjects
    },
    findheightOfMapContainer() {
      if (!this.loading) {
        const map = document.getElementById("map")
        const mapHeight = map.clientHeight
        return `${mapHeight}px`
      }
      return "200px"
    },
    handleMarkerClick(value) {
      const projectId = value.id
      this.$router.push(`/projects/${projectId}/photos`)
    },
  },
}
</script>

<style lang="scss" scoped>
.map-content {
  height: 100%;
}

.map-container {
  height: 100%;
  width: 100%;
  min-width: 70.7rem;
  min-height: 40rem;
  padding-bottom: 2rem;
  background-color: var(--grey);
}

.google-map-container {
  display: flex;
  height: 100%;
}

.project-photo-detail-map-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.no-photos {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
