

















import Vue from "vue"
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import EngieTextInput from "./EngieTextInput.vue"
import { makeAuthenticatedRequest } from "../../util/makeAuthenticatedRequest"
import { getEmailInUseUrl } from "../../util/urls"

export default Vue.extend({
  components: {
    EngieTextInput,
  },
  props: {
    value: {
      type: [String],
      default: "",
    },
    label: {
      type: String,
      default: "Label",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: null,
    },
    /**
     * When set to true, the form label is initially placed inside the input, with a transform
     * that moves it when the user types.
     */
    animatedLabel: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialValue: "" as String,
      checkingIfEmailExists: false,
      validateEmailIfNecessaryDebounced: debounce(() => {}, 0),
    }
  },
  created() {
    this.initialValue = this.value as String
    this.validateEmailIfNecessaryDebounced = debounce(this.validateEmailIfNecessary, DEBOUNCE_INTERVAL)
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)

      this.validateEmailIfNecessaryDebounced()
    },
    async validateEmailIfNecessary() {
      if (this.value !== this.initialValue) {
        this.checkIfEmailExists()
      } else {
        this.handleEmailMayBeUsed()
      }
    },
    async checkIfEmailExists() {
      this.checkingIfEmailExists = true

      try {
        await makeAuthenticatedRequest(getEmailInUseUrl(this.value), "GET", null, false)
        this.handleEmailExists()
      } catch (error) {
        this.handleEmailMayBeUsed()
      } finally {
        this.checkingIfEmailExists = false
      }
    },
    handleEmailMayBeUsed() {
      this.$emit("unused-email-entered")
    },
    handleEmailExists() {
      this.$emit("existing-email-entered")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
  },
})
