
























import Vue from "vue"
import SearchIcon from "../common_icons/SearchIcon.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"

export default Vue.extend({
  components: {
    SearchIcon,
    CloseIcon,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    showClearButton(): boolean {
      return this.value.length > 0
    },
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    clear() {
      this.$emit("input", "")
    },
  },
})
