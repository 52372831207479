

























































import Vue from "vue"
import { postUserProfilePhoto } from "@/services/userInformationService"
import EngiePlus from "@/components/common_icons/PlusIcon.vue"
import ChangePasswordDialogButton from "./ChangePasswordDialogButton.vue"
import ChangeUserInformationDialogButton from "./ChangeUserInformationDialogButton.vue"
import EngieSuccessSnackbar from "../EngieSuccessSnackbar.vue"
import { UserUpdatePayload } from "../../types/UserUpdatePayload"
import EngieThumbnailUpload from "../forms/EngieThumbnailUpload.vue"

export default Vue.extend({
  components: {
    EngieSuccessSnackbar,
    ChangePasswordDialogButton,
    ChangeUserInformationDialogButton,
    EngieThumbnailUpload,
    EngiePlus,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    jobTitle: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    userProfilePictureUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      changePasswordDialogOpen: false,
      changePasswordSuccessSnackbarOpen: false,
      changeUserInformationDialogOpen: false,
      changeUserInformationSuccessSnackbarOpen: false,
      userProfilePictureString: "",
      userProfilePictureFile: {},
      loading: false,
    }
  },
  methods: {
    async handleImageAdded(value: FormData) {
      this.loading = true
      const profilePhotoFile = value
      try {
        const response = await postUserProfilePhoto(this.userId, profilePhotoFile)
        this.userProfilePictureString = response.profilePictureUrl
        this.$emit("profile-photo-success")
        this.loading = false
      } catch (error) {
        this.$emit("profile-photo-error")
        this.loading = false
      }
    },

    handleChangePasswordDialogClosed() {
      this.changePasswordDialogOpen = false
    },
    handleChangePasswordDialogOpened() {
      this.changePasswordDialogOpen = true
    },
    handleChangeUserInformationDialogClosed() {
      this.changeUserInformationDialogOpen = false
    },
    handleChangeUserInformationDialogOpened() {
      this.changeUserInformationDialogOpen = true
    },
    handlePasswordSaved() {
      this.changePasswordSuccessSnackbarOpen = true
      this.handleChangePasswordDialogClosed()
    },
    handlePasswordUpdatedSuccessSnackbarClosed() {
      this.changePasswordSuccessSnackbarOpen = false
    },
    handleUserInformationUpdatedSuccessSnackbarClosed() {
      this.changeUserInformationSuccessSnackbarOpen = false
    },
    handleUserInformationUpdated(update: UserUpdatePayload) {
      this.changeUserInformationSuccessSnackbarOpen = true

      this.handleChangeUserInformationDialogClosed()

      this.$emit("user-updated", update)
    },
  },
})
