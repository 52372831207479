



























import Vue, { PropType } from "vue"
import { DataTableHeader } from "vuetify"
import EditIcon from "./common_icons/EditIcon.vue"

export default Vue.extend({
  components: {
    EditIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
    },
    freezeFirstColumn: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No results",
    },
    totalCount: {
      type: Number,
      default: null,
    },
    /**
     *  When true, pencil icon buttons are added to each row. These trigger the "edit-row-clicked" event.
     */
    editable: {
      type: Boolean,
      default: null,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headersToRender() {
      return this.editable
        ? [...this.headers, { value: "edit", text: "", sortable: false, align: "center" }]
        : this.headers
    },
  },
  methods: {
    handlePageNumberUpdated(pageNumber: number) {
      this.$emit("page-number-updated", pageNumber)
    },
    handlePageSizeUpdated(pageSize: number) {
      this.$emit("page-size-updated", pageSize)
    },
    handlePageSortDescendingUpdated(sortDescending: boolean[]) {
      this.$emit("page-sort-descending-updated", sortDescending)
    },
    handleEditClicked(itemId: string) {
      this.$emit("edit-row-clicked", itemId)
    },
  },
})
