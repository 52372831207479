














import Vue from "vue"

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: null,
    },
    color: {
      type: String,
      default: "secondary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event: Event) {
      this.$emit("click", event)
    },
  },
})
