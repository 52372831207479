<template>
  <Page :title="'Add Project'">
    <div v-if="loading" class="add-project-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <ProjectInformation
      v-else
      :page-title="'Add Project'"
      :submit-btn-title="'Create Project'"
      :current-user="currentUser"
      @project-submitted="handleCreateProject"
    >
      <template #team-members-container>
        <EngieInternalTeamMembersContainer
          :loading="loading"
          :is-user-admin="true"
          :current-user="currentUser"
          :project-creator="currentUser"
          @intialized="handleTeamMemberInitialized"
          @project-users-selected="handleProjectUsersSelected"
          @project-users-removed="handleProjectUsersSelected"
        >
          <template #title>
            <v-card-title style="padding: 1.8rem 0"><h2 class="header">Team Members</h2></v-card-title>
            <v-divider style="background-color: var(--navy)" />
          </template>
        </EngieInternalTeamMembersContainer>
      </template>
    </ProjectInformation>
    <EngieErrorSnackbar
      v-model="createProjectErrorSnackbarOpen"
      text="An error occured while creating your project"
      @close="handleCreateProjectErrorSnackbarClose()"
    />
  </Page>
</template>

<script>
import { getCurrentAuthUserRecord } from "@/services/authService"
import { createProject, createProjectThumbnail } from "@/services/projectService"
import Page from "@/components/Page.vue"
import EngieErrorSnackbar from "@/components/EngieErrorSnackbar.vue"
import ProjectInformation from "../components/Projects/ProjectInformation.vue"
import EngieInternalTeamMembersContainer from "../components/EngieInternalTeamMembersContainer.vue"

export default {
  components: { ProjectInformation, EngieInternalTeamMembersContainer, Page, EngieErrorSnackbar },
  data: () => ({
    projectUsers: [],
    currentUser: {},
    companyId: "",
    loading: false,
    createProjectErrorSnackbarOpen: false,
  }),
  created() {
    this.initialize()
  },
  methods: {
    async handleCreateProject(value) {
      this.loading = true
      const userIds = this.setProjectUserIds(this.projectUsers)
      const project = { ...value, memberIds: userIds }
      if (project.thumbnailImageId) {
        const updatedProject = await this.createProjectWithThumbnail(project)
        await this.checkForResponseAfterCreatingProject(updatedProject)
        this.loading = false
      } else {
        const updatedProject = project
        delete updatedProject.thumbnailImageId
        await this.checkForResponseAfterCreatingProject(updatedProject)
        this.loading = false
      }
    },

    async createProjectWithThumbnail(project) {
      const thumbnailFile = project.thumbnailImageId
      try {
        const projectThumbnailResponse = await this.handleCreateProjectThumbnailId(thumbnailFile, this.companyId)
        const updatedProject = { ...project, thumbnailImageId: projectThumbnailResponse }
        return updatedProject
      } catch (error) {
        this.createProjectErrorSnackbarOpen = true
        throw new Error("Could not create Project Thumbnail")
      }
    },

    async checkForResponseAfterCreatingProject(project) {
      try {
        const response = await createProject(project, this.companyId)
        this.$emit("project-created")
        this.$router.push("/projects")
        return response
      } catch (error) {
        this.createProjectErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    getFileFormData(file) {
      const formData = new FormData()
      formData.append("photo", file, file?.name)
      return formData
    },

    setProjectUserIds(array) {
      if (array.length > 1) {
        const userIds = array.map(user => user.id)
        return userIds
      }
      return [array[0].id]
    },

    async handleCreateProjectThumbnailId(value, companyId) {
      try {
        const response = await createProjectThumbnail(value, companyId)
        return response.id
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async fetchCurrentUser() {
      const currentUser = await getCurrentAuthUserRecord()
      return currentUser
    },

    async initialize() {
      this.loading = true
      this.currentUser = await this.fetchCurrentUser()
      this.projectUsers = [this.currentUser]
      this.companyId = this.currentUser.companyId
      this.loading = false
    },

    handleCreateProjectErrorSnackbarClose() {
      this.createProjectErrorSnackbarOpen = false
    },

    handleTeamMemberInitialized() {
      this.loading = false
    },

    handleProjectUsersSelected(value) {
      this.projectUsers = value
    },
  },
}
</script>

<style lang="scss" scoped>
.add-project-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.header {
  color: var(--navy);
  line-height: 3.2rem;
  font-weight: 400;
}
</style>
