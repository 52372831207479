<template>
  <v-dialog
    ref="map"
    :retain-focus="false"
    :value="dialogOpen"
    max-width="140rem"
    class="photo-dialog"
    :persistent="true"
    @keydown.esc="closeModal()"
  >
    <div v-if="loading" class="loading-container">
      <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <v-card v-else class="photo-dialog-container">
      <v-card-title class="d-flex justify-space-between photo-dialog-title">
        <v-menu class="menu-container" left offset-y>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" text class="extra-menu" v-on="on"><span class="extra-menu-text">...</span></v-btn>
          </template>
          <v-list class="menu-list">
            <v-list-item v-if="!isShared" class="menu-item d-flex justify-center">
              <v-btn class="menu-btn" depressed @click="handleAddToProject">Add to Project</v-btn>
            </v-list-item>
            <v-list-item v-if="!isShared" class="menu-item d-flex justify-center">
              <v-btn class="menu-btn" depressed @click="handleOpenShareDialog">Share</v-btn>
            </v-list-item>
            <v-list-item class="menu-item d-flex justify-center">
              <v-btn class="menu-btn" depressed @click="handlePhotoDownload">Download</v-btn>
            </v-list-item>
            <v-list-item v-if="!isShared && !isExternalUser" class="menu-item d-flex justify-center">
              <v-btn class="menu-btn" depressed @click="handlePhotoDelete">Delete</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn class="close-btn" icon @click="closeModal()">
          <CloseIcon class="close-icon" />
        </v-btn>
      </v-card-title>
      <div v-if="contentLoading" class="content-loading-container">
        <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
      </div>
      <div v-else class="content-container d-flex">
        <div class="carousel d-flex flex-column">
          <div v-if="isShared === false" class="map-container">
            <UnassignPhotoGoogleMap
              :height="mapContainerHeight"
              :markers="markers"
              :map-settings="mapSettings"
              :updated-center="updatedCenter"
              :draggable="true"
              :zoom="zoom"
              @marker-drag="handleMapMarkerDrag"
              @marker-location-selected="handleOpenConfirmationDialog"
            />
          </div>
          <div v-else class="map-container">
            <UnassignPhotoGoogleMap
              :height="mapContainerHeight"
              :markers="markers"
              :zoom="zoom"
              :map-settings="mapSettings"
              :updated-center="updatedCenter"
              :draggable="false"
            />
          </div>
          <div class="virtual-scroller-container">
            <PhotoCarouselScroll
              :total-photos="totalPhotos"
              :photos="photos"
              :default-photo="defaultPhoto"
              :dialog-open="dialogOpen"
              @end-of-scroll="handleEndOfScroll"
              @selected="handleSelected"
            />
          </div>
        </div>
        <div v-if="photoDetailLoading" class="photo-detail-container">
          <v-progress-circular
            indeterminate
            color="primary"
            style="margin-top: 80%; width: 100%; height: 10%"
            :size="70"
          ></v-progress-circular>
        </div>
        <div v-else class="photo-detail-container">
          <PhotoDetailInformation
            :is-shared="isShared"
            :company-id="companyId"
            :is-external-user="isExternalUser"
            :photo-detail-information="photoDetailInformation"
            @notes-updated="handleNotesUpdated"
            @location-updated="handleLocationUpdated"
          />
        </div>
      </div>
    </v-card>
    <EngieShareDialog
      v-if="!isShared"
      :open="shareDialogOpen"
      :item-type="'Photo'"
      :photos="selectedPhotosForSharing"
      :company-id="companyId"
      @share-submitted="handleShareSubmitted"
      @close="handleCloseShareDialog"
    />
    <EngieConfirmationDialogButton
      :open="confirmationDialogOpen"
      :dialog-title="`Confirm Location`"
      :narrower="true"
      style="display: none"
      @confirmed="handleNewMarkerLocation"
      @dialog-closed="handleCloseConfirmationDialog"
    >
      <div class="confirmation-content-container">
        <div v-if="markerToUpdate" style="width: 70%; padding-left: 10%">
          <h3 style="font-weight: 400">Address:</h3>
          <p class="location-detail address">{{ `${markerToUpdate.address}` }}</p>

          <h3 style="font-weight: 400">Latitude:</h3>
          <p class="location-detail">{{ `${markerToUpdate.latitude}` }}</p>

          <h3 style="font-weight: 400">Longitude:</h3>
          <p class="location-detail" style="margin-bottom: 2rem">{{ `${markerToUpdate.longitude}` }}</p>
        </div>
      </div>
    </EngieConfirmationDialogButton>
  </v-dialog>
</template>

<script>
import { getPhotoViewerInformation, getPhotoZipFile, updatePhotoInformation } from "@/services/photoService"
import { downloadImage } from "@/util/downloadImage"
import CloseIcon from "../common_icons/CloseIcon.vue"
import PhotoDetailInformation from "./PhotoDetailInformation.vue"
import PhotoCarouselScroll from "./PhotoCarouselScroll.vue"
import UnassignPhotoGoogleMap from "../Map/UnassignPhotoGoogleMap.vue"
import EngieConfirmationDialogButton from "../EngieConfirmationDialogButton.vue"
import EngieShareDialog from "../EngieShareDialog.vue"

const ZOOM_LEVEL_NO_MARKER = 14
const ZOOM_LEVEL_MARKER = 18

export default {
  components: {
    CloseIcon,
    PhotoDetailInformation,
    PhotoCarouselScroll,
    UnassignPhotoGoogleMap,
    EngieConfirmationDialogButton,
    EngieShareDialog,
  },
  props: {
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    photos: {
      type: Array,
      default: () => [],
    },
    defaultPhoto: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: String,
      default: "",
    },
    isShared: {
      type: Boolean,
      default: false,
    },
    totalPhotos: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    errorOccurred: false,
    selectedPhoto: null,
    mapLoading: false,
    contentLoading: true,
    photoDetailLoading: false,
    photoDetailInformation: {},
    mapContainerHeight: "100%",
    photoZipFile: null,
    updatedCenter: null,
    confirmationInput: "",
    confirmationDialogOpen: false,
    markerToUpdate: null,
    shareDialogOpen: false,
    selectedPhotosForSharing: [],
    zoom: ZOOM_LEVEL_NO_MARKER,
  }),
  computed: {
    loading() {
      if (this.dialogOpen === true) {
        return false
      }
      return true
    },
    mapSettings() {
      if (this.markers.length !== 0) {
        return {
          center: { lat: this.markers[0].latitude, lng: this.markers[0].longitude },
          streetViewControl: true,
          mapTypeControl: true,
          fullscreenControl: true,
          zoomControl: true,
          zoom: this.zoom,
        }
      }
      return null
    },
    markers() {
      if (this.photoDetailInformation) {
        return [this.photoDetailInformation]
      }
      return []
    },
  },
  watch: {
    dialogOpen(open) {
      if (open) {
        this.initialize()
      }
    },
  },
  methods: {
    closeModal() {
      this.selectedPhoto = null
      this.$emit("close")
    },
    setZoomAndCenter() {
      if (this.photoDetailInformation.latitude !== null && this.photoDetailInformation.longitude !== null) {
        this.zoom = ZOOM_LEVEL_MARKER
        this.updatedCenter = {
          latitude: this.photoDetailInformation.latitude,
          longitude: this.photoDetailInformation.longitude,
        }
      } else {
        this.zoom = ZOOM_LEVEL_NO_MARKER
      }
    },
    async handleSelected(value) {
      this.photoDetailLoading = true
      this.selectedPhoto = value
      this.photoDetailInformation = await this.fetchPhotoViewerInformation(this.companyId, value)

      this.setZoomAndCenter()

      this.photoDetailLoading = false
    },

    async fetchPhotoViewerInformation(companyId, photoId) {
      try {
        const response = await getPhotoViewerInformation(companyId, photoId)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async initialize() {
      this.photoDetailInformation = await this.fetchPhotoViewerInformation(this.companyId, this.defaultPhoto.id)
      this.selectedPhoto = this.photoDetailInformation.id
      this.setZoomAndCenter()
      this.contentLoading = false
    },

    async updateDetailPhotoInformation(companyId, photoId, photoData) {
      try {
        const response = await updatePhotoInformation(companyId, photoId, photoData)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async handleNewMarkerLocation() {
      const photoData = { latitude: this.markerToUpdate.latitude, longitude: this.markerToUpdate.longitude }
      await this.updateDetailPhotoInformation(this.companyId, this.markerToUpdate.id, photoData)
      this.photoDetailInformation = await this.fetchPhotoViewerInformation(this.companyId, this.markerToUpdate.id)
      this.markerToUpdate = null
      this.handleCloseConfirmationDialog()
      this.$emit("photo-location-updated")
    },

    async handleNotesUpdated(value) {
      const photoData = { notes: value }
      await this.updateDetailPhotoInformation(this.companyId, this.photoDetailInformation.id, photoData)
      await this.resetPhotoDetail()
      this.$emit("photo-notes-updated")
    },

    async resetPhotoDetail() {
      this.photoDetailLoading = true
      const photoInformation = await this.fetchPhotoViewerInformation(this.companyId, this.photoDetailInformation.id)
      this.photoDetailInformation = photoInformation
      this.photoDetailLoading = false
    },

    getSelectedPhotoOrDefault() {
      return this.selectedPhoto === null ? this.defaultPhoto.id : this.selectedPhoto
    },

    async handleLocationUpdated(latLong) {
      const { latitude, longitude } = latLong
      this.photoDetailLoading = true

      await this.updateDetailPhotoInformation(this.companyId, this.photoDetailInformation.id, latLong)

      this.photoDetailInformation = { ...this.photoDetailInformation, latitude, longitude }
      this.$emit("photo-updated")
      this.photoDetailLoading = false
    },

    async handleMapMarkerDrag(value) {
      const photoData = { latitude: value.latitude, longitude: value.longitude }
      await this.updateDetailPhotoInformation(this.companyId, value.id, photoData)
      this.$emit("photo-location-updated")
    },

    handleAddToProject() {
      this.$emit("assign-from-carousel", this.photoDetailInformation.id)
    },

    async fetchPhotoZipFile(companyId, photoIds) {
      try {
        const response = await getPhotoZipFile(companyId, photoIds)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async handlePhotoDownload() {
      downloadImage(this.photoDetailInformation.originalImageUrl)
    },

    handlePhotoDelete() {
      this.$emit("photo-deleted", this.getSelectedPhotoOrDefault())
    },

    handleOpenConfirmationDialog(value) {
      this.confirmationDialogOpen = true
      this.markerToUpdate = value
    },

    handleCloseConfirmationDialog() {
      this.markerToUpdate = null
      this.confirmationDialogOpen = false
    },

    handleOpenShareDialog() {
      this.selectedPhotosForSharing = [this.getSelectedPhotoOrDefault()]
      this.shareDialogOpen = true
    },

    handleCloseShareDialog() {
      this.selectedPhotosForSharing = []
      this.shareDialogOpen = false
    },

    handleShareSubmitted() {
      this.$emit("share-submitted")
    },

    handleEndOfScroll() {
      this.$emit("end-of-scroll")
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-dialog-container {
  width: 100%;
  background-color: white;
  height: 90vh;
}

.photo-dialog-title {
  height: 5.2rem;
  width: 100%;
  padding: 0 1.7rem !important;

  .extra-menu {
    margin-left: 1.7rem !important;
    padding: 0 0.8rem;
    position: relative !important;
    color: var(--navy);
    font-size: 9rem;
    letter-spacing: -3px;

    .extra-menu-text {
      position: relative;
      bottom: 1rem;
    }

    &::v-deep {
      .v-btn__content {
        padding: 0 1rem 2.7rem 0 !important;
      }
    }
  }

  .v-menu__content,
  .menuable__content__active {
    contain: none !important;
    box-shadow: none !important;
  }

  .menu-list {
    padding: 0 !important;
    color: var(--navy) !important;
    box-shadow: none !important;
    margin-right: 1rem;
    border: 2px solid var(--light-grey);
    &::v-deep {
      box-shadow: none !important;
      .v-menu__content {
        box-shadow: none;
      }
    }
  }
}

.v-list-item--active::before {
  display: none;
}

.v-list-item {
  padding: 0rem 0rem !important;
  min-height: 0px;
}

.menu-btn {
  color: var(--navy);
  background-color: white !important;
  transition: 0.15s;
  font-size: 1.4rem;
  font-weight: 600;
  width: 100% !important;
  letter-spacing: 0.3px;
  line-height: 1.4rem;
  text-transform: none;
  display: flex;
  justify-content: left;
  &:before {
    display: none;
  }
}

.menu-btn:hover {
  background-color: var(--navy) !important;
  color: white !important;
}

.content-container {
  height: calc(100% - 5.2rem);

  .carousel {
    background-color: var(--light-grey);
    display: flex;
    width: calc(100% - 32.3rem);

    .map-container {
      height: 100%;
      width: 100%;
      flex: 1;
    }

    .virtual-scroller-container {
      height: 9.2rem;
    }
  }
}

.content-loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-detail-container {
  background-color: white;
  width: 38.3rem;
  padding: 3rem 3rem 3rem 3rem;
}

.photo-detail-loading {
  width: 100%;
  height: calc(100% - 9.2rem);
}

.v-card__text {
  padding-top: 2rem !important;

  .loading-container {
    display: flex;
    justify-content: center;
  }
}

.error-enter-active,
.error-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.error-enter,
.error-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 0.5rem;
  }

  .location-detail {
    line-height: 2rem;
    padding: 0.5rem 0;
    font-weight: 500;
    color: var(--navy);
    width: 80%;
    text-align: center;
  }

  .address {
    margin-left: 3rem;
    width: 60%;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
