<template>
  <div class="item-checkbox-container">
    <v-checkbox
      color="primary"
      :on-icon="'mdi-check-circle'"
      :off-icon="'mdi-checkbox-blank-circle-outline'"
      class="item-checkbox"
      :input-value="checked"
      :disabled="loading"
      @change="handleItemChanged($event)"
    >
      <template #label>
        <div class="item-label">
          <h4>{{ item.name }}</h4>
        </div>
      </template>
    </v-checkbox>
    <div class="delete-container">
      <v-tooltip right :disabled="item.containsNoPhotosInCompany">
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              class="delete-btn"
              :disabled="!item.containsNoPhotosInCompany"
              icon
              @click="() => handleItemDeleteClicked()"
            >
              <CloseIcon class="delete-icon" />
            </v-btn>
          </div>
        </template>
        <span>This {{ itemName }} cannot be deleted because photos have been assigned to it.</span>
      </v-tooltip>
      <transition name="delete-prompt">
        <div v-if="deletePromptVisible" class="delete-confirm-container">
          <span>Are you sure you want to delete this {{ itemName }}?</span>
          <v-btn
            class="confirm-delete-btn"
            text
            color="red"
            :disabled="loading"
            @click="() => handleConfirmDeleteClicked()"
          >
            Delete
          </v-btn>
          <v-btn class="cancel-delete-btn" text :disabled="loading" @click="() => handleCancelDeleteClicked()">
            Cancel
          </v-btn>
          <div v-if="loading" class="mr-2 spinner">
            <v-progress-circular indeterminate color="primary" :size="20"></v-progress-circular>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CloseIcon from "../common_icons/CloseIcon.vue"

export default {
  components: { CloseIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: null,
    },
    itemName: {
      type: String,
      required: true,
    },
    deleteFnc: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    deletePromptVisible: false,
    loading: false,
  }),
  methods: {
    handleItemChanged(checked) {
      this.$emit("change", { checked, item: this.item })
    },
    handleItemDeleteClicked() {
      this.deletePromptVisible = true
    },
    async handleConfirmDeleteClicked() {
      this.loading = true

      try {
        await this.deleteFnc()

        this.$emit("deleted", this.item.id)
        this.loading = false
        this.deletePromptVisible = false
      } catch (error) {
        this.loading = false
      }
    },
    handleCancelDeleteClicked() {
      this.deletePromptVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.item-checkbox-container {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1rem;
  height: 3rem;

  .delete-container {
    display: flex;
    margin-left: 1rem;
  }

  .delete-icon {
    width: 1.4rem;
  }

  .delete-confirm-container {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    .confirm-delete-btn {
      margin-left: 1rem;
    }
  }
  .item-checkbox {
    margin-top: 0;
    padding-top: 0;
    display: flex;

    .item-label {
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: var(--navy);
      font-weight: 400;
      display: flex;
      align-items: center;
      padding: 1rem;

      h4 {
        width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2rem;
        display: block;
        white-space: nowrap;
      }
    }
  }
}

.delete-prompt-enter-active,
.delete-prompt-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.delete-prompt-enter,
.delete-prompt-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}

.loading-prompt-enter-active,
.loading-prompt-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.loading-prompt-enter,
.loading-prompt-leave-to {
  opacity: 0;
}
</style>
