<template>
  <div>
    <div v-if="loading" class="project-category-photo-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else class="container" style="">
      <div class="d-flex flex-column header-container" style="height: 16rem">
        <div class="d-flex justify-space-between align-center" style="padding: 0 3rem; height: 5rem">
          <h2 class="header">
            <router-link class="header-link" to="" @click.native="handleProjectRouteClick">{{
              header.project
            }}</router-link>
            >
            <router-link class="header-link" to="" @click.native="handleDepartmentRouteClick">{{
              header.department
            }}</router-link>
            {{ header.category }}
          </h2>
          <ProjectPhotoDetailSwitch :company-id="companyId" :share-switch="true" />
        </div>
        <v-divider style="width: 100%; margin-top: 1rem" />
        <div class="search-toolbar">
          <EngieSearchInput class="search" :label="'Through Notes'" @input="debouncedHandler" />
          <ProjectPhotoDetailSelect
            v-if="selectedCategory"
            :categories="categories"
            :selected-category="selectedCategory"
            :search-loading="loading"
            @submit="handlePhotoDetailSelectSubmit"
          />
        </div>
      </div>
      <div class="project-photo-detail-content-container">
        <transition name="route" mode="out-in">
          <div v-if="routerLoading" class="project-category-photo-loading-spinner-container">
            <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
          </div>
          <router-view
            v-else
            :markers="markers"
            :photos="photos"
            :search-loading="loading"
            :company-id="companyId"
            :user-home-company-id="userHomeCompanyId"
            :is-external-user="isExternalUser"
            :project="project"
            :departments="departments"
            :selected-categories="selectedCategories"
            :selected-department="selectedDepartment"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectPhotoDetailSwitch from "@/components/ProjectPhotoDetail/ProjectPhotoDetailSwitch.vue"
import EngieSearchInput from "@/components/forms/EngieSearchInput.vue"
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import ProjectPhotoDetailSelect from "@/components/ProjectPhotoDetail/ProjectPhotoDetailSelect.vue"
import { getProjectMarkersByCategory } from "@/services/projectService"

export default {
  components: {
    ProjectPhotoDetailSwitch,
    EngieSearchInput,
    ProjectPhotoDetailSelect,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedDepartment: {
      type: Object,
      default: () => ({}),
    },
    selectedCategory: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: true,
    photoSearchInput: "",
    selectedCategories: null,
    headerCategory: "",
    markers: [],
    photos: [],
    routerLoading: false,
    header: "",
  }),
  computed: {
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },
  },
  created() {
    this.debouncedHandler = debounce(async event => {
      this.routerLoading = true
      await this.searchProjectPhotos(event)
    }, DEBOUNCE_INTERVAL)
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async fetchMarkerInformation(
      companyId,
      projectId,
      sortByField,
      sortDirection,
      paginated,
      categoryIdsArray,
      departmentId,
      noCategory,
      search
    ) {
      try {
        if (search) {
          const response = await getProjectMarkersByCategory(
            companyId,
            projectId,
            sortByField,
            sortDirection,
            paginated,
            categoryIdsArray,
            departmentId,
            noCategory,
            search
          )
          return response
        }
        const response = await getProjectMarkersByCategory(
          companyId,
          projectId,
          sortByField,
          sortDirection,
          paginated,
          categoryIdsArray,
          departmentId,
          noCategory
        )
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async setMarkers() {
      // check to see if selectedCategoryIdsArray contains "No Category"
      const selectedCategoryIdsArray = this.selectedCategories.filter(category => category.id !== "No category")

      // if selectedCategoryIdsArray === null, it means only No Category was selected.
      if (selectedCategoryIdsArray < 1) {
        const noCategoryMarkers = await this.fetchMarkerInformation(
          this.companyId,
          this.project.id,
          "createdDateTime",
          "desc",
          "false",
          "",
          this.$route.params.departmentId,
          true,
          this.photoSearchInput
        )
        return noCategoryMarkers.items
      }

      // if selectedCategoryIds array is the same length as the selected category, no category isn't selected
      if (selectedCategoryIdsArray.length === this.selectedCategories.length) {
        const idArray = selectedCategoryIdsArray.map(category => category.id)
        const markers = await this.fetchMarkerInformation(
          this.companyId,
          this.project.id,
          "createdDateTime",
          "desc",
          "false",
          idArray,
          null,
          null,
          this.photoSearchInput
        )
        return markers.items
      }
      // if selectedCategoryIdsArray exists, but is a different length than selectedCategories, no category is selected with others
      const idArray = selectedCategoryIdsArray.map(category => category.id)
      const markers = await this.fetchMarkerInformation(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false",
        idArray,
        false,
        null,
        this.photoSearchInput
      )

      const noCategoryMarkers = await this.fetchMarkerInformation(
        this.companyId,
        this.project.id,
        "createdDateTime",
        "desc",
        "false",
        "",
        this.$route.params.departmentId,
        true,
        this.photoSearchInput
      )

      return [...noCategoryMarkers.items, ...markers.items]
    },

    setNames() {
      if (this.selectedCategories) {
        if (this.selectedCategories.length > 1) {
          return { project: `All Departments`, department: `${this.selectedDepartment.name}` }
        }
        return {
          project: `All Departments`,
          department: `${this.selectedDepartment.name}`,
          category: ` > ${this.selectedCategories[0].name}`,
        }
      }
      return {
        project: `All Departments`,
        department: `${this.selectedDepartment.name}`,
        category: ` > No Category`,
      }
    },

    async initialize() {
      this.loading = true
      const { departmentId } = this.$route.params
      const { categoryId } = this.$route.params

      // if category has been selected
      if (categoryId) {
        const routeInformation = { departmentId, categoryId }
        if (this.categories.length === 0) {
          this.$emit("category-selected", routeInformation)
        }
        this.selectedCategories = [this.selectedCategory]
        const markers = await this.fetchMarkerInformation(
          this.companyId,
          this.project.id,
          "createdDateTime",
          "desc",
          "false",
          [categoryId],
          null,
          null
        )
        this.markers = markers.items
        this.photos = this.markers.map(marker => {
          if (marker.latitude === null) {
            return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
          }
          return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
        })
        this.header = this.setNames()
        this.loading = false

        // if no category
      } else {
        if (this.categories.length === 0) {
          this.$emit("set-department-no-category", departmentId)
        }
        this.selectedCategories = [this.selectedCategory]
        const markers = await this.fetchMarkerInformation(
          this.companyId,
          this.project.id,
          "createdDateTime",
          "desc",
          "false",
          "",
          this.$route.params.departmentId,
          "true"
        )
        this.markers = markers.items
        this.photos = this.markers.map(marker => {
          if (marker.latitude === null) {
            return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
          }
          return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
        })
        this.header = this.setNames()
        this.loading = false
      }
    },

    async handlePhotoDetailSelectSubmit(value) {
      this.selectedCategories = value
      this.header = this.setNames()
      this.markers = await this.setMarkers()
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
    },

    async searchProjectPhotos(value) {
      this.routerLoading = true
      this.photoSearchInput = value
      this.markers = await this.setMarkers()
      this.photos = this.markers.map(marker => {
        if (marker.latitude === null) {
          return { id: marker.id, locationData: false, thumbnailUrl: marker.previewImageUrl }
        }
        return { id: marker.id, locationData: true, thumbnailUrl: marker.previewImageUrl }
      })
      this.routerLoading = false
    },

    handleProjectRouteClick() {
      this.$router.push(`/share/companies/${this.companyId}/projects/${this.project.id}/`)
    },

    handleDepartmentRouteClick() {
      this.$router.push(
        `/share/companies/${this.companyId}/projects/${this.project.id}/departments/${this.selectedDepartment.id}`
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  font-family: "CamptonSemiBold";
  font-size: 2.8rem;
  font-weight: bold;
}

.header-link {
  text-decoration: none;
  color: var(--black);
  transition: 0.25s;
  &:hover {
    color: var(--primary);
  }
}

.project-photo-detail-content-container {
  min-height: 72.2rem;
  height: 100%;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 6rem);
  margin-top: 6rem;
  max-width: 100% !important;
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
}

.search {
  margin-right: 2rem;
  &::v-deep {
    .v-text-field,
    .v-text-field--solo,
    .v-input--dense,
    .v-input__control {
      min-height: 3.2rem !important;
      width: 32rem;
    }
    .v-input__prepend-outer {
      margin: auto !important;
      padding-bottom: 0.5rem;
      display: flex;
    }
    .v-input__control {
      padding-left: 1rem;
    }
  }
}

.search-toolbar {
  display: flex;
  align-items: center;
  padding-left: 4.5rem;
  height: 7rem;
  box-shadow: none;
}

.project-category-photo-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
