





































import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompanyUrl } from "@/util/urls"
import Vue, { VueConstructor } from "vue"
import { Validation, validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import { phoneNumberValidation } from "@/util/validators/phoneNumberValidation"
import { checkPhoneNumber } from "@/util/validators/checkPhoneNumber"
import { checkRequired } from "@/util/validators/checkRequired"
import { companySizeValidation } from "@/util/validators/companySizeValidation"
import { checkCompanySize } from "@/util/validators/checkCompanySize"
import EngieConfirmationDialogButton from "../EngieConfirmationDialogButton.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import EngieErrorSnackbar from "../EngieErrorSnackbar.vue"

export default (Vue as VueConstructor<Vue & typeof validationMixin>).extend({
  components: {
    EngieConfirmationDialogButton,
    EngieTextInput,
    EngieErrorSnackbar,
  },
  mixins: [validationMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
    propertyNameToUpdate: {
      type: String,
      required: true,
    },
    initialValue: {
      type: String,
      required: true,
    },
    companySize: {
      type: Boolean,
      default: null,
    },
    phone: {
      type: Boolean,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editDialogOpen: false,
      saving: false,
      value: "",
      errorOccurredSaving: false,
    }
  },
  computed: {
    errors() {
      if (this.phone) {
        return checkPhoneNumber(this.$v.value)
      }

      if (this.companySize) {
        return checkCompanySize(this.$v.value)
      }

      return checkRequired(this.$v.value)
    },
  },
  methods: {
    handleEdit() {
      this.$emit("edit-clicked")
    },
    handleDialogOpened() {
      this.editDialogOpen = true
      this.value = this.initialValue
    },
    handleDialogClosed() {
      this.editDialogOpen = false
    },
    handleSnackbarClosed() {
      this.errorOccurredSaving = false
    },
    getCompanyUpdatePayload() {
      const valueToSend = this.companySize ? parseInt(this.value, 10) : this.value

      return {
        [this.propertyNameToUpdate]: valueToSend,
      }
    },
    handleSaveClicked() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.updateCompany()
      }
    },
    async updateCompany() {
      this.saving = true
      const { companyId } = this.$route.params

      try {
        await makeAuthenticatedRequest(getCompanyUrl(companyId), "PUT", this.getCompanyUpdatePayload())

        this.$emit("saved", this.getCompanyUpdatePayload())
        this.editDialogOpen = false
      } catch (error) {
        this.errorOccurredSaving = true
      }

      this.saving = false
    },
    getValidation(validation: { [index: string]: Validation }) {
      return { value: { ...validation } }
    },
  },
  validations() {
    if (this.phone) {
      return this.getValidation(phoneNumberValidation)
    }

    if (this.companySize) {
      return this.getValidation(companySizeValidation)
    }

    return this.getValidation({ required })
  },
})
