
















































import Vue from "vue"
import EngiePlus from "./common_icons/PlusIcon.vue"

export default Vue.extend({
  components: { EngiePlus },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    currentUser: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick(event: Event) {
      this.$emit("add-team-click", event)
    },
  },
})
