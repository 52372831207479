import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=71a53aa4&"
import script from "./ProjectCard.vue?vue&type=script&lang=ts&"
export * from "./ProjectCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=71a53aa4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VImg,VProgressLinear})
