













import Vue from "vue"

export default Vue.extend({
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    photos: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String,
      default: "",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedDepartment: {
      type: Object,
      default: () => ({}),
    },
  },
})
