<template>
  <v-dialog
    :retain-focus="false"
    :value="dialogOpen"
    max-width="81rem"
    class="dialog-container"
    :persistent="true"
    @keydown.esc="closeModal()"
  >
    <div class="dialog-content-container" style="height: 64.7rem">
      <div class="dialog-title-container">
        <span class="dialog-title">Add External Team Member(s)</span>
        <v-btn class="close-btn" icon @click="closeModal()">
          <CloseIcon class="close-icon" />
        </v-btn>
      </div>
      <div class="dialog-to-container" :style="errorMessages ? 'height: 14.5rem' : ''">
        <h3 style="color: var(--primary); font-weight: 400; padding-bottom: 0.5rem">
          User must have an active EngieCam Account to be added to projects Added team member(s) can edit, delete and
          add photos.
        </h3>
        <div class="dialog-to-input-container">
          <h3 class="dialog-to" style="line-height: 3rem">To:</h3>
          <v-combobox
            v-model="selectedExternalUsers"
            class="dialog-autocomplete"
            hide-selected
            clearable
            chips
            multiple
            solo
            :loading="loading"
            :items="searchResponseUsers"
            item-color="primary"
            item-text="email"
            item-value="id"
            :error-messages="errorMessages"
            :search-input.sync="search"
            @input="handleInput($event)"
          >
            <template #no-data>
              <v-list-item>
                <v-list-item-title> Search Emails...</v-list-item-title>
              </v-list-item>
            </template>
            <template #selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                dark
                close
                color="var(--navy)"
                v-on="on"
                @click:close="!item.email ? removeChip(item) : removeChip(item.email)"
              >
                <span v-text="item.email ? item.email : item"></span>
              </v-chip>
            </template>
          </v-combobox>
        </div>
      </div>
      <div class="dialog-add-message-container">
        <v-textarea
          v-model="emailMessage"
          class="add-message-input"
          :label="''"
          placeholder="Add a message (optional)"
          no-resize
          rows="18"
        ></v-textarea>
      </div>
      <div class="dialog-submit-container">
        <EngieButton :color="'primary'" :disabled="submitBtnDisabled" @click="handleSubmitExternalTeamMember"
          >Send</EngieButton
        >
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { getAllUsersByEmailAddressSearchExcludingCompany } from "@/services/projectService"
import CloseIcon from "./common_icons/CloseIcon.vue"
import EngieButton from "./forms/EngieButton.vue"

export default {
  components: { CloseIcon, EngieButton },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: "",
    },
    projectId: {
      type: String,
      default: "",
    },
    externalLoading: {
      type: Boolean,
      default: false,
    },
    currentExternalTeamMembers: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data: () => ({
    selectedExternalUsers: null,
    emailMessage: "",
    search: null,
    searchResponseUsers: [],
    loading: false,
    error: null,
    errorMessages: null,
  }),
  computed: {
    submitBtnDisabled() {
      if (this.selectedExternalUsers === null || this.selectedExternalUsers.length === 0 || this.errorMessages) {
        return true
      }
      return false
    },
  },
  watch: {
    search(value) {
      if (!value) {
        return
      }
      this.errorMessages = null
      const updatedValue = encodeURIComponent(value)
      this.fetchEntriesDebounced(updatedValue)
    },
  },
  methods: {
    closeModal() {
      this.resetModal()
      this.$emit("close")
    },

    resetModal() {
      this.selectedExternalUsers = null
      this.emailMessage = ""
      this.search = null
      this.searchResponseUsers = []
      this.errorMessages = []
    },

    fetchEntriesDebounced(value) {
      // eslint-disable-next-line no-underscore-dangle
      clearTimeout(this._timerId)

      // eslint-disable-next-line no-underscore-dangle
      this._timerId = setTimeout(() => {
        this.searchUsers(this.companyId, value)
      }, 500)
    },

    async searchUsers(companyId, value) {
      this.loading = true
      const searchResponse = await this.fetchAllUsersBySearchInput(companyId, value)
      this.searchResponseUsers = searchResponse.items
      this.loading = false
    },

    async fetchAllUsersBySearchInput(companyId, search) {
      try {
        const response = await getAllUsersByEmailAddressSearchExcludingCompany(companyId, search)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async removeChip(item) {
      this.selectedExternalUsers = this.selectedExternalUsers.filter(selectedExternalUser => {
        if (!selectedExternalUser.email) {
          return selectedExternalUser !== item
        }
        return selectedExternalUser.email !== item
      })
      try {
        const response = await this.checkIfUserSelectedEmailOrEnteredInput(this.selectedExternalUsers)
        this.search = ""
        this.errorMessages = null
        this.selectedExternalUsers = response
        const externalTeamCheck = this.selectedExternalUserIsAlreadyExternalTeamMember()
        if (externalTeamCheck) {
          this.error = true
          this.errorMessages = ["User already added as External Team Member"]
        }
      } catch (error) {
        this.error = true
        this.errorMessages = ["User not found"]
      }
    },

    async handleSubmitExternalTeamMember() {
      const currentExternalMemberIds = this.currentExternalTeamMembers.map(user => user.id)
      const selectedExternalMemberIds = this.selectedExternalUsers.map(user => user.id)
      const externalMemberIds = [...currentExternalMemberIds, ...selectedExternalMemberIds]
      const submissionData = { externalMemberIds, message: this.emailMessage }
      this.$emit("submit-external", submissionData)
      this.resetModal()
    },

    async handleInput(event) {
      try {
        const response = await this.checkIfUserSelectedEmailOrEnteredInput(event)
        this.search = ""
        this.selectedExternalUsers = response
        const externalTeamCheck = this.selectedExternalUserIsAlreadyExternalTeamMember()
        if (externalTeamCheck) {
          this.error = true
          this.errorMessages = ["User already added as External Team Member"]
        } else {
          this.errorMessages = null
        }
      } catch (error) {
        this.error = true
        this.errorMessages = ["User not found"]
      }
    },

    async checkIfUserSelectedEmailOrEnteredInput(event) {
      const promises = event.map(async item => {
        if (!item.email) {
          const updatedItem = await this.sendRequestToCheckIfUserExists(item)
          return updatedItem
        }
        return item
      })
      return Promise.all(promises)
    },

    async sendRequestToCheckIfUserExists(item) {
      const updatedItemForQuery = encodeURIComponent(item)
      const externalUser = await this.fetchAllUsersBySearchInput(this.companyId, updatedItemForQuery)
      if (externalUser.items.length > 0) {
        return externalUser.items[0]
      }
      throw new Error()
    },

    groupExternalUsersById(externalUsersArray, propertyName) {
      // eslint-disable-next-line func-names
      return externalUsersArray.reduce(function (acc, externalUser) {
        const key = externalUser[propertyName]
        if (!acc[key]) {
          acc[key] = 0
        }
        acc[key] += 1
        return acc
      }, {})
    },

    selectedExternalUserIsAlreadyExternalTeamMember() {
      const currentAndSelectedExternalUsers = [...this.currentExternalTeamMembers, ...this.selectedExternalUsers]
      const redundentExternalUserIds = this.groupExternalUsersById(currentAndSelectedExternalUsers, "id")
      if (Object.values(redundentExternalUserIds).length !== currentAndSelectedExternalUsers.length) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-container {
  height: 64.7rem !important;
  width: 81rem !important;
  position: relative;
}

.dialog-content-container {
  background-color: white;
  display: flex;
  flex-direction: column;

  .dialog-title-container {
    border-bottom: 1px solid var(--mid-light-grey);
    height: 6.2rem;
    padding: 1.6rem 2.5rem 0.8rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
      font-weight: 900;
      font-size: 2.9rem;
      margin-right: 3rem;
      word-break: break-word;
      line-height: 2.9rem;
    }

    .close-icon {
      width: 2rem;
    }
  }
}

.dialog-to-container {
  height: 12.5rem;
  border-bottom: 1px solid var(--mid-light-grey);
  padding: 1.6rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s;

  .dialog-to {
    padding-top: 0.5rem;
    font-weight: 400;
    font-family: "CamptonRegular";
    color: var(--mid-light-grey);
  }

  .dialog-to-input-container {
    display: flex;

    h3 {
      width: 3rem;
      margin-right: 1rem;
    }

    .dialog-autocomplete {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
  }
}

.dialog-add-message-container {
  border-bottom: 1px solid var(--mid-light-grey);
  height: 100%;
  width: 100%;
  padding: 1.6rem 2.5rem;

  .add-message-input {
    height: 5rem;
    width: 100%;
  }

  &::v-deep {
    .v-input__slot::before {
      display: none;
    }
    .v-input__slot::after {
      display: none;
    }

    .theme--light.v-input,
    textarea {
      color: var(--navy);
      font-family: "CamptonRegular";
    }
  }
}

.dialog-submit-container {
  display: flex;
  justify-content: flex-end;
  padding: 3.2rem 3.8rem;
}

::v-deep {
  .v-dialog,
  .v-card,
  .v-card__text {
    padding: 0 !important;
  }

  .v-text-field.v-text-field--solo,
  .v-input__control {
    min-height: 0px !important;
  }

  .v-input__slot {
    box-shadow: none !important;
  }

  .theme--light.v-input,
  input {
    color: var(--navy) !important;
  }
}
</style>
