<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206 206">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M103,206A103,103,0,1,1,206,103,103.12,103.12,0,0,1,103,206ZM103,8a95,95,0,1,0,95,95A95.11,95.11,0,0,0,103,8Z"
        />
        <path class="cls-1" d="M139,107H103a4,4,0,0,1-4-4V49a4,4,0,0,1,8,0V99h32a4,4,0,0,1,0,8Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #003347;
}
</style>
