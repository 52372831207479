















import Vue from "vue"
import SuperAdminDashboard from "../../views/SuperAdminDashboard.vue"
import UserDashboard from "../../views/UserDashboard.vue"
import { isSuperAdmin } from "../../services/authService"

export default Vue.extend({
  components: {
    SuperAdminDashboard,
    UserDashboard,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSuperAdmin: null as boolean | null,
      isLoading: true,
    }
  },
  computed: {
    shouldShowNonSuperAdminDashboard(): boolean | null {
      return !this.isLoading && !this.isSuperAdmin
    },
    shouldShowSuperAdminDashboard(): boolean | null {
      return !this.isLoading && this.isSuperAdmin
    },
  },
  mounted() {
    this.checkIsSuperAdmin()
  },
  methods: {
    async checkIsSuperAdmin() {
      this.isSuperAdmin = await isSuperAdmin()
      this.isLoading = false
    },
  },
})
