






















import Vue from "vue"
import EngieButton from "./forms/EngieButton.vue"

export default Vue.extend({
  components: { EngieButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    actionButtonText: {
      type: String,
      required: true,
    },
    performingAction: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showActionButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleActionButtonClicked() {
      this.$emit("action-button-clicked")
    },
  },
})
