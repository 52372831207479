










































import Vue from "vue"
import CloseIcon from "./common_icons/CloseIcon.vue"

export default Vue.extend({
  components: {
    CloseIcon,
  },
  props: {
    dialogTitle: {
      type: String,
      default: "Title",
    },
    open: {
      type: Boolean,
      default: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: null,
    },
    narrower: {
      type: Boolean,
      default: null,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    alignActionButtonsRight: {
      type: Boolean,
      default: null,
    },
    outlinedActionsSection: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dialogMaxWidth(): string {
      if (this.narrow) {
        return "50vw"
      }

      if (this.narrower) {
        return "40vw"
      }

      return "70vw"
    },
    actionButtonsContainerClass(): string {
      return this.alignActionButtonsRight ? "action-buttons-container align-right" : "action-buttons-container"
    },
  },
  methods: {
    closeModal() {
      this.$emit("dialog-closed")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
  },
})
