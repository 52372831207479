/**
 * This function will prompt the user to download the data supplied as a file with the given
 * type and file name.
 *
 * @param data The data to download as a file. This can be any valid file data.
 * @param type
 * @param fileName
 */
/* eslint-disable default-param-last */
export const downloadFile = (data: any, type = "text/csv", fileName: string) => {
  const blob = new Blob([data], { type })
  const link = document.createElement("a")
  link.download = fileName
  link.href = URL.createObjectURL(blob)
  link.click()

  URL.revokeObjectURL(link.href)
}
