



















import Vue from "vue"

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    handleChange(value: string) {
      this.$emit("input", value)
    },
  },
})
