<template>
  <div v-if="loading" class="loading-spinner-container">
    <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
  </div>
  <div v-else class="d-flex" style="padding: 6px; padding-bottom: 4rem">
    <form action="" class="d-flex flex-column">
      <v-card flat class="card" style="">
        <v-card-title style="padding: 0.3rem 0px 1.8rem 0"><h2 class="header">Project Details</h2></v-card-title>
        <v-divider style="margin-bottom: 2.4rem; background-color: var(--navy)" />
        <div class="d-flex form-inputs">
          <div class="form-left">
            <h5 class="photo-label">Project Status</h5>
            <EngieMenu
              class="status-select"
              :default-option="project ? project.status : status"
              :options="statusOptions"
              @submit="handleStatusClick"
            />
            <EngieTextInput v-model="project.name" class="project-input" label="Project Name" :required="true" />
            <EngieTextInput v-model="project.number" class="project-input" label="Project Number" />
            <EngieVerifiedAddressInput
              class="project-input"
              label="Project Location"
              :error-messages="addressError"
              :current-address="project.locationName ? project.locationName : null"
              @location-selected="handleAddressLocationSelected"
            />
            <EngieTextArea v-model="project.scope" class="text-area" label="Project Scope" rows="7" row-height="15" />
          </div>
          <div class="form-right">
            <EngieThumbnailUpload
              :label="'Photo Thumbnail'"
              :thumbnail="thumbnailString"
              @image-added="handleImageAdded"
              @image-added-string="handleImageStringAdded"
              ><template #btn-content>
                <div class="btn-content">
                  <EngiePlus style="height: 3.8rem; width: 3.8rem" />
                  <p>Click to add photo</p>
                </div>
              </template>
            </EngieThumbnailUpload>
          </div>
        </div>
      </v-card>
      <slot name="team-members-container"></slot>
      <div class="d-flex submit-btn-container">
        <EngieButton color="primary" :disabled="disableSubmit" @click="handleSubmitProject">{{
          submitBtnTitle
        }}</EngieButton>
        <EngieButton color="light-grey" class="cancel-btn" @click="handleCancel">Cancel</EngieButton>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue"
import EngieThumbnailUpload from "@/components/forms/EngieThumbnailUpload.vue"
import EngieButton from "@/components/forms/EngieButton.vue"
import EngieTextArea from "@/components/forms/EngieTextArea.vue"
import EngieTextInput from "@/components/forms/EngieTextInput.vue"
import EngieMenu from "@/components/EngieMenu.vue"
import EngiePlus from "@/components/common_icons/PlusIcon.vue"
import EngieVerifiedAddressInput from "../EngieVerifiedAddressInput.vue"

export default Vue.extend({
  components: {
    EngieTextInput,
    EngieButton,
    EngieTextArea,
    EngieMenu,
    EngieThumbnailUpload,
    EngiePlus,
    EngieVerifiedAddressInput,
  },
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    submitBtnTitle: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: null,
    },
    projectCreator: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorOccurred: false,
      errorMessage: "",
      addressError: [],
      project: {
        name: "",
        number: "",
        locationName: "",
        scope: "",
        status: { label: "Active", fieldValue: "active" },
        thumbnail: null,
      },
      thumbnailString: "",
      projectUsers: [],
      maxDevicesUsed: false,
      statusOptions: [
        { label: "Active", fieldValue: "active" },
        { label: "Proposed", fieldValue: "proposed" },
        { label: "Archived", fieldValue: "archived" },
      ],
      loading: true,
      dialogOpen: false,
      createProjectSuccessSnackbarOpen: false,
    }
  },
  computed: {
    disableSubmit() {
      if (this.project.name) {
        return false
      }
      return true
    },
  },

  mounted() {
    this.initialize()
  },

  methods: {
    handleStatusClick(value) {
      this.project.status = value
    },

    async handleSubmitProject() {
      const project = {
        name: this.project.name,
        locationName: this.project.locationName,
        latitude: this.project.latitude,
        longitude: this.project.longitude,
        number: this.project.number,
        scope: this.project.scope,
        status: this.project.status.fieldValue,
        thumbnailImageId: this.project.thumbnail,
      }
      this.$emit("project-submitted", project)
    },

    async initialize() {
      this.loading = true
      if (this.$props.formData) {
        this.project = this.$props.formData
        this.thumbnailString = this.$props.formData.thumbnailImageUrl
      }
      this.loading = false
    },

    handleAddressLocationSelected(value) {
      this.project.locationName = value.description
      if (value.latitude && value.longitude) {
        this.project.latitude = value.latitude
        this.project.longitude = value.longitude
      } else {
        this.project.latitude = null
        this.project.longitude = null
      }
    },

    handleCancel() {
      this.$router.push("/projects")
    },

    handleImageAdded(value) {
      this.project.thumbnail = value
    },

    handleImageStringAdded(value) {
      this.thumbnailString = value
    },
  },
})
</script>

<style scoped lang="scss">
.form-inputs {
  .form-left {
    .photo-label {
      margin-bottom: 16px;
      font-size: 16px;
      font-family: "CamptonRegular";
      line-height: 20px;
      font-weight: 400;
    }

    .status-select::v-deep {
      .dropdown {
        margin-left: 0;
        width: 89%;
        max-height: 4.8rem;
        margin-bottom: 22px;
        color: var(--charcoal);
        font-family: "CamptonRegular";
        font-size: 16px;
        padding-top: 2px !important;
      }
    }

    .project-input {
      width: 46rem;
      margin-right: 6rem;
    }

    .project-input::v-deep {
      .v-input__slot {
        min-height: 48px;
      }
      .dropdown {
        width: 100%;
        margin-left: 0;
      }
    }

    .text-area {
      margin-top: 1rem;
      margin-right: 6rem;
      width: 46rem;
    }
  }
  .form-right {
    .add-photo {
      width: 52rem;
    }
  }
}

.team-container {
  position: relative;
  margin-bottom: 2rem;

  .team-container-row {
    margin: 0 !important;
  }
  .team-container-col {
    flex-grow: 0 !important;
    margin: 0;
    padding: 0;
    width: 37rem;
  }
  .team-container-card {
    width: 37rem;
    margin-right: 0rem;
    padding-right: 0;

    .exit-btn {
      height: 2.7rem;
      width: 2.7rem;
      transform: rotate(45deg);
      padding: 5px !important;
    }
  }
}

.submit-btn-container {
  margin-bottom: 0.5rem;
  margin-top: 4rem;

  .cancel-btn::v-deep {
    margin-left: 2rem;
    span {
      color: var(--navy);
    }
  }
}

.header {
  color: var(--navy);
  line-height: 3.2rem;
  font-weight: 400;
}

.btn-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: rgba(43, 43, 43, 0.5);
    margin: 1rem 0;
    font-size: 1.2rem;
    width: 20rem;
    text-align: center;
  }
}

.loading-spinner-container {
  height: 80%;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
