<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 170">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M87.83,82.17l-.1-.08-.08-.1L6.83,1.17A4,4,0,0,0,1.17,6.83L79.34,85,1.17,163.17a4,4,0,0,0,5.66,5.66l81-81A4,4,0,0,0,87.83,82.17Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #003347;
}
</style>
