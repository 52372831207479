<template>
  <div>
    <div v-if="loading" class="team-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <v-card v-else flat>
      <slot name="title"></slot>
      <div class="team-container">
        <v-row class="team-container-row">
          <EngieTeamCard
            v-if="!isExternalUser && isUserAdmin"
            :title="'Add external team member(s)'"
            :subtitle="'User must have an active EngieCam Account to be added to projects'"
            class="team-container-card"
            style="margin-top: 2rem"
            @add-team-click="handleAddExternalTeamDialogOpen"
          />
          <v-col v-for="projectUser in projectUsers" :key="projectUser.id" class="team-container-col">
            <EngieTeamCard
              :user="projectUser"
              :current-user="projectOwner ? projectUser.id === projectOwner.id : ''"
              class="team-container-card"
            >
              <template v-if="!isExternalUser && isUserAdmin" #card-action-btn>
                <v-btn color="primary" depressed fab class="exit-btn" @click="handleOpenConfirmationDialog(projectUser)"
                  ><EngiePlus />
                </v-btn>
              </template>
            </EngieTeamCard>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <EngieConfirmationDialogButton
      :open="confirmationDialogOpen"
      :dialog-title="`Remove External User From Project`"
      :narrower="true"
      :disable-confirmation="!confirmation"
      style="display: none"
      @confirmed="handleRemoveUserFromTeam"
      @dialog-closed="handleCloseConfirmationDialog"
    >
      <div class="confirmation-content-container">
        <h3 style="font-weight: 400">Are you sure?</h3>
        <p>Type "DELETE" to confirm</p>
        <EngieTextInput v-model="confirmationInput" class="confirmation-input" :label="''"></EngieTextInput>
      </div>
    </EngieConfirmationDialogButton>
    <AddExternalTeamMembersDialog
      v-if="!isExternalUser && isUserAdmin"
      :dialog-open="addExternalTeamDialogOpen"
      :current-user="currentUser"
      :company-id="companyId"
      :project-id="projectId"
      :external-loading="loading"
      :current-external-team-members="currentExternalTeamMembers"
      @close="closeModal()"
      @submit-external="handleAddExternalTeamMember"
    ></AddExternalTeamMembersDialog>
    <EngieErrorSnackbar
      v-model="teamMemberErrorSnackbarOpen"
      text="An error occurred while editing your external team."
      @close="handleTeamMemberErrorSnackbarClose()"
    />
  </div>
</template>

<script>
import { patchProjectExternalMembersList } from "@/services/projectService"
import AddExternalTeamMembersDialog from "@/components/AddExternalTeamMembersDialog.vue"
import EngieTeamCard from "./EngieTeamCard.vue"
import EngiePlus from "./common_icons/PlusIcon.vue"
import EngieConfirmationDialogButton from "./EngieConfirmationDialogButton.vue"
import EngieTextInput from "./forms/EngieTextInput.vue"
import EngieErrorSnackbar from "./EngieErrorSnackbar.vue"

export default {
  components: {
    EngieTeamCard,
    AddExternalTeamMembersDialog,
    EngiePlus,
    EngieConfirmationDialogButton,
    EngieTextInput,
    EngieErrorSnackbar,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    currentExternalTeamMembers: {
      type: Array,
      required: false,
      default: null,
    },
    companyId: {
      type: String,
      default: "",
    },
    projectId: {
      type: String,
      default: "",
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorOccurred: false,
      errorMessage: "",
      projectOwner: {},
      projectUsers: [],
      addExternalTeamDialogOpen: false,
      createProjectSuccessSnackbarOpen: false,
      loading: false,
      confirmationDialogOpen: false,
      confirmationInput: "",
      userToBeDeleted: null,
      teamMemberErrorSnackbarOpen: false,
    }
  },
  computed: {
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },
  },
  mounted() {
    this.intialize()
  },
  methods: {
    intialize() {
      this.projectUsers = [...this.currentExternalTeamMembers]
    },

    handleAddExternalTeamDialogOpen() {
      this.addExternalTeamDialogOpen = true
    },

    closeModal() {
      this.addExternalTeamDialogOpen = false
    },

    handleOpenConfirmationDialog(value) {
      this.confirmationDialogOpen = true
      this.userToBeDeleted = value
    },

    handleCloseConfirmationDialog() {
      this.confirmationDialogOpen = false
      this.confirmationInput = ""
      this.userToBeDeleted = null
    },

    handleTeamMemberErrorSnackbarClose() {
      this.teamMemberErrorSnackbarOpen = false
    },

    async handleAddExternalTeamMember(value) {
      try {
        this.loading = true
        this.closeModal()
        const response = await patchProjectExternalMembersList(this.companyId, this.projectId, value)
        this.loading = false
        this.$emit("project-updated")
        return response
      } catch (error) {
        this.loading = false
        this.teamMemberErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    async handleRemoveUserFromTeam() {
      try {
        this.loading = true
        this.projectUsers = this.projectUsers.filter(projectUsers => projectUsers.id !== this.userToBeDeleted.id)
        const externalUserIds = this.projectUsers.map(user => user.id)
        const submissionData = { externalMemberIds: externalUserIds }
        const response = await patchProjectExternalMembersList(this.companyId, this.projectId, submissionData)
        this.handleCloseConfirmationDialog()
        this.loading = false
        this.$emit("project-users-removed")
        return response
      } catch (error) {
        this.teamMemberErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.team-container {
  position: relative;
  margin-bottom: 2rem;
  width: 49rem;

  .team-container-row {
    margin: 0 !important;
  }
  .team-container-col {
    flex-grow: 0 !important;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 49rem;
  }
  .team-container-card {
    width: 100%;
    margin-right: 0rem;
    padding-right: 0;

    .exit-btn {
      height: 2.7rem;
      width: 2.7rem;
      transform: rotate(45deg);
      padding: 5px !important;
    }
  }
}

.team-loading-spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40%;
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
