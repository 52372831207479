<template>
  <div class="project-dashboard-container">
    <div v-if="loading" class="project-dashboard-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <Page v-else :title="project.name">
      <template #toolbar>
        <ProjectStatusMenu :status="project.status" :is-user-admin="false" />
      </template>
      <ProjectDashNav
        class="nav"
        :is-user-admin="false"
        :project-id="project.id"
        :company-id="companyId"
        :can-change-team="false"
        :is-shared="true"
      />
      <div class="dashboard-body">
        <transition name="route" mode="out-in">
          <router-view
            :project="project"
            :loading="loading"
            :is-user-admin="false"
            :company-id="companyId"
            :selected-category="selectedCategory"
            :selected-department="selectedDepartment"
            :categories="categories"
            :departments="departments"
            @set-category="handleSetCategory"
            @set-department="handleSetDepartment"
            @category-selected="handleCategorySelected"
            @category-updated="handleCategoryUpdated"
            @department-selected="handleDepartmentSelected"
            @set-department-no-category="handleNoCategory"
          />
        </transition>
      </div>
    </Page>
  </div>
</template>

<script>
import { getAllCompanyDepartmentsGallery, getAllDepartmentCategoriesGallery } from "@/services/projectService"
import Vue from "vue"
import ProjectDashNav from "@/components/ProjectDashboard/ProjectDashNav.vue"
import ProjectStatusMenu from "@/components/ProjectDashboard/ProjectStatusMenu.vue"
import Page from "../Page.vue"

export default Vue.extend({
  components: { ProjectStatusMenu, ProjectDashNav, Page },
  props: {
    user: {
      type: Object,
      default: null,
    },
    userCompanyId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    projectPhotos: [],
    departments: [],
    categories: [],
    selectedCategory: {},
    selectedDepartment: {},
    loading: true,
    isUserAdmin: false,
  }),

  mounted() {
    this.initialize()
  },
  methods: {
    async fetchCompanyDepartments(companyId, projectId) {
      const response = await getAllCompanyDepartmentsGallery(companyId, projectId)
      return response
    },

    async fetchCompanyDepartmentCategories(companyId, projectId, departmentId) {
      const response = await getAllDepartmentCategoriesGallery(companyId, projectId, departmentId)
      return response
    },

    async initialize() {
      this.departments = await this.fetchCompanyDepartments(this.companyId, this.project.id)
      this.loading = false
    },

    handleSetCategory(value) {
      this.selectedCategory = value
    },

    setNoCategory(categoriesArray) {
      const noCategory = categoriesArray.map(category => {
        if (category.id === null) {
          return { ...category, id: "No category", colorHexValue: "#e2e2e2", name: "No category" }
        }
        return category
      })
      return noCategory
    },

    async handleSetDepartment(value) {
      this.selectedDepartment = value
      this.categories = await this.fetchCompanyDepartmentCategories(this.companyId, this.project.id, value.id)
    },

    async handleDepartmentSelected(value) {
      const department = this.departments.filter(department => department.id === value)
      // eslint-disable-next-line prefer-destructuring
      this.selectedDepartment = department[0]
      const categoriesArray = await this.fetchCompanyDepartmentCategories(this.companyId, this.project.id, value)
      this.categories = this.setNoCategory(categoriesArray)
    },

    async handleCategorySelected(value) {
      this.loading = true
      const department = this.departments.filter(department => department.id === value.departmentId)
      // eslint-disable-next-line prefer-destructuring
      this.selectedDepartment = department[0]
      if (this.categories.length === 0) {
        const allCategories = await this.fetchCompanyDepartmentCategories(
          this.companyId,
          this.project.id,
          value.departmentId
        )
        this.categories = this.setNoCategory(allCategories)
        const category = allCategories.filter(category => category.id === value.categoryId)
        // eslint-disable-next-line prefer-destructuring
        this.selectedCategory = category[0]
      } else {
        const category = this.categories.filter(category => category.id === value.categoryId)
        // eslint-disable-next-line prefer-destructuring
        this.selectedCategory = category[0]
      }
      this.loading = false
    },

    async handleNoCategory(value) {
      this.loading = true
      const department = this.departments.filter(department => department.id === value)
      // eslint-disable-next-line prefer-destructuring
      this.selectedDepartment = department[0]
      const categoriesArray = await this.fetchCompanyDepartmentCategories(this.companyId, this.project.id, value)
      this.categories = this.setNoCategory(categoriesArray)
      this.selectedCategory = { id: "No category", colorHexValue: "#e2e2e2", name: "No category" }
      this.loading = false
    },

    async handleCategoryUpdated() {
      this.categories = await this.fetchCompanyDepartmentCategories(
        this.companyId,
        this.project.id,
        this.selectedDepartment.id
      )
      this.$emit("category-updated")
    },
  },
})
</script>

<style lang="scss" scoped>
.project-dashboard-container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.nav {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dashboard-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 4rem;
  padding: 2rem 0;

  .dashboard-title {
    font-family: "CamptonSemibold";
    font-size: 2.8rem;
    line-height: 6.8rem;
    letter-spacing: 0.01px;
    font-weight: 900;
    color: var(--black);
  }

  .dashboard-content {
    margin-left: -12px;
  }
}

::v-deep {
  .toolbar {
    margin-left: 1.9rem !important;
  }
  .page-title {
    color: var(--navy);
    margin-bottom: 0;
  }
  .page-body {
    position: relative;
    padding-right: 1.8rem !important;
  }
}

.project-dashboard-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}
</style>
