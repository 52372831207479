



















import Vue from "vue"

export default Vue.extend({
  props: {
    numberOfUnassignedPhotos: {
      type: Number,
      required: true,
    },
    featuredPhotoUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    backgroundStyle() {
      return `background-image: url(${this.featuredPhotoUrl});`
    },
  },
  methods: {
    handleRouteClick() {
      this.$router.push({
        path: `/photos`,
      })
    },
  },
})
