<template>
  <div class="component-content-container">
    <div class="content">
      <div v-if="searchLoading" class="project-photo-detail-map-loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
      </div>
      <div v-else-if="!searchLoading && categoryHasPhotos" ref="map" class="map-container">
        <div v-if="markers.length === 0" class="project-photo-detail-map-loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
        </div>
        <div v-else class="google-map-container">
          <GoogleMap
            v-if="!photoWithoutLocation"
            :selected-marker="photoDetailInformation"
            :height="'100%'"
            :markers="markers"
            :map-settings="mapSettings"
            :updated-center="updatedCenter"
            :draggable="false"
            :project="project"
            @marker-click="handleOpenPhotoDetail"
          />
        </div>
      </div>
      <div v-else class="map-container d-flex justify-center align-center flex-column">
        <h3 style="font-weight: 400; margin-bottom: 2rem">
          {{ noDepartment ? `No Photos in ${project.name}: No Department` : `Add Photos To Category to View Here` }}
        </h3>
      </div>
      <div v-if="!photoDetailOpen && categoryHasPhotos" class="photo-drawer">
        <div v-if="loading" class="project-photo-detail-map-loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
        </div>
        <div v-else class="photo-gallery-container">
          <div v-if="!selectPhotosOpen">
            <v-btn depressed class="select-btn" @click="handleSelectOpen">Select Photos</v-btn>
          </div>
          <div v-else style="padding-top: 3rem">
            <div class="control-container d-flex align-center">
              <v-menu v-if="selectedCount > 0" class="menu-container" left offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" text class="extra-menu" v-on="on">...</v-btn>
                </template>
                <v-list class="menu-list">
                  <v-list-item class="menu-item d-flex justify-center">
                    <v-btn class="menu-btn" depressed @click="handlePhotoDownload">Download</v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div v-else style="width: 11rem; height: 2rem"></div>
              <v-spacer></v-spacer>
              <h3 class="count">[ {{ selectedCount }} ] photos selected</h3>

              <v-btn class="close-btn" icon @click="handleCloseSelect">
                <CloseIcon class="close-icon" />
              </v-btn>

              <v-spacer></v-spacer>
            </div>
          </div>
          <div v-if="!selectPhotosOpen" class="list" style="width: 100%">
            <div v-for="photo in photos" :key="photo.id" class="photo-container">
              <PhotoCard :photo="photo" class="photo" @click="handleOpenPhotoDetail(photo)" />
            </div>
          </div>
          <div v-else class="list" style="width: 100%; padding-top: 6rem">
            <div v-for="photo in photos" :key="photo.id" class="photo-container">
              <v-checkbox
                :key="photo.id"
                class="checkbox"
                on-icon="mdi-check-circle"
                off-icon="mdi-checkbox-blank-circle-outline"
                :input-value="isPhotoChecked(photo.id)"
                @change="handlePhotoChecked($event, photo)"
              >
                <template #label>
                  <PhotoCard :photo="photo" class="photo" />
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!photoDetailOpen && !categoryHasPhotos" class="photo-drawer"></div>
      <div v-else class="photo-detail-drawer">
        <div v-if="photoDetailLoading" class="project-photo-detail-map-loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
        </div>
        <div v-else style="padding-top: 3rem">
          <div class="control-container d-flex align-center">
            <v-menu class="menu-container" left offset-y>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" text class="extra-menu" v-on="on">...</v-btn>
              </template>
              <v-list class="menu-list">
                <v-list-item class="menu-item d-flex justify-center">
                  <v-btn class="menu-btn" depressed @click="handlePhotoDownload">Download</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn class="close-btn" style="margin-right: 3rem" icon @click="handleClosePhotoDetail">
              <CloseIcon class="close-icon" />
            </v-btn>
          </div>
          <PhotoDetailInformation
            :company-id="companyId"
            :can-add-notes="false"
            :photo-detail-information="photoDetailInformation"
            :project="project"
            :is-shared="isShared"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFile } from "@/util/downloadFile"
import { getPhotoViewerInformation, getPhotoZipFile } from "@/services/photoService"
import PhotoCard from "../Photos/PhotoCard.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"
import PhotoDetailInformation from "../Photos/PhotoDetailInformation.vue"
import GoogleMap from "../Map/GoogleMap.vue"

export default {
  components: {
    PhotoCard,
    CloseIcon,
    PhotoDetailInformation,
    GoogleMap,
  },
  props: {
    searchLoading: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: String,
      default: "",
    },
    departments: {
      type: Array,
      default: () => [],
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: null,
    },
    photos: {
      type: Array,
      default: () => [],
    },
    markers: {
      type: Array,
      default: () => [],
    },
    noDepartment: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedCategoryPhotos: [],
    selectPhotosOpen: false,
    selectedPhotos: [],
    loading: false,
    photoDetailOpen: false,
    photoDetailLoading: true,
    photoDetailInformation: null,
    photoWithoutLocation: null,
    mapContainerHeight: "",
    updatedCenter: null,
    confirmationInput: "",
    confirmationDialogOpen: false,
    markerToUpdate: null,
  }),
  computed: {
    selectedCount() {
      return this.selectedPhotos.length
    },
    categoryIds() {
      return this.selectedCategories.map(category => category.id)
    },
    mapSettings() {
      if (this.markers) {
        return {
          center: { lat: this.markers[0].latitude, lng: this.markers[0].longitude },
          zoom: 14,
          streetViewControl: true,
          mapTypeControl: true,
          fullscreenControl: true,
          zoomControl: true,
        }
      }
      return null
    },
    categoryHasPhotos() {
      if (this.markers.length > 0) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.loading = true
      this.loading = false
    },

    handleSelectOpen() {
      this.loading = true
      this.selectPhotosOpen = true
      this.loading = false
    },

    handleCloseSelect() {
      this.loading = true
      this.selectPhotosOpen = false
      this.selectedPhotos = []
      this.previousSelection = []
      this.loading = false
    },

    handleSelectAll() {
      this.previousSelection = this.selectedPhotos
      if (this.selectedPhotos.length === this.photos.length) {
        this.selectedPhotos = []
      } else {
        this.selectedPhotos = this.photos
      }
    },

    handlePhotoChecked(checked, item) {
      if (checked) {
        this.selectedPhotos = [...this.selectedPhotos, item]
      } else {
        this.selectedPhotos = this.selectedPhotos.filter(selectedPhoto => selectedPhoto.id !== item.id)
      }
    },

    isPhotoChecked(value) {
      return this.selectedPhotos.some(selectedPhoto => selectedPhoto.id === value)
    },

    async fetchPhotoViewerInformation(companyId, photoId) {
      try {
        const response = await getPhotoViewerInformation(companyId, photoId)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async handleOpenPhotoDetail(value) {
      this.photoDetailOpen = true
      const photoInformation = await this.fetchPhotoViewerInformation(this.companyId, value.id)
      this.updatedCenter = { latitude: photoInformation.latitude, longitude: photoInformation.longitude }
      this.photoDetailInformation = photoInformation
      this.photoDetailLoading = false
    },

    handleClosePhotoDetail() {
      this.photoDetailOpen = false
      this.photoDetailInformation = {}
      this.photoDetailLoading = true
    },

    async handleAddLocationDataToPhoto(value) {
      this.photoDetailOpen = true
      const photoInformation = await this.fetchPhotoViewerInformation(this.companyId, value.id)
      this.photoDetailInformation = photoInformation
      this.photoWithoutLocation = [photoInformation]
      this.photoDetailLoading = false
    },

    async fetchPhotoZipFile(companyId, photoIds) {
      try {
        const response = await getPhotoZipFile(companyId, photoIds)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async handlePhotoDownload() {
      if (this.photoDetailInformation === null) {
        const selectedPhotoIds = this.selectedPhotos.map(photo => photo.id)
        const responseBlob = await this.fetchPhotoZipFile(this.companyId, selectedPhotoIds)
        if (this.selectedPhotos.length > 1) {
          downloadFile(responseBlob, "application/zip", "EngieCamPhotos.zip")
        } else {
          downloadFile(responseBlob, "application/zip", `${this.selectedPhotos[0].id}.zip`)
        }
      } else {
        const responseBlob = await this.fetchPhotoZipFile(this.companyId, this.photoDetailInformation.id)
        downloadFile(responseBlob, "application/zip", `${this.photoDetailInformation.id}.zip`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-container {
  height: 100%;
  width: 100%;
  background-color: var(--grey);
}

.component-content-container {
  height: 100%;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
}

.select-btn {
  color: var(--navy) !important;
  min-width: 12rem;
  padding: 0.8rem 1.4rem;
  background-color: var(--lightest-grey);
  border-radius: 1.5rem;
  min-height: 0px;
  transition: 0.25s;
  font-size: 1.4rem;
  letter-spacing: 0.02px;
  line-height: 1.4rem;
  margin: 1.5rem 0 4.2rem 0.2rem;
  text-transform: none;
}

.list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  height: 100%;
}

.photo-gallery-container {
  padding-bottom: 2rem;
}

.photo-drawer {
  position: relative;
  right: 0;
  top: -7rem;
  left: auto;
  height: calc(100% + 6.8rem);
  width: 45%;
  padding-left: 4rem;
  padding-bottom: 3rem;
  border-left: 1px solid var(--light-grey);
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 1px;
  overflow-y: auto;
}

.photo-detail-drawer {
  position: relative;
  right: 0;
  top: -7rem;
  left: auto;
  height: calc(100% + 6.8rem);
  width: 34%;
  padding: 2rem 3rem 2rem 3rem;
  border-left: 1px solid var(--light-grey);
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 1px;
  overflow-y: scroll;
}

.control-container {
  padding-top: 1rem;
  padding-left: 0 !important;
  margin-left: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.extra-menu {
  margin-left: 1.7rem !important;
  padding: 0 0.8rem;
  position: relative !important;
  color: var(--navy);
  font-size: 9rem;
  letter-spacing: -3px;
  &::v-deep {
    .v-btn__content {
      padding: 0 1rem 2.7rem 0 !important;
    }
  }
}

.v-menu__content,
.menuable__content__active {
  contain: none !important;
  box-shadow: none !important;
}

.menu-list {
  padding: 0 !important;
  color: var(--navy) !important;
  box-shadow: none !important;
  margin-right: 1rem;
  border: 2px solid var(--light-grey);
  &::v-deep {
    box-shadow: none !important;
    .v-menu__content {
      box-shadow: none;
    }
  }
}

.v-list-item--active::before {
  display: none;
}

.v-list-item {
  padding: 0rem 0rem !important;
  min-height: 0px;
}

.menu-btn {
  color: var(--navy);
  background-color: white !important;
  transition: 0.15s;
  font-size: 1.4rem;
  font-weight: 600;
  width: 100% !important;
  letter-spacing: 0.3px;
  line-height: 1.4rem;
  text-transform: none;
  display: flex;
  justify-content: left;
  &:before {
    display: none;
  }
}

.menu-btn:hover {
  background-color: var(--navy) !important;
  color: white !important;
}

.count {
  font-family: "CamptonRegular";
  font-size: 1.4rem;
  font-weight: 100;
  margin-left: 2.5rem;
}

.close-btn {
  margin-left: 1rem;
  .close-icon {
    height: 1.5rem;
  }
}

.checkbox {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

::v-deep {
  .v-input--selection-controls__input {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin: 0;
    z-index: 2;
  }
  .v-icon {
    color: white;
    border-radius: 100%;
  }
  .mdi-checkbox-blank-circle-outline {
    background-color: white;
    border: 1px solid var(--orange);
  }
  .mdi-check-circle {
    background-color: white;
    border: 2px solid white;
    margin-top: 0.68px;
  }
  .v-messages {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0px;
  }
}

.project-photo-detail-map-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.photo-drawer {
  position: relative;
  right: 0;
  top: -7rem;
  left: auto;
  height: calc(100% + 6.8rem);
  width: 45%;
  padding-left: 4rem;
  padding-bottom: 3rem;
  border-left: 1px solid var(--light-grey);
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 1px;
  overflow-y: auto;
}

.google-map-container {
  height: 100%;
  width: 100%;
}

.photo {
  position: relative;
}

.no-location-container {
  position: relative;
}

.no-location-data {
  background-color: #000000;
  position: relative;

  &:after {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: black;
    z-index: 2;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
}

.location-btn {
  position: absolute;
  bottom: 5%;
  left: 18%;
  z-index: 3;

  ::v-deep {
    height: 2.5rem !important;
    min-width: 4.8rem !important;
    padding: 0 0.8rem !important;
    font-weight: 900;
    letter-spacing: 1px;
  }
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 0.5rem;
  }

  .location-detail {
    line-height: 2rem;
    padding: 0.5rem 0;
    font-weight: 500;
    color: var(--navy);
    width: 80%;
    text-align: center;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
