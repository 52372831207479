


































import Vue from "vue"

export default Vue.extend({
  props: {
    status: {
      type: String,
      default: "Active",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultSelected: this.$props.status,
      items: ["Active", "Proposed", "Archived"],
    }
  },
  methods: {
    handleClick(value: string) {
      this.defaultSelected = value
    },
  },
})
