










































import Vue from "vue"
import "firebase/auth"
import { validationMixin } from "vuelidate"
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getPasswordUpdateUrl } from "@/util/urls"
import { checkRequired } from "../../util/validators/checkRequired"
import EngieConfirmationDialogButton from "../EngieConfirmationDialogButton.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"

export default Vue.extend({
  components: {
    EngieConfirmationDialogButton,
    EngieTextInput,
  },
  mixins: [validationMixin],
  props: {
    userId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      savingNewPassword: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      currentPasswordIncorrect: false,
      errorOccurredSettingPassword: false,
    }
  },
  computed: {
    currentPasswordErrors(): string[] {
      return checkRequired(this.$v.currentPassword)
    },
    newPasswordErrors(): string[] {
      if (!this.$v.newPassword.$dirty) {
        return []
      }

      if (!this.$v.newPassword.required) {
        return ["Please enter a new password"]
      }

      if (!this.$v.newPassword.minLength) {
        return ["Your password must be at least six characters in length"]
      }

      return []
    },
    confirmPasswordErrors(): string[] {
      if (!this.$v.confirmPassword.$dirty) {
        return []
      }

      if (!this.$v.confirmPassword.sameAsNewPassword) {
        return ["Your password confirmation does not match the password you entered"]
      }

      return []
    },
    dialogErrorOccurred(): boolean {
      return this.currentPasswordIncorrect || this.errorOccurredSettingPassword
    },
    dialogErrorMessage(): String {
      if (this.currentPasswordIncorrect) {
        return "Your password is incorrect"
      }

      if (this.errorOccurredSettingPassword) {
        return "An error occurred setting your password"
      }

      return ""
    },
  },
  methods: {
    handleChangePasswordDialogClosed() {
      this.$v.$reset()

      this.savingNewPassword = false

      this.newPassword = ""
      this.currentPassword = ""
      this.confirmPassword = ""

      this.currentPasswordIncorrect = false
      this.errorOccurredSettingPassword = false

      this.$emit("dialog-closed")
    },
    handleChangePasswordDialogOpened() {
      this.$emit("dialog-opened")
    },
    async onConfirmClicked() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.validatePasswordAndUpdate()
      }
    },
    async validatePasswordAndUpdate() {
      this.savingNewPassword = true
      this.currentPasswordIncorrect = false

      try {
        await makeAuthenticatedRequest(
          getPasswordUpdateUrl(this.userId),
          "PUT",
          {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
          },
          false,
          "json",
          false
        )

        this.handleChangePasswordDialogClosed()

        this.$emit("password-saved")
      } catch (error) {
        this.savingNewPassword = false
        this.errorOccurredSettingPassword = true
      }
    },
  },
  validations: {
    currentPassword: {
      required,
    },
    newPassword: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      required,
      sameAsNewPassword: sameAs("newPassword"),
    },
  },
})
