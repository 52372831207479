





































import { CompanyRecord } from "@/types/CompanyRecord"
import Vue from "vue"
import { DataTableHeader } from "vuetify"
import { formatShortDate } from "@/util/dateUtils"
import EngieTable from "../EngieTable.vue"
import { getCssVariableValue } from "../../util/getCssVariableValue"

export default Vue.extend({
  components: {
    EngieTable,
  },
  props: {
    companies: {
      type: Array,
      default: null,
    },
    totalCount: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberOfActiveAccounts: 0,
      sortDesc: false,
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: "Company Name",
          value: "name",
        },
        {
          text: "Account Number",
          value: "id",
          sortable: false,
          align: "center",
        },
        {
          text: "Created",
          value: "createdDateTime",
          sortable: false,
          align: "center",
        },
        {
          text: "Plan",
          value: "planDisplayName",
          sortable: false,
          align: "center",
        },
        {
          text: "Seats Used",
          value: "numberOfActiveUsers",
          sortable: false,
          align: "center",
        },
        {
          text: "Storage",
          value: "storageAmountDisplayName",
          sortable: false,
          align: "center",
        },
        {
          text: "Name",
          value: "headAdminName",
          sortable: false,
          align: "center",
        },
        {
          text: "Email",
          value: "headAdminEmail",
          sortable: false,
          align: "center",
        },
        {
          text: "Phone",
          value: "headAdminPhoneNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "Company Size",
          value: "size",
          sortable: false,
          align: "center",
        },
        {
          text: "Company Address",
          value: "address",
          sortable: false,
          align: "center",
        },
      ]
    },
  },
  methods: {
    handlePageNumberUpdated(pageNumber: number) {
      this.$emit("page-number-updated", pageNumber)
    },
    handlePageSizeUpdated(pageSize: number) {
      this.$emit("page-size-updated", pageSize)
    },
    handlePageSortDescendingUpdated(sortDescending: boolean) {
      this.sortDesc = sortDescending
      this.$emit("page-sort-descending-updated", sortDescending)
    },
    handleEditRowClicked(itemId: string) {
      this.$emit("edit-company-clicked", itemId)
    },
    getStatusChipContent(item: CompanyRecord) {
      if (item.pausedDateTime) {
        return { color: getCssVariableValue("--mid-light-grey"), text: "paused" }
      }

      if (item.deletedDateTime) {
        return { color: getCssVariableValue("--red"), text: "deleted" }
      }

      return { color: getCssVariableValue("--green"), text: "active" }
    },
    formatCreatedDate(dateString: string) {
      return formatShortDate(dateString)
    },
  },
})
