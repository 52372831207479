<template>
  <ProjectDashboardContent>
    <template #title>
      <div class="d-flex flex-column" style="width: 100%">
        Team
        <v-divider style="border-color: var(--black); width: 90%" />
      </div>
    </template>
    <template #content>
      <div v-if="loading" class="team-loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
      </div>
      <div v-else class="content">
        <div class="d-flex flex-column" style="width: 49rem; height: 100%; padding-right: 1rem">
          <h2 style="margin-top: 2.5rem">{{ companyName }} Team</h2>
          <div>
            <EngieInternalTeamMembersContainer
              class="team-members-container"
              :loading="loading"
              :current-user="currentUser"
              :current-internal-team-members="internalMembers"
              :project-creator="internalMembers[0]"
              :project-team-page="true"
              :is-user-admin="isUserAdmin"
              :is-external-user="isExternalUser"
              @intialized="handleTeamMemberInitialized"
              @project-users-selected="handleProjectUsersSelected"
              @project-users-removed="handleOpenConfirmationDialog"
            >
            </EngieInternalTeamMembersContainer>
          </div>
        </div>
        <v-divider vertical style="border-color: var(--black); height: 100%" />
        <div style="margin-left: 4.4rem">
          <h2 style="margin-top: 3rem">External Team</h2>
          <div v-if="externalLoading"></div>
          <div v-else>
            <EngieExternalTeamMembersContainer
              :is-external-user="isExternalUser"
              :is-user-admin="isUserAdmin"
              :current-external-team-members="externalMembers"
              :current-user="currentUser"
              :company-id="companyId"
              :project-id="project.id"
              @project-updated="handleExternalUsersUpdated"
              @project-users-removed="handleExternalUserRemoved"
            >
            </EngieExternalTeamMembersContainer>
          </div>
        </div>
      </div>
      <EngieErrorSnackbar
        v-model="editProjectTeamErrorSnackbarOpen"
        class="error-snack"
        text="An error occured while updating your project's team"
        @close="handleEditProjectTeamErrorSnackbarClose()"
      />
      <EngieConfirmationDialogButton
        :open="confirmationDialogOpen"
        :dialog-title="`Remove User From Project`"
        :narrower="true"
        :disable-confirmation="!confirmation"
        style="display: none"
        @confirmed="handleRemoveProjectUser"
        @dialog-closed="handleCloseConfirmationDialog"
      >
        <div class="confirmation-content-container">
          <h3 style="font-weight: 400">Are you sure?</h3>
          <p>Type "DELETE" to confirm</p>
          <EngieTextInput v-model="confirmationInput" class="confirmation-input" :label="''"></EngieTextInput>
        </div>
      </EngieConfirmationDialogButton>
    </template>
  </ProjectDashboardContent>
</template>

<script>
import EngieConfirmationDialogButton from "@/components/EngieConfirmationDialogButton.vue"
import EngieErrorSnackbar from "@/components/EngieErrorSnackbar.vue"
import EngieExternalTeamMembersContainer from "@/components/EngieExternalTeamMembersContainer.vue"
import EngieInternalTeamMembersContainer from "@/components/EngieInternalTeamMembersContainer.vue"
import EngieTextInput from "@/components/forms/EngieTextInput.vue"
import { getCurrentAuthUserRecord, hasAdminRole } from "@/services/authService"
import {
  getAllExternalUsersWhenMembersOfProject,
  getAllUsersWhenMembersOfProject,
  patchCompanyProject,
} from "@/services/projectService"
import Vue from "vue"
import ProjectDashboardContent from "../components/ProjectDashboard/ProjectDashboardContent.vue"

export default Vue.extend({
  components: {
    ProjectDashboardContent,
    EngieInternalTeamMembersContainer,
    EngieErrorSnackbar,
    EngieConfirmationDialogButton,
    EngieTextInput,
    EngieExternalTeamMembersContainer,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: String,
      default: "",
    },
    userHomeCompanyId: {
      type: String,
      default: "",
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      companyName: "",
      internalMembers: [],
      externalMembers: [],
      currentUser: {},
      editProjectTeamErrorSnackbarOpen: false,
      confirmationInput: "",
      confirmationDialogOpen: false,
      updatedProjectUsers: [],
      externalLoading: false,
      isUserAdmin: false,
    }
  },
  computed: {
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    async fetchCompanyName() {
      const companyInformation = JSON.parse(localStorage.getItem(`currentWorkspace[${this.currentUser.id}]`))
      return companyInformation.name
    },

    async fetchProjectInternalMembers(companyId, projectId) {
      const response = await getAllUsersWhenMembersOfProject(companyId, projectId)
      return response
    },

    async fetchProjectExternalMembers(companyId, projectId) {
      const response = await getAllExternalUsersWhenMembersOfProject(companyId, projectId)
      return response
    },

    async handleExternalUsersUpdated() {
      this.externalLoading = true
      this.$emit("project-updated")
      const updatedExternalTeamMembers = await this.fetchProjectExternalMembers(this.companyId, this.project.id)
      this.externalMembers = updatedExternalTeamMembers.gallery
      this.externalLoading = false
    },

    async handleExternalUserRemoved() {
      this.externalLoading = true
      this.$emit("external-user-removed")
      const updatedExternalTeamMembers = await this.fetchProjectExternalMembers(this.companyId, this.project.id)
      this.externalMembers = updatedExternalTeamMembers.gallery
      this.externalLoading = false
    },

    async initialize() {
      this.loading = true
      this.externalLoading = true
      if (!this.isExternalUser) {
        this.currentUser = await this.fetchCurrentUser()
        this.isUserAdmin = await hasAdminRole()
        this.companyName = await this.fetchCompanyName()
        const updatedInternalTeamMembers = await this.fetchProjectInternalMembers(this.companyId, this.project.id)
        const updatedExternalTeamMembers = await this.fetchProjectExternalMembers(this.companyId, this.project.id)
        this.internalMembers = updatedInternalTeamMembers.gallery
        this.externalMembers = updatedExternalTeamMembers.gallery
        this.loading = false
        this.externalLoading = false
      } else {
        this.currentUser = await this.fetchCurrentUser()
        this.isUserAdmin = false
        this.companyName = await this.fetchCompanyName()
        const updatedInternalTeamMembers = await this.fetchProjectInternalMembers(this.companyId, this.project.id)
        const updatedExternalTeamMembers = await this.fetchProjectExternalMembers(this.companyId, this.project.id)
        this.internalMembers = updatedInternalTeamMembers.gallery
        this.externalMembers = updatedExternalTeamMembers.gallery
        this.loading = false
        this.externalLoading = false
      }
    },

    handleTeamMemberInitialized() {
      this.loading = false
    },

    async handleProjectUsersSelected(value) {
      this.loading = true
      const userIds = this.setProjectUserIds(value)
      const projectData = { memberIds: userIds }
      try {
        const response = await patchCompanyProject(this.companyId, this.project.id, projectData)
        const updatedInternalTeamMembers = await this.fetchProjectInternalMembers(this.companyId, this.project.id)
        this.internalMembers = updatedInternalTeamMembers.gallery
        this.loading = false
        this.$emit("project-updated")
        return response
      } catch (error) {
        this.loading = false
        this.editProjectTeamErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    setProjectUserIds(array) {
      if (array.length > 1) {
        const userIds = array.map(user => user.id)
        return userIds
      }
      return [array[0].id]
    },

    async fetchCurrentUser() {
      const currentUser = await getCurrentAuthUserRecord()
      return currentUser
    },

    handleEditProjectTeamErrorSnackbarClose() {
      this.editProjectTeamErrorSnackbarOpen = false
    },

    handleOpenConfirmationDialog(value) {
      this.updatedProjectUsers = value
      this.confirmationDialogOpen = true
    },

    handleCloseConfirmationDialog() {
      this.updatedProjectUsers = []
      this.confirmationDialogOpen = false
    },
    async handleRemoveProjectUser() {
      this.confirmationDialogOpen = false
      await this.handleProjectUsersSelected(this.updatedProjectUsers)
      this.updatedProjectUsers = []
    },
  },
})
</script>

<style lang="scss" scoped>
.error-snack {
  z-index: 1000;
}
::v-deep {
  .dashboard-title,
  .dashboard-body {
    font-weight: 400 !important;
    font-family: "CamptonMedium" !important;
  }
}

h2 {
  font-family: "CamptonMedium";
  font-weight: 400;
}

.v-list-item {
  padding: 0 !important;
}

.content {
  margin-left: 12px;
  display: flex;
  width: 100%;
}

.team-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.team-members-container::v-deep {
  .team-container-card {
    width: 49rem;
    max-width: 47rem !important;
  }
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
