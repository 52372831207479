<template>
  <div class="btn-container">
    <slot name="button"></slot>
    <EngieDialog
      :open="open"
      :dialog-title="dialogTitle"
      :narrower="true"
      style="max-width: 42rem !important"
      @dialog-closed="closeModal()"
      @enter-pressed="handleEnterPressed()"
    >
      <div class="dialog-content-department">
        <h4 class="subheader">New Department Name:</h4>
        <EngieTextInput v-model="newName" :label="''" class="text-input" :autofocus="true" />
      </div>
      <EngieButton class="submit-btn" @click="handleCreateClick(newName)">Create Department</EngieButton>
    </EngieDialog>
  </div>
</template>

<script>
import Vue from "vue"
import EngieDialog from "@/components/EngieDialog.vue"
import EngieButton from "@/components/forms/EngieButton.vue"
import EngieTextInput from "@/components/forms/EngieTextInput.vue"

export default Vue.extend({
  components: { EngieDialog, EngieTextInput, EngieButton },
  props: {
    title: {
      type: String,
      default: "",
    },
    dialogTitle: {
      type: String,
      default: "Title",
    },
    open: {
      type: Boolean,
      default: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
    narrow: {
      type: Boolean,
      default: null,
    },
    narrower: {
      type: Boolean,
      default: null,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    text: {
      type: Boolean,
      default: null,
    },
    category: {
      type: Boolean,
      default: false,
    },
    department: {
      type: Boolean,
      default: false,
    },
    departmentName: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      newName: "",
    }
  },
  methods: {
    handleCreateClick(value) {
      this.$emit("create", value)
    },

    closeModal() {
      this.$emit("dialog-closed")
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleEnterPressed() {
      this.$emit("create", this.newName)
    },
  },
})
</script>

<style lang="scss" scoped>
.dialog-title {
  font-weight: 800;
}

.selected::after {
  content: "";
  position: absolute;
  border-radius: 2px;
  left: -6px;
  height: 6.5rem;
  width: 6.5rem;
  border: 2px solid var(--black);
  transition-delay: 1.25s;
  transition: 0.05s;
}

.submit-btn {
  text-align: center;
}

.dialog-content-department {
  padding: 1.5rem;
}

.subheader {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--black);
  margin-bottom: 1rem;
  font-weight: 400;
}

::v-deep {
  .v-dialog,
  .v-dialog--active {
    max-width: 420px !important;
  }

  .engie-text-input:not(.animated-label),
  .v-text-field {
    padding-top: 0px !important;
    .v-input__slot {
      border: 2px solid var(--grey);
    }
  }
}
</style>
