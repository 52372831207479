<template>
  <Page :title="'Edit Project'">
    <template #toolbar>
      <EngieButton v-if="isUserAdmin && !isExternalUser" color="primary" @click="handleOpenConfirmationDialog"
        >DELETE PROJECT</EngieButton
      >
    </template>
    <div v-if="loading" class="add-project-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <ProjectInformation
      v-else
      :page-title="'Edit Project'"
      :submit-btn-title="'Save Project'"
      :form-data="formData"
      @project-submitted="handleUpdateProject"
    >
    </ProjectInformation>
    <EngieConfirmationDialogButton
      :open="confirmationDialogOpen"
      :dialog-title="`Delete ${project.name}`"
      :narrower="true"
      :disable-confirmation="!confirmation"
      style="display: none"
      @confirmed="handleDeleteProject"
      @dialog-closed="handleCloseConfirmationDialog"
    >
      <div class="confirmation-content-container">
        <h3 style="font-weight: 400">Are you sure?</h3>
        <p>Type "DELETE" to confirm</p>
        <EngieTextInput v-model="confirmationInput" class="confirmation-input" :label="''"></EngieTextInput>
      </div>
    </EngieConfirmationDialogButton>
    <EngieErrorSnackbar
      v-model="editProjectErrorSnackbarOpen"
      text="An error occured while editing your project"
      @close="handleEditProjectErrorSnackbarClose()"
    />
    <EngieErrorSnackbar
      v-model="deleteProjectErrorSnackbarOpen"
      text="An error occured while editing your project"
      @close="handleDeleteProjectErrorSnackbarClose()"
    />
  </Page>
</template>

<script>
import { hasAdminRole } from "@/services/authService"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { createProjectThumbnail, getOneCompanyProject, patchCompanyProject } from "@/services/projectService"
import Page from "@/components/Page.vue"
import EngieErrorSnackbar from "@/components/EngieErrorSnackbar.vue"
import EngieConfirmationDialogButton from "@/components/EngieConfirmationDialogButton.vue"
import EngieTextInput from "@/components/forms/EngieTextInput.vue"
import EngieButton from "@/components/forms/EngieButton.vue"
import { getCompanyUrl } from "@/util/urls"
import ProjectInformation from "../components/Projects/ProjectInformation.vue"

export default {
  components: {
    ProjectInformation,
    Page,
    EngieErrorSnackbar,
    EngieConfirmationDialogButton,
    EngieButton,
    EngieTextInput,
  },
  props: {
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    confirmationInput: "",
    confirmationDialogOpen: false,
    project: {},
    companyId: "",
    loading: false,
    editProjectErrorSnackbarOpen: false,
    deleteProjectErrorSnackbarOpen: false,
    isUserAdmin: false,
    formData: {
      name: "",
      number: "",
      locationName: "",
      scope: "",
      thumbnailImageUrl: "",
      status: "",
    },
  }),
  computed: {
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    handleOpenConfirmationDialog() {
      this.confirmationDialogOpen = true
    },

    handleCloseConfirmationDialog() {
      this.confirmationDialogOpen = false
    },

    async handleDeleteProject() {
      try {
        const response = await makeAuthenticatedRequest(
          `${getCompanyUrl(this.companyId)}/projects/${this.project.id}`,
          "DELETE"
        )
        const recentProjectsInStorage = JSON.parse(localStorage.getItem(`recentProjects[${this.companyId}]`))
        if (recentProjectsInStorage) {
          const recentProjectsWithoutCurrentProject = recentProjectsInStorage.filter(
            recentProjectId => recentProjectId !== this.project.id
          )
          localStorage.setItem(`recentProjects[${this.companyId}]`, JSON.stringify(recentProjectsWithoutCurrentProject))
        }
        this.$emit("project-deleted")
        this.$router.push("/projects")
        return response
      } catch (error) {
        this.deleteProjectErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    async fetchProjectInformation(companyId, projectId) {
      // this function is used in the intialize function, fetching the information about the project.
      const response = await getOneCompanyProject(companyId, projectId)
      return response
    },

    capitalizeFirstLetter(string) {
      // this function is used in the initialize function, setting the status object's label property to be title case.
      return string.charAt(0).toUpperCase() + string.substring(1)
    },

    async initialize() {
      this.loading = true
      this.companyId = this.$route.params.companyId
      this.project = await this.fetchProjectInformation(this.companyId, this.$route.params.projectId)
      this.isUserAdmin = await this.checkUserRole()
      this.formData.name = this.project.name
      this.formData.number = this.project.number
      this.formData.locationName = this.project.locationName
      this.formData.scope = this.project.scope
      this.formData.thumbnailImageUrl = this.project.thumbnailImageUrl
      const statusLabel = this.capitalizeFirstLetter(this.project.status)
      const updatedStatus = { label: statusLabel, fieldValue: this.project.status }
      this.formData.status = updatedStatus
      this.loading = false
    },

    async handleUpdateProject(value) {
      this.loading = true
      const project = { ...value }
      if (project.thumbnailImageId) {
        const updatedProject = await this.createProjectWithThumbnail(project)
        this.checkForResponseAfterUpdatingProject(updatedProject)
      } else {
        const updatedProject = project
        delete updatedProject.thumbnailImageId
        this.checkForResponseAfterUpdatingProject(updatedProject)
      }
      this.loading = false
    },

    async createProjectWithThumbnail(project) {
      const thumbnailFile = project.thumbnailImageId
      try {
        const projectThumbnailResponse = await this.handleCreateProjectThumbnailId(thumbnailFile, this.companyId)
        const updatedProject = { ...project, thumbnailImageId: projectThumbnailResponse }
        return updatedProject
      } catch (error) {
        this.editProjectErrorSnackbarOpen = true
        throw new Error("Could not update Project Thumbnail")
      }
    },

    getFileFormData(file) {
      const formData = new FormData()
      formData.append("photo", file, file?.name)
      return formData
    },

    async handleCreateProjectThumbnailId(value, companyId) {
      try {
        const response = await createProjectThumbnail(value, companyId)
        return response.id
      } catch (error) {
        this.editProjectErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    async checkForResponseAfterUpdatingProject(project) {
      try {
        const response = await patchCompanyProject(this.companyId, this.project.id, project)
        this.$emit("project-updated")
        this.$router.push(`/projects/${this.project.id}/details`)
        return response
      } catch (error) {
        this.editProjectErrorSnackbarOpen = true
        throw new Error(`${error}`)
      }
    },

    async checkUserRole() {
      if (this.isExternalUser) {
        return false
      }
      const isUserAdmin = await hasAdminRole()
      return isUserAdmin
    },

    handleEditProjectErrorSnackbarClose() {
      this.editProjectErrorSnackbarOpen = false
    },
    handleDeleteProjectErrorSnackbarClose() {
      this.deleteProjectErrorSnackbarOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.add-project-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
