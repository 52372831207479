























































import { debounce } from "vue-debounce"
import { isCompanyAdmin, isSuperAdmin } from "@/services/authService"
import { CompanyRecord } from "@/types/CompanyRecord"
import { PaginatedResponse } from "@/types/PaginatedResponse"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { pluralize } from "@/util/pluralize"
import { downloadFile } from "@/util/downloadFile"
import { getSortDirection } from "@/util/getSortDirection"
import { findByIdAndReplace, replaceAtIndex } from "@/util/arrayUtils"
import { getCompanyUrl, getCompanyUsersUrl, getUserCSVUrl } from "@/util/urls"
import Vue from "vue"
import Page from "../components/Page.vue"
import PageToolbar from "../components/PageToolbar.vue"
import UsersTable from "../components/Users/UsersTable.vue"
import { UserRecord } from "../types/UserRecord"
import { DEBOUNCE_INTERVAL } from "../constants/debounceInternval"
import EditUsersDialog from "../components/Users/EditUsersDialog.vue"
import EngieSuccessSnackbar from "../components/EngieSuccessSnackbar.vue"
import AddNewUsersDialogButton from "../components/Users/AddNewUsersDialogButton.vue"
import { CompanyRole } from "../types/CompanyRole"

export default Vue.extend({
  components: {
    Page,
    PageToolbar,
    UsersTable,
    EditUsersDialog,
    EngieSuccessSnackbar,
    AddNewUsersDialogButton,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageTitle: "",
      numberOfActiveAccounts: 0,
      queryResultUserCount: 0,
      numberOfActiveAccountsLoaded: false,
      companyName: null as null | string,
      loadingUsers: true,
      userPageNumber: 1,
      userPageSize: 10,
      userNameSortDirection: "asc",
      usersPaginated: true,
      users: null as null | UserRecord[],
      userNameSearch: "",
      fetchUsersDebounced: debounce(() => {}, 0),
      editUserDialogOpen: false,
      userToEdit: null as null | UserRecord,
      savingUser: false,
      showEditSuccessSnackbar: false,
      showDeleteUserSuccessSnackbar: false,
      showAddUserSuccessSnackbar: false,
      addNewUsersDialogOpen: false,
    }
  },
  computed: {
    userPluralization(): string {
      return pluralize(this.numberOfActiveAccounts)
    },
    successSnackbarUserName(): string {
      return this.userToEdit?.name || "user"
    },
  },
  watch: {
    isExternalUser(currentValue) {
      if (currentValue === true) {
        this.$router.push(`/`)
      }
    },
  },
  created() {
    this.fetchUsersDebounced = debounce(this.fetchUsers, DEBOUNCE_INTERVAL)
    this.fetchUsersDebounced()
  },
  beforeMount() {
    this.setPageTitle()
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.redirectIfNotAdmin()
      this.fetchUsers()
      this.fetchCompanyName()
    },
    async redirectIfNotAdmin() {
      const hasCompanyAdminStatus = await isCompanyAdmin(this.companyId)

      if (!hasCompanyAdminStatus) {
        this.$router.push("/")
      }
    },
    async setPageTitle() {
      const hasSuperAdminStatus = await isSuperAdmin()

      this.pageTitle = hasSuperAdminStatus ? "Super Admin" : "Users"
    },
    async fetchUsers() {
      this.loadingUsers = true

      const usersResponse = await makeAuthenticatedRequest<PaginatedResponse<UserRecord>>(
        getCompanyUsersUrl(
          this.companyId,
          this.usersPaginated,
          this.userPageSize,
          this.userPageNumber,
          this.userNameSortDirection,
          this.userNameSearch
        )
      )

      this.users = usersResponse.items

      if (!this.numberOfActiveAccountsLoaded) {
        this.numberOfActiveAccounts = usersResponse.total
        this.numberOfActiveAccountsLoaded = true
      }

      this.queryResultUserCount = usersResponse.total

      this.loadingUsers = false
    },
    async fetchCompanyName() {
      const companyResponse: CompanyRecord = await makeAuthenticatedRequest(getCompanyUrl(this.companyId))

      this.companyName = companyResponse.name
    },
    handleUserSearchQueryChanged() {
      this.fetchUsersDebounced()
    },
    async handleCsvDownload() {
      const response = await makeAuthenticatedRequest(getUserCSVUrl(this.companyId), "GET", null, false, "blob")

      downloadFile(response, "text/csv", `Engiecam User Report ${new Date().toDateString()}.csv`)
    },
    handleEditUserClicked(userId: string) {
      this.editUserDialogOpen = true

      if (this.users) {
        this.userToEdit = this.users.find(user => user.id === userId) || null
      }
    },
    handleEditUsersDialogClosed() {
      this.editUserDialogOpen = false
    },
    demoteExistingHeadAdminIfFound() {
      const existingHeadAdminIndex = this.users?.findIndex(user => user.companyRole === CompanyRole.HEAD_ADMIN)

      if (existingHeadAdminIndex && this.users) {
        const existingHeadAdmin = this.users[existingHeadAdminIndex]

        this.users = replaceAtIndex(this.users, existingHeadAdminIndex, {
          ...existingHeadAdmin,
          companyRole: CompanyRole.USER,
        })
      }
    },
    handleUserUpdated(updatedUserResponse: UserRecord) {
      if (this.users) {
        this.showEditSuccessSnackbar = true
        this.demoteExistingHeadAdminIfFound()
        this.users = findByIdAndReplace(this.users, updatedUserResponse.id, updatedUserResponse)
        this.editUserDialogOpen = false
      }
    },
    handleUserDeleted(deletedUserId: string) {
      if (this.users) {
        this.users = this.users.filter(user => user.id !== deletedUserId)
        this.editUserDialogOpen = false
        this.showDeleteUserSuccessSnackbar = true
      }
    },
    handlePageSortDescendingUpdated(sortDescending: boolean) {
      this.userNameSortDirection = getSortDirection(sortDescending)

      this.fetchUsers()
    },
    handleAddNewUsersDialogOpened() {
      this.addNewUsersDialogOpen = true
    },
    handleAddNewUsersDialogClosed() {
      this.addNewUsersDialogOpen = false
    },
    handleUsersCreated(newUsers: UserRecord[]) {
      if (this.users) {
        this.showAddUserSuccessSnackbar = true
        this.users = [...this.users, ...newUsers]
        this.addNewUsersDialogOpen = false
      }
    },
  },
})
