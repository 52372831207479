<template>
  <div class="project-content">
    <transition name="route" mode="out-in">
      <router-view
        :company-id="companyId"
        :user-home-company-id="userHomeCompanyId"
        :is-external-user="isExternalUser"
        @category-updated="handleCategoryUpdated"
        @department-updated="handleDepartmentUpdated"
        @project-created="handleProjectCreated"
        @project-updated="handleProjectUpdated"
        @project-deleted="handleProjectDeleted"
        @user-removed="handleTeamMemberRemoved"
      ></router-view>
    </transition>
    <EngieSuccessSnackbar
      v-model="updateCategorySuccessSnackbarOpen"
      text="Your project's categories were updated successfully"
      @close="handleUpdateCategorySuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="updateDepartmentSuccessSnackbarOpen"
      text="Your project's departments were updated successfully"
      @close="handleupdateDepartmentSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="createProjectSuccessSnackbarOpen"
      text="Your project was created successfully"
      @close="handleCreateProjectSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="updateProjectSuccessSnackbarOpen"
      text="Your project was updated successfully"
      @close="handleUpdatedProjectSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="deleteProjectSuccessSnackbarOpen"
      text="Your project was deleted successfully"
      @close="handleDeleteProjectSuccessSnackbarClose()"
    />
    <EngieSuccessSnackbar
      v-model="teamMemberRemovedSuccessSnackbarOpen"
      text="Your team member was removed successfully"
      @close="handleteamMemberRemovedSuccessSnackbarClose()"
    />
  </div>
</template>

<script>
import EngieSuccessSnackbar from "../EngieSuccessSnackbar.vue"

export default {
  components: { EngieSuccessSnackbar },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    updateCategorySuccessSnackbarOpen: false,
    updateDepartmentSuccessSnackbarOpen: false,
    createProjectSuccessSnackbarOpen: false,
    updateProjectSuccessSnackbarOpen: false,
    deleteProjectSuccessSnackbarOpen: false,
    teamMemberRemovedSuccessSnackbarOpen: false,
  }),
  methods: {
    handleProjectCreated() {
      this.createProjectSuccessSnackbarOpen = true
    },
    handleCreateProjectSuccessSnackbarClose() {
      this.createProjectSuccessSnackbarOpen = false
    },
    handleProjectUpdated() {
      this.updateProjectSuccessSnackbarOpen = true
    },
    handleUpdatedProjectSuccessSnackbarClose() {
      this.updateProjectSuccessSnackbarOpen = false
    },
    handleProjectDeleted() {
      this.deleteProjectSuccessSnackbarOpen = true
    },
    handleDeleteProjectSuccessSnackbarClose() {
      this.deleteProjectSuccessSnackbarOpen = false
    },
    handleDepartmentUpdated() {
      this.updateDepartmentSuccessSnackbarOpen = true
    },
    handleUpdateDepartmentSuccessSnackbarClose() {
      this.updateDepartmentSuccessSnackbarOpen = false
    },
    handleCategoryUpdated() {
      this.updateCategorySuccessSnackbarOpen = true
    },
    handleUpdateCategorySuccessSnackbarClose() {
      this.updateDepartmentSuccessSnackbarOpen = false
    },
    handleTeamMemberRemoved() {
      this.teamMemberRemovedSuccessSnackbarOpen = true
    },
    handleteamMemberRemovedSuccessSnackbarClose() {
      this.teamMemberRemovedSuccessSnackbarOpen = false
    },
  },
}
</script>

<style>
.project-content {
  height: calc(100% - 3rem);
  width: 100%;
}
</style>
