<template>
  <div class="input-container">
    <div v-if="!currentImage && !thumbnail" class="no-current-photo">
      <label for="file-upload" class="input-label"
        >{{ label }}
        <input
          ref="imageUploader"
          type="file"
          class="file-input"
          name="file-upload"
          accept="image/png, image/jpg"
          @change="uploadImageFile($event)"
      /></label>
      <v-btn class="img-upload-btn" depressed @click="useFileInput"
        ><div class="btn-content">
          <slot name="btn-content"></slot>
        </div>
      </v-btn>
    </div>

    <div v-else class="edit-photo">
      <label for="file-upload" class="input-label"
        >{{ label }}
        <input
          ref="imageUploader"
          type="file"
          class="file-input"
          name="file-upload"
          accept="image/png, image/jpg"
          @change="uploadImageFile($event)"
      /></label>
      <v-img v-if="currentImage" :src="currentImage" class="current-img"></v-img>
      <v-img v-else :src="thumbnail" class="current-img"></v-img>
      <v-btn class="edit-img-btn" color="secondary" icon @click="useFileInput"
        ><v-icon>mdi-pencil-outline</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    thumbnail: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    currentImage: null,
    previewImage: null,
    progress: 0,
    message: "",
    imageInfo: [],
    formData: null,
  }),
  beforeUnmount() {
    URL.revoke(this.currentImage)
  },
  methods: {
    useFileInput() {
      const uploader = this.$refs.imageUploader
      uploader.click()
    },

    uploadImageFile(event) {
      if (event.target.files.length !== 0) {
        const data = URL.createObjectURL(event.target.files[0])
        const formData = this.getFileFormData(event.target.files[0])
        this.formData = formData
        this.currentImage = data
        this.$emit("image-added", formData)
        this.$emit("image-added-string", data)
      }
    },

    getFileFormData(file) {
      const formData = new FormData()
      formData.append("photo", file, file?.name)
      return formData
    },
  },
})
</script>

<style lang="scss" scoped>
.input-container {
  .no-current-photo {
    display: flex;
    flex-direction: column;
  }
}

.input-label {
  color: var(--charcoal);
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  height: 100%;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  .file-input {
    display: none;
  }
}

.img-upload-btn {
  height: 30rem !important;
  width: 60rem;
  text-transform: none;

  .btn-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: rgba(43, 43, 43, 0.5);
      margin: 1rem 0;
      font-size: 1.2rem;
      width: 20rem;
      text-align: center;
    }
  }
}

.edit-photo {
  position: relative;

  .current-img {
    height: 30rem;
    width: 60rem;
    margin-top: 1.6rem;
    border-radius: 5px;
    position: relative;
  }

  .edit-img-btn {
    position: absolute;
    bottom: -1.5rem;
    left: -1.5rem;
    background-color: white;
    border: 1px solid var(--lightest-grey);
    height: 5rem;
    width: 5rem;
  }
}
</style>
