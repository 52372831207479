
















import { CompanyWithMetadataRecord } from "@/types/CompanyWithMetadataRecord"
import Vue from "vue"
import InfoPanel from "./InfoPanel.vue"

type InfoPanelProps = {
  label: string
  content: string
}

export default Vue.extend({
  components: {
    InfoPanel,
  },
  props: {
    infoPanels: {
      type: Array,
      default: [] as InfoPanelProps[],
    },
  },
  methods: {
    handleSave(update: Partial<CompanyWithMetadataRecord>) {
      this.$emit("saved", update)
    },
  },
})
