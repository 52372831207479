








































import Vue from "vue"
import { getCompanyPhotosUrl, getCompanyStorageInformationUrl } from "@/util/urls"
import { StorageInformationResponse } from "@/types/StorageInformationResponse"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import PageTitle from "../PageTitle.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"
import PhotoUploader from "../PhotoUploader.vue"

const GIGABYTE_SIZE = 1_000_000_000

export default Vue.extend({
  components: {
    CloseIcon,
    PageTitle,
    PhotoUploader,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      storageInformation: null as null | StorageInformationResponse,
      newFilesUploadedByteTotal: 0,
      storagePlanDisplayName: "",
      storageInformationLoaded: false,
    }
  },
  computed: {
    percentageOfStorageUsed(): number | null {
      if (
        this.storageInformation === null ||
        (this.storageInformation.totalBytesUsed === 0 && this.newFilesUploadedByteTotal === 0)
      ) {
        return 0
      }

      const totalBytesUsedIncludingNewUploads = this.storageInformation.totalBytesUsed + this.newFilesUploadedByteTotal
      const storageConsumedRatio =
        totalBytesUsedIncludingNewUploads / (this.storageInformation.planGigMaximum * GIGABYTE_SIZE)

      return Math.max(1, Math.floor(storageConsumedRatio * 100))
    },
  },
  mounted() {
    this.getCompanyInformation()
  },
  methods: {
    async getCompanyInformation() {
      this.storageInformation = await makeAuthenticatedRequest(getCompanyStorageInformationUrl(this.companyId))
      if (this.storageInformation !== null) {
        this.storagePlanDisplayName = this.storageInformation.storagePlanDisplayName
        this.storageInformationLoaded = true
      }
    },
    handleImproperFileUploaded() {
      this.$emit("improper-file-uploaded")
    },
    getPhotoUploadUrl() {
      return this.companyId ? getCompanyPhotosUrl(this.companyId) : null
    },
    handleFileUploaded(file: File) {
      this.newFilesUploadedByteTotal += file.size
      this.$emit("new-file-uploaded")
    },
    closeUploadNewPhotosDialog() {
      this.$emit("close-clicked")
    },
  },
})
