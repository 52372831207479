<template>
  <div style="height: 100%">
    <div id="map" ref="googleMap" class="google-map" :style="`height: ${height}`"></div>
  </div>
</template>

<script>
import Vue from "vue"
import { Loader } from "@googlemaps/js-api-loader"
import { getGoogleMapsApiKey } from "@/util/urls"
import { MarkerClusterer } from "@googlemaps/markerclusterer"
import { getMarkerRenderer } from "@/types/MarkerRenderer"
import { DEFAULT_LAT_LONG } from "@/constants/defaultLatLong"

export default Vue.extend({
  props: {
    mapSettings: {
      type: Object,
      default: () => ({}),
    },
    markers: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "",
    },
    addSearch: {
      type: Boolean,
      default: false,
    },
    updatedCenter: {
      type: Object,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: null,
    },
    zoom: {
      type: Number,
      default: 18,
    },
  },
  data: () => ({
    apiKey: "",
    google: null,
    map: null,
    mapMarkers: [],
    initialized: false,
  }),
  computed: {
    mapConfig() {
      return {
        ...this.mapSettings,
        center: this.mapCenter,
        gestureHandling: "cooperative",
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
        ],
      }
    },
    mapCenter() {
      if (this.markers[0].latitude === null) {
        if (this.project === null) {
          // TODO: eventually change me to company's verified location!
          return DEFAULT_LAT_LONG
        }
        return { lat: this.project.latitude, lng: this.project.longitude }
      }
      return { lat: this.markers[0].latitude, lng: this.markers[0].longitude }
    },
  },
  watch: {
    updatedCenter(currentValue) {
      if (currentValue === null) {
        return null
      }
      return this.handleAddressLocationSelected(currentValue)
    },
    markers(currentValue) {
      if (currentValue === null) {
        return null
      }
      if (this.clusterer !== null) {
        this.clusterer.clearMarkers()
        this.mapMarkers = []
        this.markers.map(marker => this.setupMarkers(marker, this.map, this.google))
        const clusterer = new MarkerClusterer({
          map: this.map,
          markers: this.mapMarkers,
          renderer: getMarkerRenderer(this.google),
        })
        this.clusterer = clusterer

        return null
      }
      return null
    },
    zoom(updatedZoom) {
      this.map.setZoom(updatedZoom)
    },
  },
  async mounted() {
    this.apiKey = `${getGoogleMapsApiKey()}`
    const googleMapApi = new Loader({
      apiKey: this.apiKey,
      libraries: ["places"],
    })
    this.google = await googleMapApi.load()
    this.initializeMap()
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
      this.markers.map(marker => this.setupMarkers(marker, this.map, this.google))

      const clusterer = new MarkerClusterer({
        map: this.map,
        markers: this.mapMarkers,
        renderer: getMarkerRenderer(this.google),
      })
      this.clusterer = clusterer
    },

    setupMarkers(marker, map, google) {
      const svgMarker = {
        path: "M57.5,8.5A22.38,22.38,0,0,0,39.68.4h-.33A22.38,22.38,0,0,0,21.53,8.5c-4.07,4.92-6.32,11.76-6.32,19.27,0,8.8,5.91,20.22,10.36,28.94C30.18,65.76,39.46,79.6,39.51,79.6s9-13.84,14-22.89c4.69-8.59,10.36-20.14,10.36-28.94C63.82,20.26,61.57,13.42,57.5,8.5Zm-18,27.64A10.31,10.31,0,1,1,49.82,25.83,10.31,10.31,0,0,1,39.51,36.14Z",
        fillColor: marker.categories.length > 0 ? marker.categories[0].colorHexValue : "#DB4437",
        fillOpacity: 1,
        strokeWeight: 0,
        strokeColor: "#404040",
        rotation: 0,
        scale: 0.5,
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(41, 84),
      }

      if (marker.latitude && marker.longitude) {
        // eslint-disable-next-line no-new
        const mapMarker = new google.maps.Marker({
          id: marker.id,
          position: { lat: marker.latitude, lng: marker.longitude },
          marker,
          map,
          icon: svgMarker,
          draggable: this.draggable,
        })

        const infoWindow = new google.maps.InfoWindow({
          content: `<div style="margin: 0.25rem 0 0 0.5rem"><img style="margin: 0.25rem 0 0 0.5rem"><img style="height: 15rem; width: 15rem; border-radius: 0.5rem; object-fit: contain;" src='${marker.originalImageUrl}'></div>`,
          disableAutoPan: true,
        })

        mapMarker.addListener("click", () => {
          map.setCenter(mapMarker.position)
          this.$emit("marker-click", marker)
        })
        mapMarker.addListener("dragend", () => {
          const latitude = mapMarker.getPosition().lat()
          const longitude = mapMarker.getPosition().lng()
          const data = { id: mapMarker.id, latitude, longitude }
          this.clusterer.clearMarkers()
          this.clusterer.addMarkers(this.mapMarkers)
          this.$emit("marker-drag", data)
        })
        mapMarker.addListener("mouseover", () => {
          infoWindow.open(map, mapMarker)
        })
        mapMarker.addListener("mouseout", () => {
          infoWindow.close(map, mapMarker)
        })
        if (this.updatedCenter !== null) {
          this.handleAddressLocationSelected(this.updatedCenter)
        }

        this.mapMarkers = [...this.mapMarkers, mapMarker]
        this.svgMarker = { ...svgMarker }
      } else {
        map.addListener("click", e => this.emitLocation(e, marker))
      }
    },

    async emitLocation(e, marker) {
      const latitude = e.latLng.lat()
      const longitude = e.latLng.lng()
      const geocodedResponse = await this.getAddressFromLatLng(latitude, longitude)
      const address = geocodedResponse.formatted_address
      const position = { id: marker.id, address, latitude, longitude }
      this.$emit("marker-location-selected", position)
    },

    async getAddressFromLatLng(lat, lng) {
      if (lat !== null && lng !== null) {
        const geocoder = new this.google.maps.Geocoder()
        const result = await geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === "OK") {
            return results
          }
          return null
        })
        return result.results[0]
      }
      return null
    },

    handleAddressLocationSelected(value) {
      this.map.panTo({ lat: value.latitude, lng: value.longitude })
    },
    handleMarkersChanged(markers) {
      const response = markers.map(marker => this.setupMarkers(marker, this.map, this.google))
      return response
    },
  },
})
</script>

<style lang="scss" scoped>
.google-map {
  width: 100%;
  height: 100%;
}
</style>
