<template>
  <div class="photo-gallery-container">
    <div v-if="!selectPhotosOpen" style="padding-top: 3rem">
      <EngieButton @click="handleSelectOpen">Select Photos</EngieButton>
    </div>
    <div v-else style="padding-top: 3rem">
      <div class="control-container d-flex align-center">
        <EngieButton class="select-btn" :color="'primary'" style="margin: 0" @click="handleSelectAll">
          Select All
        </EngieButton>
        <h3 class="count">[ {{ selectedCount }} ] photos selected</h3>
        <v-spacer></v-spacer>
        <div v-if="selectedPhotos.length > 0" class="d-flex">
          <v-btn v-if="!isShared" plain class="control-btn" color="secondary" @click="handleAssignPhotoDialogOpen">
            <AddNewProjectIcon class="control-btn-icon" />Add to Project
          </v-btn>
          <v-btn v-if="!isShared" plain class="control-btn" color="secondary" @click="handleOpenShareDialog">
            <ShareIcon class="control-btn-icon" /> Share
          </v-btn>
          <v-btn plain class="control-btn" color="secondary" @click="handlePhotoDownload">
            <DownloadIcon class="control-btn-icon" /> Download
          </v-btn>
          <v-btn
            v-if="!isShared && !isExternalUser"
            plain
            class="control-btn"
            color="secondary"
            @click="handleOpenConfirmationDialog"
          >
            <DeleteIcon class="control-btn-icon" /> Delete
          </v-btn>
        </div>
        <v-btn plain class="control-btn" @click="handleCloseSelect"
          ><EngiePlus style="transform: rotate(45deg)"
        /></v-btn>
      </div>
    </div>

    <div class="photo-container" style="width: 100%">
      <div v-if="loading" class="loading-container">
        <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
      </div>
      <div v-for="[key, value] in organizedPhotos.entries()" v-else :key="key" class="photo-year-container">
        <h4 class="year">{{ key }}</h4>
        <div v-for="[monthKey, monthPhotos] in value.entries()" :key="monthKey">
          <h4 class="month">{{ monthKey }}</h4>
          <div class="photo-gallery-content d-flex flex-wrap" style="width: 100%">
            <div
              v-for="(photo, index) in monthPhotos"
              :key="photo.id"
              class="photo-gallery-item"
              :id="index === monthPhotos.length - 1 ? 'last-photo' : ''"
            >
              <v-checkbox
                :key="photo.id"
                class="checkbox"
                :class="selectPhotosOpen ? 'select-mode' : ''"
                on-icon="mdi-check-circle"
                off-icon="mdi-checkbox-blank-circle-outline"
                :input-value="isPhotoChecked(photo.id)"
                @change="handlePhotoChecked($event, photo)"
              >
                <template #label>
                  <PhotoCard :photo="photo" @click="handlePhotoCarouselOpen" />
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EngieShareDialog
      v-if="!isShared"
      :open="shareDialogOpen"
      :item-type="'Photo'"
      :photos="selectedPhotosForSharing"
      :company-id="companyId"
      @share-submitted="handleShareSubmitted"
      @close="handleCloseShareDialog"
    />
    <PhotoCarouselDialog
      :is-external-user="isExternalUser"
      :total-photos="totalPhotos"
      :company-id="companyId"
      :dialog-open="photoCarouselOpen"
      :photos="photos"
      :default-photo="selectedPhotoForCarousel"
      :is-shared="isShared"
      @close="handlePhotoCarouselClose"
      @photo-updated="handlePhotoDataUpdated"
      @photo-location-updated="handlePhotoLocationUpdated"
      @assign-from-carousel="handleAssignPhotoFromCarousel"
      @photo-deleted="handleOpenConfirmationDialog"
      @share-submitted="handleShareSubmitted"
      @end-of-scroll="handleEndOfScroll"
    />
    <AssignPhotoDialog
      v-if="!isShared"
      :is-external-user="isExternalUser"
      :company-id="companyId"
      :dialog-open="assignPhotoDialogOpen"
      :selected-photos-for-assignment="selectedPhotosForAssignment"
      @photo-updated="handlePhotoUpdated"
      @close="handleAssignPhotoDialogClose"
    />
    <EngieConfirmationDialogButton
      v-if="!isShared"
      :open="confirmationDialogOpen"
      :dialog-title="`Delete Photo(s)`"
      :narrower="true"
      :disable-confirmation="!confirmation"
      style="display: none"
      @confirmed="handlePhotoDelete"
      @dialog-closed="handleCloseConfirmationDialog"
    >
      <div class="confirmation-content-container">
        <h3 style="font-weight: 400">Are you sure?</h3>
        <p>Type "DELETE" to confirm</p>
        <EngieTextInput v-model="confirmationInput" class="confirmation-input" :label="''"></EngieTextInput>
      </div>
    </EngieConfirmationDialogButton>
  </div>
</template>

<script>
import { getPhotoZipFile } from "@/services/photoService"
import { downloadFile } from "@/util/downloadFile"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getCompanyUrl } from "@/util/urls"
import ShareIcon from "@/components/common_icons/ShareIcon.vue"
import DownloadIcon from "@/components/common_icons/DownloadIcon.vue"
import DeleteIcon from "@/components/common_icons/DeleteIcon.vue"
import EngiePlus from "@/components/common_icons/PlusIcon.vue"
import PhotoCard from "./PhotoCard.vue"
import EngieButton from "../forms/EngieButton.vue"
import PhotoCarouselDialog from "./PhotoCarouselDialog.vue"
import AssignPhotoDialog from "./AssignPhotoDialog.vue"
import AddNewProjectIcon from "../common_icons/AddNewProjectIcon.vue"
import EngieConfirmationDialogButton from "../EngieConfirmationDialogButton.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import EngieShareDialog from "../EngieShareDialog.vue"

export default {
  components: {
    AssignPhotoDialog,
    PhotoCard,
    ShareIcon,
    DownloadIcon,
    DeleteIcon,
    EngiePlus,
    EngieButton,
    PhotoCarouselDialog,
    AddNewProjectIcon,
    EngieConfirmationDialogButton,
    EngieTextInput,
    EngieShareDialog,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    organizedPhotos: {
      type: Map,
      default: new Map(),
    },
    photos: {
      type: Array,
      default: () => [],
    },
    totalPhotos: {
      type: Number,
      default: 0,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedPhotos: [],
    previousSelection: [],
    photoCarouselOpen: false,
    selectedPhotoForCarousel: null,
    assignPhotoDialogOpen: false,
    selectedPhotosForAssignment: null,
    confirmationInput: "",
    confirmationDialogOpen: false,
    shareDialogOpen: false,
    selectedPhotosForSharing: [],
    loading: false,
    selectPhotosOpen: false,
  }),
  computed: {
    selectedCount() {
      return this.selectedPhotos.length
    },
    confirmation() {
      if (this.confirmationInput.toLowerCase() === "delete") {
        return true
      }
      return false
    },
  },
  methods: {
    handleAssignPhotoDialogOpen() {
      this.assignPhotoDialogOpen = true
      this.selectedPhotosForAssignment = this.selectedPhotos
    },
    handleAssignPhotoDialogClose() {
      this.selectedPhotoForAssignment = null
      this.assignPhotoDialogOpen = false
    },

    handlePhotoCarouselOpen(value) {
      if (!this.selectPhotosOpen) {
        this.selectedPhotoForCarousel = value
        this.photoCarouselOpen = true
      }
    },

    handlePhotoCarouselClose() {
      this.selectedPhotoForCarousel = null
      this.photoCarouselOpen = false
    },

    handleSelectOpen() {
      this.selectPhotosOpen = true
      this.loading = false
    },

    handleCloseSelect() {
      this.selectPhotosOpen = false
      this.selectedPhotos = []
    },

    handleSelectAll() {
      this.previousSelection = this.selectedPhotos
      if (this.selectedPhotos.length === this.photos.length) {
        this.selectedPhotos = []
      } else {
        this.selectedPhotos = this.photos
      }
    },

    handlePhotoChecked(checked, item) {
      if (checked) {
        this.selectedPhotos = [...this.selectedPhotos, item]
      } else {
        this.selectedPhotos = this.selectedPhotos.filter(selectedPhoto => selectedPhoto.id !== item.id)
      }
    },

    isPhotoChecked(value) {
      return this.selectedPhotos.some(selectedPhoto => selectedPhoto.id === value)
    },

    handlePhotoUpdated() {
      this.selectedPhotos = []
      this.selectedPhotosForAssignment = null
      this.selectPhotosOpen = false
      this.$emit("photo-updated")
    },

    handlePhotoDataUpdated() {
      this.$emit("photo-updated")
    },

    handlePhotoLocationUpdated() {
      this.$emit("photo-location-updated")
    },

    handleAssignPhotoFromCarousel(value) {
      this.selectedPhotosForAssignment = [{ id: value }]
      this.assignPhotoDialogOpen = true
    },

    async fetchPhotoZipFile(companyId, photoIds) {
      try {
        const response = await getPhotoZipFile(companyId, photoIds)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async handlePhotoDownload() {
      if (this.selectedPhotoForCarousel) {
        const responseBlob = await this.fetchPhotoZipFile(this.companyId, this.selectedPhotoForCarousel.id)
        downloadFile(responseBlob, "application/zip", `${this.selectedPhotoForCarousel.id}.zip`)
      } else if (this.selectedPhotos.length > 1) {
        const selectedPhotoIds = this.selectedPhotos.map(photo => photo.id)
        const responseBlob = await this.fetchPhotoZipFile(this.companyId, selectedPhotoIds)
        downloadFile(responseBlob, "application/zip", "EngieCamPhotos.zip")
      } else {
        const responseBlob = await this.fetchPhotoZipFile(this.companyId, this.selectedPhotos[0].id)
        downloadFile(responseBlob, "application/zip", `${this.selectedPhotos[0].id}.zip`)
      }
    },

    handleOpenConfirmationDialog(value) {
      this.confirmationDialogOpen = true
      if (this.photoCarouselOpen) {
        this.selectedPhotoForCarousel = value
      }
    },

    handleCloseConfirmationDialog() {
      this.confirmationDialogOpen = false
    },

    async handlePhotoDelete() {
      if (this.photoCarouselOpen) {
        const response = await this.deletePhoto(this.companyId, this.selectedPhotoForCarousel.id)
        this.selectedPhotoForCarousel = null
        this.photoCarouselOpen = false
        this.handleCloseConfirmationDialog()
        return response
      }
      if (this.selectedPhotos.length > 1) {
        const photoIdsArray = this.selectedPhotos.map(photo => photo.id)
        const response = this.sendDeleteRequestForEachPhotoId(photoIdsArray)
        return response
      }
      const response = await this.deletePhoto(this.companyId, this.selectedPhotos[0].id)
      return response
    },

    async deletePhoto(companyId, photoId) {
      try {
        const response = await makeAuthenticatedRequest(`${getCompanyUrl(companyId)}/photos/${photoId}`, "DELETE")
        this.$emit("photo-deleted")
        return response
      } catch (error) {
        this.$emit("photo-deleted-error")
        throw new Error(`${error}`)
      }
    },

    sendDeleteRequestForEachPhotoId(photoIdsArray) {
      const promises = photoIdsArray.map(async photoId => {
        const response = await this.deletePhoto(this.companyId, photoId)
        return response
      })
      return Promise.all(promises)
    },

    handleOpenShareDialog() {
      this.selectedPhotosForSharing = [...this.selectedPhotos]
      this.shareDialogOpen = true
    },

    handleCloseShareDialog() {
      this.selectedPhotosForSharing = []
      this.shareDialogOpen = false
    },

    handleShareSubmitted() {
      this.$emit("share-submitted")
    },

    handleEndOfScroll() {
      this.$emit("end-of-scroll")
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-container {
  padding-top: 2.8rem;

  .photo-year-container {
    .year {
      font-size: 2.5rem;
      font-family: "CamptonMedium";
      font-weight: 400;
      line-height: 3.3rem;
      color: var(--navy);
      margin-bottom: 1.3rem;
    }

    .month {
      font-size: 2.1rem;
      font-family: "CamptonMedium";
      font-weight: 400;
      line-height: 1.4rem;
      color: var(--navy);
    }

    .photo-gallery-content {
      padding-top: 1.8rem;
      padding-bottom: 2.7rem;
      height: 100%;
      row-gap: 1rem;
      column-gap: 1rem;
      margin: 0px;
      width: 36.7rem;
    }
  }
}

.control-container {
  padding: 0rem;
}

.control-btn {
  text-transform: none;
  font-size: 1.2rem;
  line-height: 1.7rem;
  display: flex;
}
.control-btn-icon {
  height: 2.2rem;
  margin-right: 8px;
  padding-bottom: 2px;
}
.count {
  font-family: "CamptonRegular";
  font-weight: 100;
  margin-left: 2.5rem;
}

.checkbox {
  margin-top: 0 !important;
  padding-top: 0 !important;

  &::v-deep {
    .v-input--selection-controls__input {
      display: none;
    }

    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }
  }

  &.select-mode::v-deep {
    .v-input--selection-controls__input {
      display: flex;
    }
  }
}

.photo-gallery-item:hover {
  cursor: pointer;
}

::v-deep {
  .v-input--selection-controls__input {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin: 0;
    z-index: 2;
  }
  .v-icon {
    color: white;
    border-radius: 100%;
  }
  .mdi-checkbox-blank-circle-outline {
    background-color: white;
    border: 1px solid var(--orange);
  }
  .mdi-check-circle {
    background-color: white;
    border: 2px solid white;
    margin-top: 0.68px;
  }
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  h3 {
    font-size: 2.5rem;
    font-family: "CamptonMedium";
    line-height: 2.4rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: var(--charcoal);
    margin-bottom: 2rem;
  }

  .confirmation-input {
    width: 50%;
  }

  .confirmation-input::v-deep {
    .engie-text-input:not(.animated-label),
    .v-text-field {
      padding-top: 0 !important;
    }
  }
}
</style>
