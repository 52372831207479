<template>
  <div class="photo-container">
    <div v-if="searchLoading" class="project-photo-detail-map-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else class="photo-container">
      <div v-if="!selectPhotosOpen && categoryHasPhotos" style="padding-top: 3rem">
        <EngieButton @click="handleSelectOpen">Select Photos</EngieButton>
      </div>
      <div v-else-if="!selectPhotosOpen"></div>
      <div v-else style="padding-top: 3rem">
        <div class="control-container d-flex align-center">
          <EngieButton class="select-btn" :color="'primary'" style="margin: 0" @click="handleSelectAll">
            Select All
          </EngieButton>
          <h3 class="count">[ {{ selectedCount }} ] photos selected</h3>
          <v-spacer></v-spacer>
          <div v-if="selectedPhotos.length > 0" class="d-flex">
            <v-btn plain class="control-btn" color="secondary" @click="handlePhotoDownload">
              <DownloadIcon class="control-btn-icon" /> Download
            </v-btn>
          </div>
          <v-btn plain class="control-btn" @click="handleCloseSelect"><CloseIcon class="control-btn-icon" /></v-btn>
        </div>
      </div>
      <div v-if="!selectPhotosOpen && categoryHasPhotos" class="photo-gallery-container">
        <div v-for="(values, propertyName, idx) in photosGroupedByCategory" :key="idx">
          <h3 class="category-name">{{ propertyName }}</h3>
          <div class="photo-gallery-content d-flex flex-wrap">
            <div v-for="photo in values" :key="photo.id" class="photo-gallery-item">
              <PhotoCard :photo="photo" @click="handleOpenPhotoDetail(photo)" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="!selectPhotosOpen"
        class="photo-gallery-container d-flex justify-center align-center flex-column"
        style="height: 100%"
      >
        <h3 style="font-weight: 400; margin-bottom: 2rem">
          {{ noDepartment ? `No Photos in ${project.name}: No Department` : `Add Photos To Category to View Here` }}
        </h3>
      </div>
      <div v-else class="photo-gallery-container">
        <div v-for="(values, propertyName, idx) in photosGroupedByCategory" :key="idx">
          <h3 class="category-name">{{ propertyName }}</h3>
          <div class="photo-gallery-content d-flex flex-wrap">
            <div v-for="photo in values" :key="photo.id" class="photo-gallery-item">
              <v-checkbox
                :key="photo.id"
                class="checkbox"
                on-icon="mdi-check-circle"
                off-icon="mdi-checkbox-blank-circle-outline"
                :input-value="isPhotoChecked(photo.id)"
                @change="handlePhotoChecked($event, photo)"
              >
                <template #label>
                  <PhotoCard :photo="photo" class="photo" />
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFile } from "@/util/downloadFile"
import { getPhotoViewerInformation, getPhotoZipFile } from "@/services/photoService"
import PhotoCard from "../Photos/PhotoCard.vue"
import CloseIcon from "../common_icons/CloseIcon.vue"
import EngieButton from "../forms/EngieButton.vue"
import DownloadIcon from "../common_icons/DownloadIcon.vue"

export default {
  components: { PhotoCard, CloseIcon, EngieButton, DownloadIcon },
  props: {
    searchLoading: {
      type: Boolean,
      default: false,
    },
    photoSearchInput: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: String,
      default: "",
    },
    departments: {
      type: Array,
      default: () => [],
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    photos: {
      type: Array,
      default: () => [],
    },
    markers: {
      type: Array,
      default: () => [],
    },
    noDepartment: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    photosGroupedByCategory: null,
    selectedCategoryPhotos: [],
    selectPhotosOpen: false,
    selectedPhotos: [],
    loading: false,
    photoDetailOpen: false,
    photoDetailLoading: true,
    photoDetailInformation: {},
    photoWithoutLocation: null,
    mapContainerHeight: "",
  }),
  computed: {
    selectedCount() {
      return this.selectedPhotos.length
    },
    categoryIds() {
      return this.selectedCategories.map(category => category.id)
    },
    categoryHasPhotos() {
      if (this.markers.length > 0) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.loading = true
      this.photosGroupedByCategory = this.groupByCategoryName(this.markers)
      this.loading = false
    },

    updateGroupedPhotos(groupedPhotos, categoryName, photo) {
      const updatedAccumulator = {
        ...groupedPhotos,
      }

      if (!updatedAccumulator[categoryName]) {
        updatedAccumulator[categoryName] = []
      }

      const photoEntry = updatedAccumulator[categoryName] || []

      updatedAccumulator[categoryName] = [...photoEntry, photo]

      return updatedAccumulator
    },

    groupByCategoryName(photos) {
      return photos.reduce((acc, currentPhoto) => {
        const updatedCurrentPhoto = { ...currentPhoto, thumbnailUrl: currentPhoto.previewImageUrl }
        if (currentPhoto.categories.length < 1 && !this.noDepartment) {
          const categoryName = "No Category"
          return this.updateGroupedPhotos(acc, categoryName, updatedCurrentPhoto)
        }
        if (currentPhoto.categories.length < 1 && this.noDepartment) {
          const categoryName = "No Department"
          return this.updateGroupedPhotos(acc, categoryName, updatedCurrentPhoto)
        }
        const categoryName = currentPhoto.categories[0].name
        return this.updateGroupedPhotos(acc, categoryName, updatedCurrentPhoto)
      }, {})
    },

    handleSelectOpen() {
      this.loading = true
      this.selectPhotosOpen = true
      this.loading = false
    },

    handleCloseSelect() {
      this.loading = true
      this.selectPhotosOpen = false
      this.selectedPhotos = []
      this.previousSelection = []
      this.loading = false
    },

    handleSelectAll() {
      this.previousSelection = this.selectedPhotos
      if (this.selectedPhotos.length === this.photos.length) {
        this.selectedPhotos = []
      } else {
        this.selectedPhotos = this.photos
      }
    },

    handlePhotoChecked(checked, item) {
      if (checked) {
        this.selectedPhotos = [...this.selectedPhotos, item]
      } else {
        this.selectedPhotos = this.selectedPhotos.filter(selectedPhoto => selectedPhoto.id !== item.id)
      }
    },

    isPhotoChecked(value) {
      return this.selectedPhotos.some(selectedPhoto => selectedPhoto.id === value)
    },

    async fetchPhotoViewerInformation(companyId, photoId) {
      try {
        const response = await getPhotoViewerInformation(companyId, photoId)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async handleOpenPhotoDetail(value) {
      this.photoDetailOpen = true
      const photoInformation = await this.fetchPhotoViewerInformation(this.companyId, value.id)
      this.photoDetailInformation = photoInformation
      this.photoDetailLoading = false
    },

    handleClosePhotoDetail() {
      this.photoDetailOpen = false
      this.photoDetailInformation = {}
      this.photoDetailLoading = true
    },

    async fetchPhotoZipFile(companyId, photoIds) {
      try {
        const response = await getPhotoZipFile(companyId, photoIds)
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    async handlePhotoDownload() {
      if (this.selectedPhotos.length > 1) {
        const selectedPhotoIds = this.selectedPhotos.map(photo => photo.id)
        const responseBlob = await this.fetchPhotoZipFile(this.companyId, selectedPhotoIds)
        downloadFile(responseBlob, "application/zip", "EngieCamPhotos.zip")
      } else {
        const responseBlob = await this.fetchPhotoZipFile(this.companyId, this.selectedPhotos[0].id)
        downloadFile(responseBlob, "application/zip", `${this.selectedPhotos[0].id}.zip`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-container {
  height: 100%;
  width: 100%;
  padding: 0 3rem;
}

.photo-gallery-container {
  width: 100%;
  padding-bottom: 3rem;
}

.photo-gallery-content {
  height: 100% !important;
  width: 100% !important;
  row-gap: 1rem;
  column-gap: 1rem;
  margin: 0px;

  width: 36.7rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.photo-gallery-content::-webkit-scrollbar {
  display: none;
}

.control-container {
  padding: 0rem;
}

.control-btn {
  text-transform: none;
  font-size: 1.2rem;
  line-height: 1.7rem;
  display: flex;
}
.control-btn-icon {
  height: 2.2rem;
  margin-right: 8px;
  padding-bottom: 2px;
}
.count {
  font-family: "CamptonRegular";
  font-weight: 100;
  margin-left: 2.5rem;
}

.category-name {
  padding-top: 2.8rem;
  font-size: 2.5rem;
  font-family: "CamptonMedium";
  font-weight: 400;
  line-height: 3.3rem;
  color: var(--navy);
  margin-bottom: 1.3rem;
}

.checkbox {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

::v-deep {
  .v-input--selection-controls__input {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin: 0;
    z-index: 2;
  }
  .v-icon {
    color: white;
    border-radius: 100%;
  }
  .mdi-checkbox-blank-circle-outline {
    background-color: white;
    border: 1px solid var(--orange);
  }
  .mdi-check-circle {
    background-color: white;
    border: 2px solid white;
    margin-top: 0.68px;
  }
}
</style>
