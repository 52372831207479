<template>
  <div class="role-based-dashboard">
    <div v-if="isLoading" class="full-page-loading-spinner">
      <v-progress-circular v-if="isLoading" indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <ShareProjectsContainer
      v-if="shouldShowShareProjects"
      :user="currentUser"
      :user-company-id="userCompanyId"
      :company-id="companyId"
      :project="project"
    />
  </div>
</template>

<script>
import { getCurrentAuthUserRecord } from "@/services/authService"
import { getCurrentUserCompanyId } from "@/services/userInformationService"
import { getAllExternalUsersWhenMembersOfProject, getOneCompanyProject } from "@/services/projectService"
import ShareProjectsContainer from "./ShareProjectsContainer.vue"

export default {
  components: { ShareProjectsContainer },
  data: () => ({
    shouldShowShareProjects: false,
    isLoading: true,
    currentUser: null,
    userCompanyId: null,
    companyId: null,
    project: null,
    projectExternalUsers: null,
  }),
  mounted() {
    this.initialize()
  },
  methods: {
    // check if user is logged in, check if user's company is the same as the project they are being shared
    async fetchCurrentUser() {
      try {
        const user = await getCurrentAuthUserRecord()
        return user
      } catch (error) {
        return null
      }
    },

    async fetchCompanyId() {
      try {
        const response = await getCurrentUserCompanyId()
        return response
      } catch (error) {
        return null
      }
    },

    async fetchProject(companyId, projectId) {
      try {
        const response = await getOneCompanyProject(companyId, projectId)
        return response
      } catch (error) {
        return null
      }
    },

    // fetch external project members
    async fetchExternalProjectUsers(companyId, projectId) {
      try {
        const response = await getAllExternalUsersWhenMembersOfProject(companyId, projectId)
        return response
      } catch (error) {
        return null
      }
    },

    async initialize() {
      const { companyId } = this.$route.params
      this.companyId = companyId
      const response = await this.fetchCurrentUser()

      // if user has account
      if (response) {
        this.currentUser = response
        this.userCompanyId = await this.fetchCompanyId()
        this.projectExternalUsers = await this.fetchExternalProjectUsers(
          this.$route.params.companyId,
          this.$route.params.projectId
        )
        const isUserExternalProjectMember = this.projectExternalUsers.gallery.find(
          projectExternalUser => projectExternalUser.id === this.currentUser.id
        )

        // if user has an account, and their company Id is not the same as the parameter Id, but the user is an external member of the project
        if (this.userCompanyId !== this.$route.params.companyId && isUserExternalProjectMember) {
          this.project = await this.fetchProject(this.$route.params.companyId, this.$route.params.projectId)
          this.shouldShowShareProjects = true
          this.isLoading = false

          // if user has an account, and their company id is not the same as the parameter Id, and the user is NOT an external member of the project
        } else if (this.userCompanyId !== this.$route.params.companyId) {
          this.project = await this.fetchProject(this.$route.params.companyId, this.$route.params.projectId)
          this.shouldShowShareProjects = true
          this.isLoading = false

          // if user has an account, and their company id matches the route parameter
        } else {
          this.shouldShowShareProjects = true
          this.isLoading = false
          this.$router.push({
            path: `/projects/${this.$route.params.projectId}/photos`,
            params: { projectId: `${this.$route.params.projectId}` },
            replace: true,
          })
        }

        // if the user does not have an account
      } else {
        this.project = await this.fetchProject(this.$route.params.companyId, this.$route.params.projectId)
        this.shouldShowShareProjects = true
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.role-based-dashboard {
  height: 100vh;
  width: 100%;
}
.full-page-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
