<template>
  <div id="address-text-input-container" class="engie-address-text-input">
    <v-menu v-model="menuOpen" class="address-menu" attach="#address-text-input-container" offset-y nudge-top="20">
      <template #activator="{ attrs }">
        <div v-bind="attrs">
          <v-text-field
            id="autocomplete"
            v-model="search"
            class="address-text-input"
            :disabled="disabled"
            :label="label"
            :placeholder="placeholder"
            clearable
            outlined
            :type="type"
            :rules="required !== null ? [requiredRule] : []"
            :error-messages="errorMessages"
            :min="min"
            :max="max"
            :autofocus="autofocus"
            @blur="handleBlur($event)"
            @keydown.enter="handleEnterPressed()"
            @click:clear="handleInputClear()"
          >
          </v-text-field>
        </div>
      </template>
      <v-list :light="false">
        <div
          v-for="item in googleAddresses"
          :key="item.place_id"
          class="address-item"
          active-class="address-item-active"
          @click="selectAddress(item)"
          @keydown.enter="selectAddress(item)"
          @mouseover="toggleActiveClass($event)"
          @focusin="toggleActiveClass($event)"
          @mouseleave="toggleActiveClass($event)"
          @focusout="toggleActiveClass($event)"
        >
          {{ item.description }}
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import { getGoogleMapsApiKey } from "@/util/urls"

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    currentAddress: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
    phone: {
      type: Boolean,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: null,
    },
    requireVerified: {
      type: Boolean,
      default: false,
    },
    addressError: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    selectedAddress: null,
    search: null,
    googleAddresses: [],
    menuOpen: false,
    loading: false,
    response: null,
    errorMessages: [],
  }),
  computed: {
    locationFoundItems() {
      return this.googleAddresses
    },
  },
  watch: {
    search(previousValue, currentValue) {
      if (currentValue === null || previousValue === null) {
        this.menuOpen = false
        return
      }
      if (previousValue !== currentValue) {
        this.getSuggestions(currentValue)
        if (this.menuOpen === false) {
          this.menuOpen = true
        }
      }
    },
    addressError(currentValue) {
      this.errorMessages = [currentValue]
    },
  },
  async mounted() {
    const apiKey = `${getGoogleMapsApiKey()}`
    const googleMapApi = new Loader({
      apiKey,
      libraries: ["places"],
    })
    this.google = await googleMapApi.load()
    if (this.currentAddress !== null) {
      this.search = this.currentAddress
      this.selectedAddress = this.currentAddress
    }
  },
  methods: {
    async getSuggestions(searchText) {
      const rawResult = await this.searchLocation(searchText)
      this.googleAddresses = rawResult
    },

    async searchLocation(val) {
      const promise = await new Promise((resolve, reject) => {
        const displaySuggestions = (predictions, status) => {
          if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
            reject(status)
          }
          resolve(predictions)
        }

        const service = new this.google.maps.places.AutocompleteService()
        service.getQueryPredictions(
          {
            input: val,
            types: ["geocode"],
          },
          displaySuggestions
        )
      })
      return promise
    },

    async getLatLngFromAddress(address) {
      if (address !== null) {
        const geocoder = new this.google.maps.Geocoder()
        const result = await geocoder.geocode({ address }, (results, status) => {
          if (status === "OK") {
            return results
          }
          return null
        })
        return result.results[0]
      }
      return null
    },

    async submitVerifiedAddressLocation() {
      if (this.selectedAddress !== null) {
        this.errorMessages = []
        const address = await this.getLatLngFromAddress(this.selectedAddress.description)
        const latitude = address.geometry.location.lat()
        const longitude = address.geometry.location.lng()
        this.menuOpen = false
        this.$emit("location-selected", { latitude, longitude, description: this.selectedAddress.description })
      }
      return null
    },

    selectAddress(item) {
      this.search = item.description
      this.selectedAddress = item
      this.submitVerifiedAddressLocation()
    },

    submitWithoutVerification() {
      if (this.requireVerified && this.search !== null) {
        this.errorMessages = ["Verified Address must be selected."]
      } else {
        this.errorMessages = []
        this.$emit("location-selected", { description: this.search })
      }
    },

    toggleActiveClass(event) {
      event.target.classList.toggle("address-item-active")
    },

    handleInputClear() {
      this.errorMessages = []
      this.selectedAddress = null
      this.search = null
      this.googleAddresses = null
      this.menuOpen = false
    },

    handleBlur() {
      this.submitWithoutVerification()
    },
    handleEnterPressed() {
      this.submitWithoutVerification()
      this.menuOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.engie-address-text-input::v-deep .v-text-field {
  width: 100rem;
  label {
    color: var(--charcoal);
    text-align: center;
  }
  fieldset {
    border-color: var(--light-grey);
    border-radius: 0.5rem;
  }

  legend {
    // Vuetify sets this property in javascript. So, a necessary sin.
    width: 0 !important;
  }

  &.v-input--is-focused {
    caret-color: var(--orange) !important;

    fieldset {
      border-color: var(--grey);
      border-width: 0.1rem;
      border-radius: 0;
    }
  }

  .error--text {
    color: red !important;
  }
}

.engie-address-text-input.animated-label::v-deep .v-text-field {
  input {
    padding: 2.1rem 0 0.8rem;
  }
  &.v-input--is-focused,
  &.v-input--is-dirty {
    label.v-label--active {
      color: var(--mid-grey) !important;
      transform: translate(0, -1.2rem) scale(0.7) !important;
    }
  }
}

.engie-address-text-input.inline-label::v-deep .v-text-field {
  &.v-input--is-focused,
  &.v-input--is-dirty {
    label.v-label--active {
      opacity: 0;
      transform: none;
    }
  }
}

.engie-address-text-input:not(.animated-label):not(.inline-label)::v-deep .v-text-field {
  padding-top: 5rem;
  label {
    transform: translate(-1rem, -5rem);
  }
}

.engie-address-text-input::v-deep {
  .v-messages__message {
    line-height: 1.4rem;
  }
}

.loading-enter-active,
.loading-leave-active {
  transition: all 1s ease-out;
}
.loading-enter,
.loading-leave-to {
  opacity: 0;
}

::v-deep {
  .v-input__append-inner {
    align-self: center !important;
    margin-top: 0 !important;
  }
}

.address-item {
  cursor: pointer;
  transition: 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 1.4rem;
  font-family: "CamptonRegular";
  min-height: 4.8rem;
  padding: 0 1.6rem;
  color: rgba(0, 0, 0, 0.87) !important;
  display: flex;
  align-items: center;
}

.address-item-active {
  color: var(--primary) !important;
  background-color: rgba(243, 115, 83, 0.25) !important;
}

.first {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.last {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.v-menu__content {
  border: 1px solid var(--grey) !important;
  border-radius: 0 !important;
  margin-top: 0.5rem;
  padding: 0 !important;
  box-shadow: none;
  &::v-deep {
    .v-list {
      padding: 0 !important;
    }
  }
}

.v-menu__content,
.menuable__content__active {
  overflow-x: visible !important;
  overflow-y: visible !important;
  box-shadow: none !important;
}

.address-text-input::v-deep {
  .v-input__slot {
    background-color: white;
  }
}
</style>
