import { render, staticRenderFns } from "./NoDeptProjectPhotoSwitch.vue?vue&type=template&id=79f3ad43&scoped=true&"
import script from "./NoDeptProjectPhotoSwitch.vue?vue&type=script&lang=ts&"
export * from "./NoDeptProjectPhotoSwitch.vue?vue&type=script&lang=ts&"
import style0 from "./NoDeptProjectPhotoSwitch.vue?vue&type=style&index=0&id=79f3ad43&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f3ad43",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})
