<template>
  <ProjectDashboardContent>
    <template #title>Photos by Department</template>
    <template #content>
      <div v-if="loading" class="project-department-loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
      </div>
      <div v-else class="d-flex flex-wrap" style="width: 100%">
        <div v-for="department in departments" :key="department.id" class="card-container">
          <ProjectDashCard v-if="department.id !== null">
            <template #img-content>
              <router-link to="" @click.native="handleCardClick(department)">
                <v-img
                  v-if="department.latestPhotoThumbnailUrl"
                  style="border-radius: 15px"
                  height="100%"
                  :src="department.latestPhotoThumbnailUrl"
                ></v-img>
                <v-img v-else style="border-radius: 15px; background-color: var(--lightest-grey)" height="100%"></v-img>
              </router-link>
            </template>
            <template #text-content>
              <v-card-title class="title-content">
                {{ department.name }}
              </v-card-title>
            </template>
          </ProjectDashCard>

          <ProjectDashCard v-else>
            <template #img-content>
              <router-link to="" @click.native="handleNoDepartmentClick()">
                <v-img
                  v-if="department.latestPhotoThumbnailUrl"
                  style="border-radius: 15px"
                  height="100%"
                  :src="department.latestPhotoThumbnailUrl"
                ></v-img>
                <v-img v-else style="border-radius: 15px; background-color: var(--lightest-grey)" height="100%"></v-img>
              </router-link>
            </template>
            <template #text-content>
              <v-card-title class="title-content">
                {{ department.name }}
              </v-card-title>
            </template>
          </ProjectDashCard>
        </div>
      </div>
    </template>
  </ProjectDashboardContent>
</template>

<script>
import Vue from "vue"
import ProjectDashboardContent from "@/components/ProjectDashboard/ProjectDashboardContent.vue"
import ProjectDashCard from "@/components/ProjectDashboard/ProjectDashCard.vue"
import { getAllCompanyDepartmentsGallery } from "@/services/projectService"

export default Vue.extend({
  components: {
    ProjectDashCard,
    ProjectDashboardContent,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: String,
      default: "",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    selectedDepartment: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      projectDepartments: [],
      loading: false,
      companyDepartments: [],
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    handleCardClick(department) {
      this.$emit("set-department", department)
      this.$router.push(`/share/companies/${this.companyId}/projects/${this.project.id}/departments/${department.id}`)
    },

    handleNoDepartmentClick() {
      this.$router.push(
        `/share/companies/${this.companyId}/projects/${this.project.id}/departments/no-department/no-dept-map`
      )
    },

    async fetchCompanyDepartments(companyId, projectId) {
      const response = await getAllCompanyDepartmentsGallery(companyId, projectId)
      return response
    },

    async initialize() {
      this.loading = true
      this.loading = false
    },
  },
})
</script>

<style lang="scss" scoped>
.card-container {
  margin: 0 12px 24px 12px;
  width: 36.7rem;
  height: 25.7rem;
}
.edit-btn {
  z-index: 999;
}

.card {
  height: 24.4rem;
  width: 36.7rem;
  border-radius: 1.5rem;
}

.text-box {
  background-color: transparent;
  height: 5.1rem;
  padding: 0 1.6rem;
  position: absolute;
  bottom: -1px;
  display: flex;
  color: var(--black) !important;
  background-color: white;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}

.v-card__title {
  font-family: "CamptonMedium";
  font-size: 1.5rem;
  line-height: 1.4rem;
  font-weight: 400;
  z-index: 3;
  padding: 0;
}

.project-department-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.dialog-title {
  font-weight: 800;
}

.dialog-content-department {
  padding: 1.5rem;
}

.subheader {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--black);
  margin-bottom: 1rem;
  font-weight: 400;
}

.submit-btn {
  text-align: center;
}

.edit-btn {
  z-index: 2;
  background-color: white !important;
  padding: 2rem 1rem !important;
  border-radius: 100%;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
}

::v-deep {
  .engie-text-input:not(.animated-label),
  .v-text-field {
    padding-top: 0px !important;
    .v-input__slot {
      border: 2px solid var(--grey);
    }
  }
}
</style>
