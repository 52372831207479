
































import Vue from "vue"
import { Workspace } from "@/types/Workspaces"
import { UserRecord } from "@/types/UserRecord"
import SideNav from "../components/Dashboard/SideNav.vue"
import MainToolbar from "../components/Dashboard/MainToolbar.vue"
import { getCurrentAuthUserRecord, isSuperAdmin } from "../services/authService"

export default Vue.extend({
  components: {
    SideNav,
    MainToolbar,
  },
  data() {
    return {
      isSuperAdmin: null as null | boolean,
      isLoading: true,
      currentYear: new Date().getFullYear(),
      userId: null as null | string,
      userHomeCompanyId: null as any,
      userHomeCompanyName: null as any,
      companyId: null as any,
      isExternalUser: false,
    }
  },
  computed: {
    shouldShowNonSuperAdminDashboard(): boolean | null {
      return !this.isLoading && !this.isSuperAdmin
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async checkIsSuperAdmin() {
      this.isSuperAdmin = await isSuperAdmin()
    },

    async setUserCompanyId() {
      const user: UserRecord = await getCurrentAuthUserRecord()
      this.userHomeCompanyId = user.companyId
      this.userId = user.id
      const currentWorkspace: Workspace = JSON.parse(
        localStorage.getItem(`currentWorkspace[${this.userId}]`) || `${null}`
      )
      if (currentWorkspace) {
        this.companyId = currentWorkspace.companyId
      } else {
        this.companyId = this.userHomeCompanyId
      }
    },

    isUserExternal() {
      if (this.userHomeCompanyId !== this.companyId) {
        return true
      }
      return false
    },

    async initialize() {
      await this.checkIsSuperAdmin()
      await this.setUserCompanyId()
      this.isExternalUser = this.isUserExternal()
      this.isLoading = false
    },

    handleWorkspaceChanged(value: any) {
      this.isLoading = true
      this.companyId = value.companyId
      this.isExternalUser = this.isUserExternal()
      this.isLoading = false
    },

    handleWorkspaceUpdated(value: any) {
      this.isLoading = true

      const currentWorkspace: Workspace = JSON.parse(
        localStorage.getItem(`currentWorkspace[${this.userId}]`) || `${null}`
      )
      currentWorkspace.name = value.name
      localStorage.setItem(`currentWorkspace[${this.userId}]`, JSON.stringify(currentWorkspace))
      this.userHomeCompanyName = value.name
      this.isLoading = false
    },
  },
})
