<template>
  <div class="photo-sort-bar-container">
    <div class="d-flex align-center">
      <PhotoSwitch :is-external-user="isExternalUser" @clicked="handleSwitchClick" />
      <EngieMenu :default-option="defaultStatus" :options="statusOptions" @submit="handleStatusClick" />
      <EngieMenu :default-option="defaultSort" :options="sortOptions" @submit="handleSortClick" />
    </div>

    <EngieSearchInput class="photo-search" :label="'by Notes'" @input="debouncedHandler"></EngieSearchInput>
  </div>
</template>

<script>
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import EngieSearchInput from "../forms/EngieSearchInput.vue"
import EngieMenu from "../EngieMenu.vue"
import PhotoSwitch from "./PhotoSwitch.vue"

export default {
  components: { EngieMenu, EngieSearchInput, PhotoSwitch },
  props: {
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaultSort: { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
    sortOptions: [
      { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
      { label: "Oldest to Newest", fieldValue: "createdDateTime", directionValue: "asc" },
    ],
    defaultStatus: { label: "Unassigned", fieldValue: true },
    statusOptions: [
      { label: "Unassigned", fieldValue: true },
      { label: "All Photos", fieldValue: false },
    ],
    loading: false,
  }),
  created() {
    this.debouncedHandler = debounce(event => {
      this.loading = true
      this.searchPhotos(event)
    }, DEBOUNCE_INTERVAL)
  },
  methods: {
    handleSortClick(value) {
      this.$emit("photo-sort-clicked", value)
    },
    searchPhotos(value) {
      this.$emit("search", value)
    },
    handleStatusClick(value) {
      this.$emit("photo-status-clicked", value)
    },
    handleSwitchClick(value) {
      this.$emit("photo-switch-clicked", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-sort-bar-container {
  display: flex;
  flex-direction: column;

  .photo-search::v-deep {
    width: 100%;
    max-width: 116rem;

    label {
      padding-top: 1px;
      font-size: 1.2rem;
    }

    .engie-search-input,
    .v-text-field {
      border-color: var(--light-grey) !important;
      max-height: 5.3rem !important;
      height: 5.3rem !important;
    }
    .v-input__prepend-outer {
      margin-top: 1.4rem !important;
    }
    .v-input__control {
      min-height: 5.1rem !important;
    }
  }
}
</style>
