

































































































import Vue from "vue"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getSendForgotPasswordUrl, getUserSessionCleanupUrl } from "@/util/urls"
import AuthForm from "../components/AuthForm.vue"
import EngieButton from "../components/forms/EngieButton.vue"
import EngieTextButton from "../components/forms/EngieTextButton.vue"
import EngieTextInput from "../components/forms/EngieTextInput.vue"
import EngieDialog from "../components/EngieDialog.vue"
import EngieSuccessSnackbar from "../components/EngieSuccessSnackbar.vue"
import { TooManyDeviceSessionsError, CompanyPausedError, signIn } from "../services/authService"

export default Vue.extend({
  components: { AuthForm, EngieTextInput, EngieDialog, EngieButton, EngieTextButton, EngieSuccessSnackbar },
  data() {
    return {
      errorOccurred: false,
      emailAddress: "",
      password: "",
      loggingIn: false,
      errorMessage: "",
      maxDevicesUsed: false,
      loggingoutOfSessionsAndLoggingIn: false,
      forgotPasswordDialogOpen: false,
      forgotPasswordEmailAddress: "",
      sendingForgotPasswordEmail: false,
      showPasswordResetEmailSentSnackbar: false,
    }
  },
  methods: {
    async handleLogin() {
      this.loggingIn = true
      this.maxDevicesUsed = false

      try {
        await signIn(this.emailAddress, this.password)

        this.$router.push("/")
      } catch (error) {
        if (error instanceof TooManyDeviceSessionsError) {
          this.maxDevicesUsed = true
        } else if (error instanceof CompanyPausedError) {
          this.errorMessage = "Your account has been placed on hold."
        } else {
          this.errorMessage = "Invalid login credentials."
        }

        this.errorOccurred = true
        this.loggingIn = false
      }
    },
    async handleCleanupSessionsAndLoginClicked() {
      await makeAuthenticatedRequest(getUserSessionCleanupUrl(this.emailAddress), "DELETE", { password: this.password })
      this.maxDevicesUsed = false
      this.handleLogin()
    },
    handleCleanupSessionsCancelled() {
      this.maxDevicesUsed = false
    },
    openForgotPasswordDialog() {
      this.forgotPasswordEmailAddress = ""
      this.forgotPasswordDialogOpen = true
    },
    handleForgotPasswordDialogClosed() {
      this.forgotPasswordDialogOpen = false
    },
    async sendForgotPasswordEmail() {
      this.sendingForgotPasswordEmail = true

      await makeAuthenticatedRequest(getSendForgotPasswordUrl(this.forgotPasswordEmailAddress), "POST")

      this.sendingForgotPasswordEmail = false
      this.forgotPasswordDialogOpen = false
      this.showPasswordResetEmailSentSnackbar = true
    },
    handlePasswordResentSentSnackbarClosed() {
      this.showPasswordResetEmailSentSnackbar = false
    },
    handleTooManyDevicesDialogClosed() {
      this.handleCleanupSessionsCancelled()
    },
  },
})
