





















import Vue from "vue"
import { Photo } from "@/types/Photo"
import PhotosSortBar from "@/components/Photos/PhotosSortBar.vue"
import PhotoGallery from "@/components/Photos/PhotoGallery.vue"
import Page from "@/components/Page.vue"
import { SortOption } from "@/types/SortOption"
import { getAllCompanySharedPhotoCollectionsGallery } from "@/services/photoService"
import { PhotoResponse } from "@/types/PhotoResponse"

export default Vue.extend({
  components: {
    Page,
    PhotosSortBar,
    PhotoGallery,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
    photoCollection: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchInput: "",
      sortSelect: { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
      organizedPhotos: {},
      loading: true,
      observer: null as unknown as IntersectionObserver,
      pageNumber: 0,
      hasNextPage: false as Boolean,
      photoGallery: null as any,
      currentPhotos: [] as Photo[],
      totalPhotos: 0 as number,
    }
  },
  async mounted() {
    await this.initialize()
  },
  methods: {
    handlePhotoSortClicked(value: SortOption) {
      this.sortSelect = value
      this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },

    handleSearch(value: string) {
      this.searchInput = value
      this.resetPhotos()
      if (this.hasNextPage) {
        this.initObserver()
      }
    },

    async fetchPhotoCollection(
      companyId: string,
      photoCollectionId: string,
      sortByField: string,
      sortDirection: string,
      pageSize: string,
      pageNumber: string,
      search: string
    ) {
      try {
        const response = await getAllCompanySharedPhotoCollectionsGallery(
          companyId,
          photoCollectionId,
          sortByField,
          sortDirection,
          pageSize,
          pageNumber,
          search
        )
        return response
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    getMonthAndYear(dateString: string) {
      const date = new Date(dateString)

      const month = date.toLocaleString("default", { month: "long" })
      const year = date.toLocaleString("default", { year: "numeric" })
      return { month, year }
    },

    updatedGroupedPhotos(yearMap: any, year: string, month: string, photo: Photo) {
      const newMap = new Map<string, Map<string, Photo[]>>(yearMap)
      const yearEntry = newMap.get(year)
      if (yearEntry) {
        const monthEntry = yearEntry.get(month)
        if (monthEntry) {
          yearEntry.set(month, [...monthEntry, photo])
        } else {
          yearEntry.set(month, [photo])
        }
      } else {
        const monthMap = new Map()
        monthMap.set(month, [photo])
        newMap.set(year, monthMap)
      }
      return newMap
    },

    groupByYearAndMonth(photos: any) {
      return photos.reduce((acc: any, currentPhoto: any) => {
        const { month, year } = this.getMonthAndYear(currentPhoto.createdDateTime)

        return this.updatedGroupedPhotos(acc, year, month, currentPhoto)
      }, new Map())
    },

    async resetPhotos() {
      this.pageNumber = 1
      const { photoCollectionId } = this.$route.params
      try {
        const photos: PhotoResponse = await this.fetchPhotoCollection(
          this.companyId,
          photoCollectionId,
          this.sortSelect.fieldValue,
          this.sortSelect.directionValue,
          "48",
          `${this.pageNumber}`,
          this.searchInput
        )
        this.currentPhotos = photos.items
        const updatedPhotos = this.groupByYearAndMonth(photos.items)
        this.totalPhotos = photos.total
        this.organizedPhotos = updatedPhotos
        this.hasNextPage = photos.hasNextPage
        this.loading = false
      } catch (error) {
        this.loading = false
        throw new Error(`${error}`)
      }
    },

    async initialize() {
      this.loading = true
      await this.resetPhotos()
      if (this.observer) {
        this.observer.unobserve(this.photoGallery)
      }
      this.initObserver()
    },

    async addMorePhotos() {
      this.observer.unobserve(this.photoGallery)
      this.pageNumber += 1
      const { photoCollectionId } = this.$route.params
      try {
        const addedPhotosResponse: PhotoResponse = await this.fetchPhotoCollection(
          this.companyId,
          photoCollectionId,
          this.sortSelect.fieldValue,
          this.sortSelect.directionValue,
          "48",
          `${this.pageNumber}`,
          this.searchInput
        )
        if (!addedPhotosResponse.hasNextPage) {
          this.hasNextPage = false
          this.currentPhotos = [...this.currentPhotos, ...addedPhotosResponse.items]
          const updatedPhotos = this.groupByYearAndMonth(this.currentPhotos)
          return updatedPhotos
        }
        this.initObserver()
        this.currentPhotos = [...this.currentPhotos, ...addedPhotosResponse.items]
        const updatedPhotos = this.groupByYearAndMonth(this.currentPhotos)
        return updatedPhotos
      } catch (error) {
        throw new Error(`${error}`)
      }
    },

    initObserver() {
      this.photoGallery = document.getElementById("photo-gallery")
      const contentContainer: any = document.getElementById("last-photo")
      const options = {
        root: contentContainer,
        // create an array of thresholds from .00 to .99
        threshold: [...Array(10)].map((el, index) => 0.1 * index),
      }

      const handleIntersectionEvent = async (entry: any) => {
        if (entry[0].intersectionRect.y <= entry[0].rootBounds.y + 20) {
          const photos = await this.addMorePhotos()
          this.organizedPhotos = photos
        }
      }

      this.observer = new IntersectionObserver(handleIntersectionEvent, options)
      this.observer.observe(this.photoGallery)
    },

    async handleEndOfScroll() {
      const photos = await this.addMorePhotos()
      this.organizedPhotos = photos
    },
  },
})
