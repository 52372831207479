<template>
  <Page :title="!isExternalUser ? 'Dashboard' : 'Recent Projects'" style="height: 100%">
    <div v-if="loading" class="projects-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else class="dashboard-content-container">
      <UnassignedPhotosSummary
        v-if="!isExternalUser"
        :number-of-unassigned-photos="numberOfUnassignedPhotos"
        :featured-photo-url="latestUnassignedPhotoThumbnailUrl"
      />
      <PageTitle v-if="!isExternalUser">Recent Projects</PageTitle>
      <div class="recent-projects-container">
        <RecentProjectsGallery :company-id="companyId" />
      </div>
    </div>
  </Page>
</template>

<script>
import { getCurrentUserCompanyId } from "@/services/userInformationService"
import RecentProjectsGallery from "@/components/UserDashboard/RecentProjectsGallery.vue"
import { getUnassignedPhotos } from "@/services/photoService"
import UnassignedPhotosSummary from "../components/UserDashboard/UnassignedPhotosSummary.vue"
import Page from "../components/Page.vue"
import PageTitle from "../components/PageTitle.vue"

export default {
  components: {
    Page,
    PageTitle,
    UnassignedPhotosSummary,
    RecentProjectsGallery,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    numberOfUnassignedPhotos: 0,
    latestUnassignedPhotoThumbnailUrl: "",
  }),
  watch: {
    companyId(currentValue, previousValue) {
      if (currentValue === previousValue) {
        this.loading = true
        this.initialize()
        return true
      }
      this.sortLoading = true
      this.loading = true
      this.initialize()
      return false
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async fetchCompanyId() {
      const response = await getCurrentUserCompanyId()
      return response
    },

    async fetchUnassignedPhotoInformation(companyId) {
      const response = await getUnassignedPhotos(companyId)
      return response
    },

    async initialize() {
      const unassignedPhotoInformation = await this.fetchUnassignedPhotoInformation(this.companyId)
      this.numberOfUnassignedPhotos = unassignedPhotoInformation.unassignedPhotosCount
      if (unassignedPhotoInformation.latestUnassignedPhotoThumbnailUrl) {
        this.latestUnassignedPhotoThumbnailUrl = unassignedPhotoInformation.latestUnassignedPhotoThumbnailUrl
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.recent-projects-container {
  width: 100%;
}
.projects-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.dashboard-content-container {
  height: calc(100% - 3rem);
}
</style>
