<template>
  <EngieDialog
    :open="dialogOpen"
    :dialog-title="'Add Team Members'"
    :dialog-max-width="'76.5rem'"
    @dialog-closed="closeModal()"
  >
    <div class="dialog-search-container" style="padding: 2rem 0">
      <EngieSearchInput @input="debouncedHandler" />
    </div>
    <div v-if="loading" class="dialog-content" style="height: 550px">
      <div class="dialog-loading-spinner-container">
        <v-progress-circular indeterminate color="primary" :size="90" style="height: 480px"></v-progress-circular>
      </div>
    </div>
    <div v-else class="dialog-content">
      <v-virtual-scroll :items="userSearchResults" item-height="94px" height="480px" bench="5">
        <template #default="{ item }">
          <div class="v-scroll-card-container" style="padding: 0 2rem">
            <EngieTeamCard :user="item">
              <template #card-action-btn>
                <v-checkbox
                  color="primary"
                  :on-icon="'mdi-check-circle'"
                  :off-icon="'mdi-checkbox-blank-circle-outline'"
                  class="dialog-checkbox"
                  :input-value="isUserChecked(item.id)"
                  @change="handleUserChecked($event, item)"
                ></v-checkbox>
              </template>
            </EngieTeamCard>
          </div>
        </template>
      </v-virtual-scroll>
      <div class="dialog-submit-btn-container d-flex justify-center" style="margin: 2rem 0 1rem 0">
        <EngieButton color="primary" @click="handleTeamSubmit($event)">Add Selected Members</EngieButton>
      </div>
    </div>
  </EngieDialog>
</template>

<script>
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import EngieDialog from "@/components/EngieDialog.vue"
import EngieTeamCard from "@/components/EngieTeamCard.vue"
import EngieButton from "@/components/forms/EngieButton.vue"
import EngieSearchInput from "@/components/forms/EngieSearchInput.vue"
import { getAllCompanyUsersWithProfilePicture } from "@/services/projectService"
import { getCurrentUserCompanyId } from "@/services/userInformationService"

export default {
  components: { EngieSearchInput, EngieDialog, EngieTeamCard, EngieButton },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    currentInternalTeamMembers: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    searchValue: "",
    selectedUsers: [],
    allUsers: [],
    userSearchResults: [],
  }),
  computed: {
    allUsersWithoutCurrentInternalTeam() {
      return this.allUsers.filter(
        userFromAllUsers =>
          !this.currentInternalTeamMembers.find(userFromTeam => userFromAllUsers.id === userFromTeam.id)
      )
    },
    allUsersWithoutCurrentUser() {
      return this.allUsers.filter(userFromAllUsers => userFromAllUsers.id !== this.currentUser.id)
    },
  },
  created() {
    this.debouncedHandler = debounce(event => {
      this.loading = true
      this.searchUsers(event)
    }, DEBOUNCE_INTERVAL)
  },

  mounted() {
    this.initializeUsersAndSearchResults()
  },

  beforeUnmount() {
    this.debouncedHandler.cancel()
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },

    handleTeamSubmit() {
      this.$emit("team-selected", this.selectedUsers)
    },

    searchUsers(value) {
      if (this.$props.currentInternalTeamMembers) {
        this.userSearchResults = this.allUsersWithoutCurrentInternalTeam.filter(user => {
          const name = `${user.displayName.toLowerCase()}`
          return name.includes(value.toLowerCase())
        })
        this.loading = false
      } else {
        this.userSearchResults = this.allUsersWithoutCurrentUser.filter(user => {
          const name = `${user.displayName.toLowerCase()}`
          return name.includes(value.toLowerCase())
        })
        this.loading = false
      }
    },

    async fetchUsersByCompany(companyId) {
      const allUsers = await getAllCompanyUsersWithProfilePicture(companyId)

      return allUsers.items
    },

    async initializeUsersAndSearchResults() {
      const companyId = await getCurrentUserCompanyId()
      this.allUsers = await this.fetchUsersByCompany(companyId)
      // this.userSearchResults = this.allUsersWithoutCurrentInternalTeam
      if (this.$props.currentInternalTeamMembers) {
        this.addCurrentTeamMembersToSelected()
        this.userSearchResults = this.allUsersWithoutCurrentInternalTeam
      } else {
        this.addCurrentUserToSelected()
        this.userSearchResults = this.allUsersWithoutCurrentUser
      }
    },

    addCurrentTeamMembersToSelected() {
      this.selectedUsers = [...this.currentInternalTeamMembers]
    },

    addCurrentUserToSelected() {
      this.selectedUsers = [...this.selectedUsers, this.currentUser]
    },

    handleUserChecked(checked, item) {
      if (checked) {
        this.selectedUsers = [...this.selectedUsers, item]
      } else {
        this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser !== item)
      }
    },

    isUserChecked(value) {
      return this.selectedUsers.some(selectedUser => selectedUser.id === value)
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-loading-spinner-container {
  height: 80%;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  &v::deep {
    .v-dialog {
      height: 100px !important;
    }
  }
}

.exit-btn {
  height: 2.7rem;
  width: 2.7rem;
  transform: rotate(45deg);
  padding: 5px !important;
}

.dialog-checkbox {
  height: 3rem;
  width: 3rem;
  padding: 0 !important;

  &::v-deep {
    i {
      font-size: 32px;
    }
    .mdi-checkbox-blank-circle-outline {
      color: var(--secondary);
    }
    .v-input--selection-controls__input {
      padding-bottom: 2rem !important;
    }
    .v-input--selection-controls__ripple {
      top: calc(50% - 3.3rem);
    }
  }
}
</style>
