import { Validation } from "vuelidate"
import { ValidationRule } from "vuelidate/lib/validators"
import { DEFAULT_REQUIRED_FIELD_ERROR_MESSAGE } from "./checkRequired"

type EmailValidation = Validation & { required: ValidationRule; email: ValidationRule; notTaken: ValidationRule }

export const checkEmail = (emailProperty: EmailValidation, checkRequired = true) => {
  if (!emailProperty.$dirty) {
    return []
  }

  if (checkRequired && !emailProperty.required) {
    return [DEFAULT_REQUIRED_FIELD_ERROR_MESSAGE]
  }

  if (!emailProperty.email) {
    return ["Please enter a valid email address"]
  }

  if (!emailProperty.notTaken) {
    return ["This email address is already in-use"]
  }

  return []
}
