











import Vue from "vue"

export default Vue.extend({
  props: {
    word: {
      type: String,
      required: true,
    },
    andOr: {
      type: String,
      default: null,
    },
    definition: {
      type: String,
      required: true,
    },
    noSpace: {
      type: Boolean,
      default: null,
    },
  },
})
