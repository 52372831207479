





















































import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import Vue from "vue"
import { pluralize } from "@/util/pluralize"
import CloudIcon from "./common_icons/CloudIcon.vue"
import CheckIcon from "./common_icons/CheckIcon.vue"
import EngieButton from "./forms/EngieButton.vue"

const ACCEPTABLE_MIME_TYPES = ["image/png", "image/jpeg"]

export default Vue.extend({
  components: {
    CloudIcon,
    EngieButton,
    CheckIcon,
  },
  props: {
    photoUploadUrl: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragOver: false,
      uploadingFiles: false,
      numberOfFilesProcessed: 0,
      totalNumberOfFilesUploading: 0,
      totalSuccessfulUploads: 0,
      showUploadSuccessMessage: false,
      improperFileUploaded: true,
      errorOccurred: false,
      errorFileNames: [] as string[],
    }
  },
  computed: {
    percentageLoaded(): number {
      return Math.max(1, (this.numberOfFilesProcessed / this.totalNumberOfFilesUploading) * 100)
    },
    formattedErroredFileNames(): string {
      return this.errorFileNames.join(", ")
    },
    uploadSuccessMessage(): string {
      return `${this.totalSuccessfulUploads} image${pluralize(this.totalSuccessfulUploads)} uploaded successfully`
    },
  },
  watch: {
    open(currentValue, previousValue) {
      if (currentValue === true && previousValue === false) {
        this.showUploadSuccessMessage = false
      }
    },
  },
  methods: {
    async handleImagesDropped(event: DragEvent) {
      this.dragOver = false
      const dataTransferItems = event.dataTransfer?.items

      if (dataTransferItems && !this.uploadingFiles) {
        const dataTransferItemsArray = Array.from(dataTransferItems)

        const dataTransferFiles = dataTransferItemsArray
          .map(item => item.getAsFile())
          .filter(item => item !== null) as File[]

        await this.uploadFiles(dataTransferFiles)
      }
    },
    resetFileInfo() {
      this.numberOfFilesProcessed = 0
      this.totalSuccessfulUploads = 0
      this.errorFileNames = []
    },
    async uploadFiles(files: File[]) {
      this.resetFileInfo()

      this.uploadingFiles = true
      this.totalNumberOfFilesUploading = files.length

      const fileUploadRequsts = files.map(item => this.uploadFileIfProperType(item!))

      await Promise.all(fileUploadRequsts)

      setTimeout(() => this.handleUploadCompleted(), 500)
    },
    handleUploadCompleted() {
      this.showUploadSuccessMessage = false
      this.uploadingFiles = false
      this.showUploadSuccessMessage = true
    },
    async uploadFileIfProperType(file: File) {
      this.improperFileUploaded = false
      this.errorOccurred = false

      if (!ACCEPTABLE_MIME_TYPES.includes(file.type)) {
        this.$emit("improper-file-uploaded")
        this.improperFileUploaded = true

        return Promise.resolve()
      }

      return this.uploadFile(file)
    },
    getFileFormData(file: File) {
      const formData = new FormData()

      formData.append("photo", file, file?.name)

      return formData
    },
    async uploadFile(file: File) {
      try {
        await makeAuthenticatedRequest(this.photoUploadUrl, "POST", this.getFileFormData(file))

        this.$emit("file-uploaded", file)

        this.totalSuccessfulUploads += 1
      } catch (error) {
        this.errorOccurred = true
        this.errorFileNames = [...this.errorFileNames, file.name]
      }

      this.numberOfFilesProcessed += 1
    },
    handleDragEnter() {
      if (!this.uploadingFiles) {
        this.dragOver = true
      }
    },
    handleDragLeave() {
      this.dragOver = false
    },
    handleUploadPhotosClicked() {
      const fileUploadRef = this.$refs.fileUpload as HTMLElement

      fileUploadRef.click()
    },
    async handlePhotosUploaded(event: InputEvent) {
      const fileInputElement = event.target as HTMLInputElement
      const fileInputList = fileInputElement?.files

      if (fileInputList) {
        await this.uploadFiles(Array.from(fileInputList))
      }
    },
  },
})
