






















import Vue from "vue"
import PageTitle from "./PageTitle.vue"

export default Vue.extend({
  components: {
    PageTitle,
  },
  props: {
    title: {
      type: String,
      default: "My Page",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
