






























import Vue from "vue"
import EngieButton from "./forms/EngieButton.vue"
import EngieDialogButton from "./EngieDialogButton.vue"

export default Vue.extend({
  components: {
    EngieButton,
    EngieDialogButton,
  },
  props: {
    buttonLabel: {
      type: String,
      default: "Open modal",
    },
    dialogTitle: {
      type: String,
      default: "Title",
    },
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    confirming: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: null,
    },
    disableConfirmation: {
      type: Boolean,
      default: false,
    },
    alignActionButtonsRight: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    handleDialogClosed() {
      this.$emit("dialog-closed")
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
      this.$emit("confirmed")
    },
    handleConfirmClicked() {
      this.$emit("confirmed")
    },
  },
})
