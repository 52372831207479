<template>
  <div>
    <v-menu class="d-flex" offset-y>
      <template #activator="{ on, attrs }">
        <v-btn v-if="!select" class="dropdown elevation-0" color="white" outlined depressed v-bind="attrs" v-on="on"
          >{{ defaultOption.label }}<v-icon style="font-size: 24px">mdi-chevron-down</v-icon></v-btn
        >
        <v-btn v-else class="dropdown elevation-0" color="white" outlined depressed v-bind="attrs" v-on="on"
          >{{ select.label }}<v-icon style="font-size: 24px">mdi-chevron-down</v-icon></v-btn
        >
      </template>
      <v-list class="elevation-0" outlined>
        <v-list-item
          v-for="(option, index) in options"
          id="items"
          :key="index"
          mandatory
          :class="{ last: index === options.length - 1, first: option === 0, active: select === option }"
          @click="
            {
              handleClick(option)
            }
          "
          >{{ option.label }}
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    defaultOption: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    select: "",
  }),
  methods: {
    handleClick(option) {
      this.select = option
      this.$emit("submit", option)
    },
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  border: 1px solid var(--lightest-grey) !important;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  letter-spacing: 0.02px;
  font-family: "CamptonMedium";
  height: 5.3rem !important;
  width: 27.6rem;
  padding: 0 1.5rem !important;
  margin: 0 1.4rem;
  display: flex;
  justify-content: space-between;
  text-transform: none;
  color: var(--navy);
}

.theme--light.v-sheet--outlined {
  border: 1px solid var(--lightest-grey);
  border-radius: 1rem;
}

#items {
  color: var(--navy) !important;
  border: none;
  padding-left: 1.5rem;
  font-size: 1.4rem;
  font-family: "CamptonMedium";
  transition: 0.25s;
  &:hover {
    background-color: var(--navy);
    color: white !important;
  }
  &.active {
    background-color: var(--navy);
    color: white !important;
    &:hover {
      background-color: var(--orange);
    }
  }
}

.first {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.last {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.v-menu__content {
  margin-top: 0.5rem;
  padding: 0 !important;
  box-shadow: none;
  &::v-deep {
    .v-list {
      padding: 0 !important;
    }
  }
}

.v-menu__content,
.menuable__content__active {
  overflow-x: visible !important;
  overflow-y: visible !important;
  border-radius: 1rem !important;
  box-shadow: none !important;
}
</style>
