


































import Vue from "vue"
import EngieButton from "./forms/EngieButton.vue"
import EngieTextButton from "./forms/EngieTextButton.vue"
import EngieDialog from "./EngieDialog.vue"

export default Vue.extend({
  components: {
    EngieButton,
    EngieDialog,
    EngieTextButton,
  },
  props: {
    buttonLabel: {
      type: String,
      default: "Open modal",
    },
    dialogTitle: {
      type: String,
      default: "Title",
    },
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableCloseButton: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
    narrow: {
      type: Boolean,
      default: null,
    },
    narrower: {
      type: Boolean,
      default: null,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    text: {
      type: Boolean,
      default: null,
    },
    alignActionButtonsRight: {
      type: Boolean,
      default: null,
    },
    outlinedActionsSection: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("dialog-closed")
    },
    handleDialogOpened() {
      this.$emit("dialog-opened")
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
  },
})
