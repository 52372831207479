































import Vue from "vue"
import { required as requiredRule } from "vuelidate/lib/validators"

const INPUT_PADDING_PX = 24
const PHONE_MASK = "(###) ###-####"

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "Label",
    },
    placeholder: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: null,
    },
    /**
     * When set to true, the form label is initially placed inside the input, with a transform
     * that moves it when the user types.
     */
    animatedLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    phone: {
      type: Boolean,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredRule,
    }
  },
  computed: {
    mask() {
      return this.phone === null ? null : PHONE_MASK
    },
    inputClass() {
      if (this.animatedLabel && this.inlineLabel) {
        // eslint-disable-next-line no-console
        console.error("An Engie Text Input may not have both the animated-label and inline-label props set to true")
      }

      if (this.animatedLabel) {
        return "animated-label"
      }

      if (this.inlineLabel) {
        return "inline-label"
      }

      return ""
    },
  },
  mounted() {
    if (this.animatedLabel) {
      this.positionLabelAtCenter()
    }
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    handleBlur(value: Event) {
      this.$emit("blur", value)
    },
    handleEnterPressed() {
      this.$emit("enter-pressed")
    },
    positionLabelAtCenter() {
      Vue.nextTick(() => {
        if (this.$refs.textField) {
          const textField = this.$refs.textField as Vue

          const inputWidthPx = textField.$el.clientWidth - INPUT_PADDING_PX
          const labelEl = textField.$el.querySelector("label")

          if (labelEl) {
            const inputLabelWidthPx = labelEl.clientWidth
            labelEl.style.transform = `translateX(${inputWidthPx / 2 - inputLabelWidthPx / 2}px)`
          }
        }
      })
    },
  },
})
