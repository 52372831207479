







import { CompanyWithMetadataRecord } from "@/types/CompanyWithMetadataRecord"
import Vue from "vue"
import PageSubheader from "../PageSubheader.vue"
import InfoContainer from "./InfoContainer.vue"

export default Vue.extend({
  components: {
    InfoContainer,
    PageSubheader,
  },
  props: {
    headAdminName: {
      type: String,
      default: "",
    },
    headAdminEmail: {
      type: String,
      default: "",
    },
    headAdminPhoneNumber: {
      type: String,
      default: "",
    },
  },
  computed: {
    infoPanels() {
      return [
        {
          label: "Name",
          content: this.headAdminName,
          propertyNameToUpdate: "headAdminName",
        },
        {
          label: "Email",
          content: this.headAdminEmail,
          propertyNameToUpdate: "headAdminEmail",
          editable: false,
        },
        {
          label: "Phone Number",
          content: this.headAdminPhoneNumber,
          propertyNameToUpdate: "headAdminPhoneNumber",
          phone: true,
        },
      ]
    },
  },
  methods: {
    handleSave(update: Partial<CompanyWithMetadataRecord>) {
      this.$emit("saved", update)
    },
  },
})
