<template>
  <div>
    <ProjectDashboardContent>
      <template #title>
        <div>
          <router-link class="header-link" to="" @click.native="handleProjectRouteClick">All Departments</router-link> >
          {{ selectedDepartment.name }}
        </div>
      </template>
      <template #content>
        <div v-if="categories.length === 0" class="project-department-loading-spinner-container">
          <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
        </div>
        <div v-else class="d-flex flex-wrap" style="width: 100%">
          <div v-for="category in categories" :key="category.id" class="card-container">
            <ProjectDashCard>
              <template #img-content>
                <router-link to="" @click.native="handleCardClick(category)">
                  <v-img
                    v-if="category.latestPhotoThumbnailUrl"
                    style="border-radius: 15px"
                    height="100%"
                    :src="category.latestPhotoThumbnailUrl"
                  ></v-img>
                  <v-img
                    v-else
                    style="border-radius: 15px; background-color: var(--lightest-grey)"
                    height="100%"
                  ></v-img>
                </router-link>
              </template>
              <template #text-content>
                <v-card-title class="title-content">
                  <v-icon v-if="category.colorHexValue" :style="{ color: category.colorHexValue }" class="map-marker"
                    >mdi-map-marker</v-icon
                  >
                  <v-icon v-else style="color: var(--grey)" class="map-marker">mdi-map-marker-outline</v-icon>
                  {{ category.name }}
                </v-card-title>
              </template>
            </ProjectDashCard>
          </div>
        </div>
      </template>
    </ProjectDashboardContent>
  </div>
</template>

<script>
import Vue from "vue"
import ProjectDashboardContent from "@/components/ProjectDashboard/ProjectDashboardContent.vue"
import ProjectDashCard from "@/components/ProjectDashboard/ProjectDashCard.vue"
import { getAllDepartmentCategoriesGallery } from "@/services/projectService"

export default Vue.extend({
  components: {
    ProjectDashCard,
    ProjectDashboardContent,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    photos: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String,
      default: "",
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedDepartment: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
    }
  },
  computed: {
    departmentId() {
      return this.$route.params.departmentId
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    handleCardClick(category) {
      if (category.name === "No category") {
        this.$emit("set-department-no-category", this.selectedDepartment.id)
        this.$router.push({
          name: "ShareProjectPhotoDetailMap",
          params: { companyId: this.companyId, projectId: this.project.id, departmentId: this.selectedDepartment.id },
        })
      } else {
        this.$emit("set-category", category)
        this.$router.push({
          name: "ShareProjectPhotoDetailMap",
          params: {
            companyId: this.companyId,
            projectId: this.project.id,
            departmentId: this.selectedDepartment.id,
            categoryId: category.id,
          },
        })
      }
    },

    async fetchCompanyDepartmentCategories(companyId, projectId, departmentId) {
      const response = await getAllDepartmentCategoriesGallery(companyId, projectId, departmentId)
      return response
    },

    initialize() {
      this.loading = true
      if (this.categories.length === 0) {
        this.$emit("department-selected", this.$route.params.departmentId)
      }
      this.loading = false
    },

    handleProjectRouteClick() {
      this.$router.push({
        name: "ShareProjectDepartmentPhotos",
        params: { companyId: this.companyId, projectId: this.project.id },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.card-container {
  margin: 0 12px 24px 12px;
  width: 36.7rem;
  height: 25.7rem;
}
.edit-btn {
  z-index: 999;
}

.header-link {
  text-decoration: none;
  color: var(--black);
  transition: 0.25s;
  &:hover {
    color: var(--primary);
  }
}

.card {
  height: 24.4rem;
  width: 36.7rem;
  border-radius: 1.5rem;
}

.text-box {
  background-color: transparent;
  height: 5.1rem;
  padding: 0 1.6rem;
  position: absolute;
  bottom: -1px;
  display: flex;
  color: var(--black) !important;
  background-color: white;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
}

.v-card__title {
  font-family: "CamptonMedium";
  font-size: 1.5rem;
  line-height: 1.4rem;
  font-weight: 400;
  z-index: 3;
  padding: 0;
}

.project-department-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.dialog-title {
  font-weight: 800;
}

.dialog-content-department {
  padding: 1.5rem;
}

.subheader {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--black);
  margin-bottom: 1rem;
  font-weight: 400;
}

.submit-btn {
  text-align: center;
}

.edit-btn {
  z-index: 2;
  background-color: white !important;
  padding: 2rem 1rem !important;
  border-radius: 100%;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
}

.map-marker {
  margin-right: 0.5rem;
}

::v-deep {
  .engie-text-input:not(.animated-label),
  .v-text-field {
    padding-top: 0px !important;
    .v-input__slot {
      border: 2px solid var(--grey);
    }
  }
}
</style>
