<template>
  <div class="top-nav d-flex justify-space-between align-center">
    <div class="d-flex justify-center">
      <div v-if="canChangeTeam && !isShared" class="d-flex justify-center nav-container">
        <v-list-item
          v-for="(item, idx) in items"
          :key="item.name"
          router
          :to="item.route"
          :class="{ first: idx === 0 }"
          :ripple="false"
          class="list"
          aria-current="page"
          style="
            list:hover {
              color: white !important;
            }
          "
          >{{ item.name }}</v-list-item
        >
      </div>
      <div v-else-if="!canChangeTeam && !isShared" class="d-flex justify-center nav-container">
        <v-list-item
          v-for="(item, idx) in itemsWithoutTeam"
          :key="item.name"
          router
          :to="item.route"
          :class="{ first: idx === 0 }"
          :ripple="false"
          class="list"
          aria-current="page"
          style="
            list:hover {
              color: white !important;
            }
          "
          >{{ item.name }}</v-list-item
        >
      </div>
      <div v-else class="d-flex justify-center nav-container">
        <v-list-item
          v-for="(sharedItem, idx) in sharedItems"
          :key="sharedItem.name"
          router
          :to="sharedItem.route"
          :class="{ first: idx === 0 }"
          :ripple="false"
          class="list"
          aria-current="page"
          style="
            list:hover {
              color: white !important;
            }
          "
          >{{ sharedItem.name }}</v-list-item
        >
      </div>
    </div>
    <div v-if="canChangeTeam || isExternalUser" class="right-nav-container">
      <v-menu style="width: 100%; margin-left: auto; z-index: 10010" class="menu-container" left offset-y>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" text class="extra-menu" v-on="on"><span class="extra-menu-text">...</span></v-btn>
        </template>
        <v-list class="menu-list">
          <v-list-item
            v-if="!isExternalUser && !isShared"
            class="d-flex justify-start r-list"
            :to="`/projects/${companyId}/edit/${projectId}`"
            >Edit Project</v-list-item
          >
          <v-list-item class="d-flex justify-start r-list" @click="handleShareClick">Share</v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      default: "",
    },
    companyId: {
      type: String,
      default: "",
    },
    canChangeTeam: {
      type: Boolean,
      default: true,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        { name: "Photos", route: `/projects/${this.$route.params.projectId}/photos` },
        { name: "Details", route: `/projects/${this.$route.params.projectId}/details` },
        { name: "Team", route: `/projects/${this.$route.params.projectId}/team` },
      ],
      sharedItems: [
        {
          name: "Photos",
          route: `/share/companies/${this.$route.params.companyId}/projects/${this.$route.params.projectId}/`,
        },
        {
          name: "Details",
          route: `/share/companies/${this.$route.params.companyId}/projects/${this.$route.params.projectId}/details`,
        },
      ],
      itemsWithoutTeam: [
        {
          name: "Photos",
          route: `/share/${this.$route.params.companyId}/projects/${this.$route.params.projectId}/photos`,
        },
        {
          name: "Details",
          route: `/share/${this.$route.params.companyId}/projects/${this.$route.params.projectId}/details`,
        },
      ],
    }
  },
  methods: {
    handleShareClick() {
      this.$emit("share")
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: inherit !important;
  &:hover {
    color: white !important;
  }
}

.top-nav {
  padding: 0 3rem;
  height: 6rem;
  box-shadow: 0px 3px 6px #00000029;
  clip-path: inset(0px 0px -1rem -0px);
  &::v-deep {
    color: var(--navy) !important;
  }
}

.nav-container {
  height: 100%;
  width: 98%;
  margin: 1.3rem 0px 1.3rem 0px;
  padding: 0.5rem 0px 0.5rem 10px;
  background-color: var(--lightest-grey);
  border-radius: 15px;
}

.first {
  margin-left: 0 !important;
}

.list {
  border: none;
  padding: 0.8rem 2.4rem;
  letter-spacing: 0.02px;
  margin: 0 1rem;
  border-radius: 1.3rem;
  min-height: 0px;
  font-size: 1.4rem;
  letter-spacing: 0.02px !important;
  line-height: 1.4rem;
  background-color: transparent;
  transition: 0.25s;
  &:before,
  &:after {
    content: none;
  }
  &.v-list-item--active {
    background-color: var(--navy);
    color: white;
  }
}

.list:hover {
  background-color: var(--orange);
  color: white !important;
  border: none !important;
}

.right-nav-container {
  height: 100%;
  padding: 0;
}

.extra-menu {
  height: 100% !important;
  color: var(--navy);
  font-size: 9rem;
  letter-spacing: -3px;

  .extra-menu-text {
    position: relative;
    bottom: 1rem;
  }

  &::v-deep {
    .v-btn__content {
      height: 100%;
      padding: 0 1rem 2.7rem 0;
    }
  }
}

.v-menu__content,
.menuable__content__active {
  contain: none !important;
  box-shadow: none !important;
}

.menu-list {
  padding: 0;
  color: var(--navy) !important;
  box-shadow: none !important;
  margin-right: 1rem;
  border: 2px solid var(--light-grey);
  &::v-deep {
    box-shadow: none !important;
    .v-menu__content {
      box-shadow: none;
    }
  }
}

.r-list {
  padding: 1rem 2rem;
  min-height: 0px;
  transition: 0.25s;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 1.4rem;
}

.r-list:first-child {
  padding-top: 2rem;
}
.r-list:last-child {
  padding-bottom: 2rem;
}

.r-list:hover {
  background-color: var(--navy);
  color: white !important;
}

.active {
  background-color: var(--orange);
  color: white !important;
}

.active:hover {
  background-color: var(--navy);
}

.v-list-item--active::before {
  display: none;
}
</style>
