


















































































import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"
import { checkRequired } from "@/util/validators/checkRequired"
import { checkEmail } from "@/util/validators/checkEmail"
import { phoneNumberValidation } from "@/util/validators/phoneNumberValidation"
import { checkPhoneNumber } from "@/util/validators/checkPhoneNumber"
import Vue, { VueConstructor } from "vue"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getUserUrl } from "@/util/urls"
import EngieDialog from "../EngieDialog.vue"
import EngieTextInput from "../forms/EngieTextInput.vue"
import EngieUserEmailInput from "../forms/EngieUserEmailInput.vue"
import EngieButton from "../forms/EngieButton.vue"
import EngieErrorSnackbar from "../EngieErrorSnackbar.vue"
import EngieSuccessSnackbar from "../EngieSuccessSnackbar.vue"
import EngieSelect from "../forms/EngieSelect.vue"
import { UserRecord } from "../../types/UserRecord"
import { CompanyRole } from "../../types/CompanyRole"
import EditDialogAction from "../EditDialogAction.vue"
import { companyRoleList } from "../../constants/companyRoleList"

export default (Vue as VueConstructor<Vue & typeof validationMixin>).extend({
  components: {
    EngieDialog,
    EditDialogAction,
    EngieTextInput,
    EngieUserEmailInput,
    EngieButton,
    EngieErrorSnackbar,
    EngieSelect,
    EngieSuccessSnackbar,
  },
  mixins: [validationMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      originalName: "",
      name: "",
      email: "",
      originalEmail: "",
      jobTitle: "",
      companyRole: "",
      originalCompanyRole: "",
      phoneNumber: "",
      usableEmailEntered: true,
      showErrorSnackbar: false,
      error: "",
      saving: false,
      deletingUser: false,
      confirmDeleteUserDialogOpen: false,
      showDeletionErrorSnackbar: false,
      showDeletionSuccessSnackbar: false,
      roleOptions: companyRoleList,
    }
  },
  computed: {
    nameErrors(): string[] {
      return checkRequired(this.$v.name)
    },
    emailErrors(): string[] {
      return checkEmail(this.$v.email)
    },
    phoneNumberErrors(): string[] {
      return checkPhoneNumber(this.$v.phoneNumber)
    },
    roleSelectDisabled(): boolean {
      return this.saving || this.originalCompanyRole === CompanyRole.HEAD_ADMIN
    },
    roleSelectTitle(): string | null {
      return this.originalCompanyRole === CompanyRole.HEAD_ADMIN
        ? "Head Admin users may not have their role changed."
        : null
    },
    shouldShowDeleteUserAction(): boolean {
      return this.user && this.user.companyRole !== CompanyRole.HEAD_ADMIN
    },
  },
  watch: {
    open(open: boolean) {
      if (open && this.user) {
        this.originalName = this.user.name || ""
        this.name = this.user.name
        this.email = this.user.email
        this.originalEmail = this.user.email
        this.jobTitle = this.user.jobTitle
        this.phoneNumber = this.user.phoneNumber
        this.companyRole = this.user.companyRole
        this.originalCompanyRole = this.user.companyRole
      }
    },
  },
  methods: {
    handleDialogClosed() {
      this.$emit("dialog-closed")
      this.reset()
    },
    reset() {
      this.name = ""
      this.email = ""
      this.jobTitle = ""
      this.phoneNumber = ""
      this.companyRole = ""

      this.$v.$reset()
    },
    handleUnusedEmailEntered() {
      this.usableEmailEntered = true
    },
    handleExistingEmailEntered() {
      this.usableEmailEntered = false
    },
    handleSaveClicked() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.updateUser()
      }
    },
    getUserUpdateRequestBody() {
      const updateBody: Partial<UserRecord> = {
        name: this.name,
        phoneNumber: this.phoneNumber,
        jobTitle: this.jobTitle,
      }

      if (this.email !== this.originalEmail) {
        updateBody.email = this.email
      }

      if (this.companyRole !== this.originalCompanyRole) {
        updateBody.companyRole = this.companyRole
      }

      return updateBody
    },
    async updateUser() {
      this.saving = true

      const updateBody = this.getUserUpdateRequestBody()

      try {
        const response = await makeAuthenticatedRequest(getUserUrl(this.user.id), "PUT", updateBody)
        this.$emit("user-saved", response)
      } catch (error) {
        this.showErrorSnackbar = true
      }

      this.saving = false
    },
    handleErrorSnackbarClosed() {
      this.showErrorSnackbar = false
    },
    handleConfirmDeleteUserDialogOpened() {
      this.confirmDeleteUserDialogOpen = true
    },
    handleConfirmDeleteUserDialogClosed() {
      this.confirmDeleteUserDialogOpen = false
    },
    async handleUserDeleted() {
      this.deletingUser = true

      try {
        await makeAuthenticatedRequest(getUserUrl(this.user.id), "DELETE")
        this.$emit("user-deleted", this.user.id)
        this.showDeletionSuccessSnackbar = true
        this.confirmDeleteUserDialogOpen = false
      } catch (error) {
        this.showDeletionErrorSnackbar = true
      }

      this.deletingUser = false
    },
    handleDeletionErrorSnackbarClosed() {
      this.showDeletionErrorSnackbar = false
    },
    handleDeletionSuccessSnackbarClosed() {
      this.showDeletionSuccessSnackbar = false
    },
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
      notTaken() {
        return this.usableEmailEntered
      },
    },
    phoneNumber: phoneNumberValidation,
  },
})
