<template>
  <div>
    <div v-if="loading" class="project-department-loading-spinner-container">
      <v-btn class="dropdown" dark :color="'var(--grey)'" depressed
        ><v-progress-circular
          indeterminate
          :size="20"
          :color="'white'"
          style="margin-right: 0.5rem; margin-bottom: 0.25rem"
        ></v-progress-circular
      ></v-btn>
    </div>
    <div v-else class="d-flex">
      <v-menu
        class="d-flex"
        offset-y
        :close-on-content-click="false"
        :close-on-click="true"
        :value="menuOpen"
        attach=".project-department-loading-spinner-container"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="dropdown"
            :style="`color: ${colorOfButtonText(submittedSelectedCategories[0].colorHexValue)}`"
            :color="
              submittedSelectedCategories.length !== categories.length
                ? submittedSelectedCategories[0].colorHexValue
                : 'var(--grey)'
            "
            depressed
            v-bind="attrs"
            v-on="on"
            >{{ submittedSelectedCategories.length !== categories.length ? names.join(", ") : "All Categories" }}
            <v-icon style="font-size: 24px">mdi-chevron-down</v-icon></v-btn
          >
        </template>
        <v-list class="menu-list" outlined>
          <v-checkbox
            v-for="category in categories"
            :key="category.id"
            :label="category.name"
            on-icon="mdi-check-circle"
            off-icon="mdi-checkbox-blank-circle-outline"
            :input-value="isCategoryChecked(category.id)"
            @change="handleCategoryChecked($event, category)"
          >
            <template #label>
              <div class="label">
                <v-icon v-if="category.colorHexValue" :style="{ color: category.colorHexValue }" class="map-marker"
                  >mdi-map-marker</v-icon
                >
                <v-icon v-else style="color: var(--grey)" class="map-marker">mdi-map-marker-outline</v-icon>
                {{ category.name }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="allSelected"
            label="All Categories"
            on-icon="mdi-check-circle"
            off-icon="mdi-checkbox-blank-circle-outline"
            @click="handleAllCategoriesSelected"
          >
            <template #label>
              <div style="padding: 4rem">All Categories</div>
            </template>
          </v-checkbox>
          <div class="submit d-flex justify-end align-center">
            <v-btn class="submit-btn" depressed :disabled="submitDisabled" @click="handleSubmit">Apply</v-btn>
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    searchLoading: {
      type: Boolean,
      default: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedCategory: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menuOpen: false,
      allSelected: false,
      currentSelectedCategories: [],
      names: [],
      loading: true,
      submittedSelectedCategories: [],
      disableSubmitBtn: false,
    }
  },
  computed: {
    allCategoriesSelected() {
      return this.currentSelectedCategories.length === this.categories.length
    },
    submitDisabled() {
      return this.currentSelectedCategories.length === 0
    },
    doneLoadingCheck() {
      if (this.selectedCategory !== null) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.submittedSelectedCategories = [this.selectedCategory]
      this.currentSelectedCategories = [this.selectedCategory]
      this.names = [this.selectedCategory.name]
      this.loading = false
    },

    handleCategoryChecked(checked, item) {
      if (checked) {
        this.currentSelectedCategories = [...this.currentSelectedCategories, item]
        if (this.allCategoriesSelected) {
          this.allSelected = true
        }
      } else {
        this.currentSelectedCategories = this.currentSelectedCategories.filter(
          selectedCategory => selectedCategory.id !== item.id
        )
        if (!this.allCategoriesSelected) {
          this.allSelected = false
        }
      }
    },

    isCategoryChecked(value) {
      return this.currentSelectedCategories.some(selectedCategory => selectedCategory.id === value)
    },

    handleAllCategoriesSelected() {
      this.$nextTick(() => {
        if (this.allCategoriesSelected) {
          this.currentSelectedCategories = [this.selectedCategory]
        } else {
          this.allSelected = true
          this.currentSelectedCategories = this.categories
        }
      })
    },

    createArrayOfSelectedNames(array) {
      const namesArray = array.map(category => category.name)
      return namesArray
    },

    handleSubmit() {
      this.submittedSelectedCategories = this.currentSelectedCategories
      this.names = this.createArrayOfSelectedNames(this.submittedSelectedCategories)
      this.$emit("submit", this.submittedSelectedCategories)
      this.menuOpen = false
    },

    hexToRgb(hex) {
      const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
      if (normal) return normal.slice(1).map(e => parseInt(e, 16))
      return null
    },

    colorOfButtonText(hex) {
      const rbg = this.hexToRgb(hex)
      const totalValue = rbg[0] * 0.299 + rbg[1] * 0.587 + rbg[2] * 0.114
      if (totalValue > 150) {
        return "var(--navy)"
      }
      return "#ffffff"
    },
  },
})
</script>

<style lang="scss" scoped>
.v-input .chips {
  font-size: 1.4rem;
  font-weight: 600;
}

.submit-btn {
  border-radius: 13px;
  text-transform: none;
  height: 2.6rem !important;
  width: 7.2rem;
  text-transform: none;
  font-family: "CamptonMedium";
  font-size: 1.2rem;
  letter-spacing: 0.28px;
  color: var(--navy);
}

.submit {
  background-color: var(--navy);
  margin-top: -1.5rem;
  height: 4.6rem;
  padding-right: 1rem;
}

.all-categories {
  padding-left: 4.5rem !important;
}

.map-marker {
  margin-right: 1rem;
}

.dropdown {
  border-radius: 1.5rem;
  font-size: 1.4rem;
  letter-spacing: 0.32px;
  font-weight: 600;
  height: 3.2rem !important;
  padding: 0 0.5rem 0 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  &::v-deep {
    span {
      display: block;
      padding-top: 2px;
    }
    i {
      padding-bottom: 2px;
    }
  }
}

.menu-list {
  display: flex;
  flex-direction: column;
  width: 27.6rem;
  font-size: 1.4rem;
  background-color: var(--lightest-grey);
  padding: 0 !important;
  border: 1px solid var(--lightest-grey);
  &::v-deep {
    .v-input,
    .v-input--selection-controls {
      margin: 0;
      padding: 0;
      justify-content: center;
      align-items: center;
    }
    .v-input__slot {
      margin-bottom: 0;
      align-content: flex-end;
      background-color: white;
      border-bottom: 1px solid var(--grey);
      padding: 0.8rem;
      height: 4.6rem;
      flex-direction: row-reverse;
    }
    .v-input--selection-controls__input {
      .theme--light,
      .v-icon {
        color: var(--orange);
      }
    }
  }
}

.v-menu__content,
.menuable__content__active {
  // overflow-x: visible !important;
  // overflow-y: visible !important;
  padding-top: 1rem !important;
  // contain: none !important;
  // border-radius: 1.5rem !important;
  box-shadow: none !important;
}
</style>
