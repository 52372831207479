<template>
  <Page title="Projects">
    <template v-if="isUserAdmin" #toolbar>
      <EngieButton @click="handleAddProjectRouteClick">Add Project</EngieButton>
    </template>
    <div>
      <div v-if="sortLoading" style="height: 9.3rem; margin-left: 1.4rem"></div>
      <ProjectSortBar
        v-else
        :is-external-user="isExternalUser"
        :user-projects-count="userProjectsCount"
        :total-count="totalProjectsCount"
        @project-switch-clicked="handleSwitchClick"
        @project-status-clicked="handleStatusClick"
        @project-sort-clicked="handleSortClick"
      />
      <EngieSearchInput label="by Project Name or Number" class="search" @input="debouncedHandler" />
    </div>
    <div v-if="loading" class="projects-loading-spinner-container">
      <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
    </div>
    <div v-else-if="!loading && currentProjects.length === 0" class="d-flex justify-center">
      <h2 class="pa-12">No projects found</h2>
    </div>
    <div v-else id="project-gallery" class="project-gallery-content" style="margin-top: 4.2rem">
      <div class="d-flex flex-wrap gallery-container">
        <ProjectCard
          v-for="(project, index) in currentProjects"
          :key="project.id"
          :project="project"
          :class="index === currentProjects.length - 1 ? 'last-project' : ''"
        >
          <template #action-btn>
            <EngieButton @click="handleClickRoute(project.id)">View Project</EngieButton>
          </template>
        </ProjectCard>
      </div>
      <div v-if="loadingMoreProjects" class="d-flex justify-center">
        <v-progress-circular indeterminate color="primary" :size="45"></v-progress-circular>
      </div>
    </div>
  </Page>
</template>

<script>
import { debounce } from "vue-debounce"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import EngieButton from "@/components/forms/EngieButton.vue"
import EngieSearchInput from "@/components/forms/EngieSearchInput.vue"
import ProjectCard from "@/components/Projects/ProjectCard.vue"
import ProjectSortBar from "@/components/Projects/ProjectSortBar.vue"
import { getCurrentAuthUserRecord, hasAdminRole } from "@/services/authService"
import { getAllCompanyProjects } from "@/services/projectService"
import Vue from "vue"
import Page from "../components/Page.vue"

export default Vue.extend({
  components: {
    Page,
    EngieButton,
    ProjectSortBar,
    EngieSearchInput,
    ProjectCard,
  },
  beforeRouteLeave(to, from, next) {
    this.cleanupScrollObserver()
    next()
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
    userHomeCompanyId: {
      type: String,
      default: null,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userProjectsCount: 0,
    totalProjectsCount: 0,
    statusSelect: { label: "Active", fieldValue: "active" },
    sortSelect: { label: "Newest to Oldest", fieldValue: "createdDateTime", directionValue: "desc" },
    projectSwitch: "user",
    currentProjects: [],
    projects: [],
    hasNextPage: null,
    pageNumber: 0,
    pageSize: "8",
    searchInput: "",
    isUserAdmin: false,
    loading: true,
    sortLoading: false,
    observer: null,
    projectGallery: null,
    loadingMoreProjects: false,
  }),
  watch: {
    companyId(currentValue, previousValue) {
      if (currentValue === previousValue) {
        this.loading = true
        this.initialize()
        return true
      }
      this.sortLoading = true
      this.loading = true
      this.initialize()
      return false
    },
    loading(currentValue) {
      if (currentValue === false) {
        setTimeout(() => this.initObserver(), 0)
      }
    },
  },
  created() {
    this.debouncedHandler = debounce(event => {
      this.loading = true
      this.searchProjects(event)
    }, DEBOUNCE_INTERVAL)
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async fetchAllCompanyProjects(
      companyId,
      userId,
      sortByStatus,
      sortByField,
      sortByDirection,
      search,
      pageSize,
      pageNumber
    ) {
      const response = await getAllCompanyProjects(
        companyId,
        userId,
        sortByStatus,
        sortByField,
        sortByDirection,
        search,
        pageSize,
        pageNumber
      )

      return response
    },

    async fetchCurrentUser() {
      const user = await getCurrentAuthUserRecord()
      return user
    },

    async checkUserRole() {
      if (this.isExternalUser) {
        return false
      }
      const isUserAdmin = await hasAdminRole()
      return isUserAdmin
    },

    async initialize() {
      this.isUserAdmin = await this.checkUserRole()
      await this.setProjects()
      if (this.sortLoading) {
        this.sortLoading = false
      }
    },

    async setProjects() {
      this.pageNumber = 1
      const projectsRequest = await this.handleProjectSwitch(this.pageSize, this.pageNumber)
      this.projects = projectsRequest.items
      this.userProjectsCount = projectsRequest.totalInUsersProjects
      this.totalProjectsCount = projectsRequest.totalInAllCompanyProjects
      if (projectsRequest.hasNextPage) {
        this.hasNextPage = true
        this.currentProjects = this.projects
        this.loading = false
      } else {
        this.hasNextPage = false
        this.currentProjects = this.projects
        this.loading = false
      }
    },

    async handleProjectSwitch(pageSize, pageNumber) {
      if (this.projectSwitch === "user" || this.isExternalUser) {
        const user = await this.fetchCurrentUser()
        const response = await this.fetchAllCompanyProjects(
          this.companyId,
          user.id,
          this.statusSelect.fieldValue,
          this.sortSelect.fieldValue,
          this.sortSelect.directionValue,
          this.searchInput,
          pageSize,
          pageNumber
        )
        return response
      }
      const response = await this.fetchAllCompanyProjects(
        this.companyId,
        "",
        this.statusSelect.fieldValue,
        this.sortSelect.fieldValue,
        this.sortSelect.directionValue,
        this.searchInput,
        pageSize,
        pageNumber
      )
      return response
    },

    cleanupScrollObserver() {
      if (this.projectGallery !== null) {
        this.observer.unobserve(this.projectGallery)
      }
    },

    async addMoreProjects() {
      this.loadingMoreProjects = true
      this.cleanupScrollObserver()
      this.pageNumber += 1

      const addedProjectsRequest = await this.handleProjectSwitch("8", this.pageNumber)

      this.loadingMoreProjects = false

      if (addedProjectsRequest.hasNextPage === false) {
        this.hasNextPage = false
        const newUpdatedProjects = addedProjectsRequest.items
        return [...this.currentProjects, ...newUpdatedProjects]
      }

      const newUpdatedProjects = addedProjectsRequest.items

      this.initObserver()
      return [...this.currentProjects, ...newUpdatedProjects]
    },

    initObserver() {
      this.projectGallery = document.querySelector(".last-project")
      const projectContent = document.querySelector(".project-content")

      if (this.projectGallery !== null && projectContent !== null) {
        const options = {
          root: projectContent,
          threshold: [...Array(10)].map((el, index) => 0.1 * index),
        }

        const handleIntersectionEvent = async entry => {
          if (entry[0].intersectionRatio > 0.9) {
            const projects = await this.addMoreProjects()
            this.currentProjects = projects
          }
        }

        this.observer = new IntersectionObserver(handleIntersectionEvent, options)
        this.observer.observe(this.projectGallery)
      }
    },

    async searchProjects(value) {
      this.searchInput = value
      await this.setProjects()
    },

    handleAddProjectRouteClick() {
      this.$router.push(`/projects/${this.companyId}/add-project`)
    },

    async handleStatusClick(value) {
      this.loading = true
      this.statusSelect = value
      await this.setProjects()
    },

    async handleSortClick(value) {
      this.loading = true
      this.sortSelect = value
      await this.setProjects()
    },

    async handleSwitchClick(value) {
      this.loading = true
      this.projectSwitch = value
      await this.setProjects()
    },

    handleClickRoute(value) {
      this.$router.push({
        path: `/projects/${value}/photos`,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.engie-button::v-deep .v-btn {
  padding: 0 2rem !important;
}

.engie-button::v-deep {
  span {
    display: block;
    padding-top: 1px;
  }
}

.search::v-deep {
  height: 4rem;
  width: 99%;
  max-width: 116rem;
  label {
    padding-top: 1px;
    font-size: 1.2rem;
  }
  .engie-search-input,
  .v-text-field {
    border-color: var(--light-grey) !important;
  }
}

.projects-loading-spinner-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20%;
}

.gallery-container {
  gap: 2rem;
  padding-bottom: 4rem;
}
</style>
