








import { CompanyWithMetadataRecord } from "@/types/CompanyWithMetadataRecord"
import Vue from "vue"
import PageSubheader from "../PageSubheader.vue"
import InfoContainer from "./InfoContainer.vue"

export default Vue.extend({
  components: {
    InfoContainer,
    PageSubheader,
  },
  props: {
    companyId: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
    companyAddress: {
      type: String,
      default: "",
    },
    companySize: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    infoPanels() {
      return [
        {
          label: "Company Name",
          content: this.companyName,
          propertyNameToUpdate: "name",
        },
        {
          label: "Company Address",
          content: this.companyAddress,
          propertyNameToUpdate: "address",
        },
        {
          label: "Company Size",
          content: this.companySize.toString(),
          propertyNameToUpdate: "size",
          companySize: true,
        },
      ]
    },
  },
  methods: {
    handleSave(update: Partial<CompanyWithMetadataRecord>) {
      this.$emit("saved", update)
    },
  },
})
