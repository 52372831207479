<template>
  <div id="assign-project-gallery" class="stepper-content-container">
    <ProjectCard
      v-for="(project, index) in companyProjects"
      :key="project.id"
      :class="index === companyProjects.length - 1 ? 'last-assign-project project-card' : 'project-card'"
      :project="project"
    >
      <template #action-btn>
        <EngieButton class="project-card-btn" @click="handleSelectProject(project)"> Select Project </EngieButton>
      </template>
    </ProjectCard>
  </div>
</template>

<script>
import EngieButton from "../forms/EngieButton.vue"
import ProjectCard from "../Projects/ProjectCard.vue"

export default {
  components: { EngieButton, ProjectCard },
  props: {
    companyProjects: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleSelectProject(value) {
      this.$emit("submitted", value)
    },
  },
}
</script>

<style lang="scss" scoped>
#assign-project-gallery {
  margin-top: 1rem;
  max-height: 50rem;
  overflow: auto;
}

.stepper-content-container {
  padding: 1rem;
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  width: 100%;
}

.project-card {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

::v-deep {
  .engie-button .v-btn {
    min-height: 0px !important;
    min-width: 0px !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.75px !important;
    line-height: 1.6rem !important;
    padding: 0 1rem !important;
  }

  .card-text {
    padding: 1.6rem 0 0 0 !important;
  }

  .card-title {
    font-size: 1.2rem !important;
    border-bottom: none !important;
  }
  .subtitle {
    font-size: 1rem !important;
  }

  .proj-card {
    border-radius: 15px !important;
  }

  .v-dialog,
  .v-sheet.v-card {
    border-radius: 15px !important;
  }

  .v-card__title {
    border-bottom: 0;
    margin-bottom: 0.5rem !important;
  }
}
</style>
