<template>
  <v-container
    id="project-switch-container"
    :class="!isExternalUser ? 'status-slider d-flex align-center' : 'external-status-slider d-flex align-center'"
    style="position: relative"
  >
    <v-list-item-group v-if="!isExternalUser" class="item-grp" color="primary" mandatory>
      <v-list-item
        v-for="(item, idx) in items"
        :key="idx"
        v-ripple="false"
        class="list-item"
        depressed
        @click="handleClick(item)"
      >
        <v-list-item-content>
          <h3 v-if="item.value === 'user'" class="item-text d-flex justify-center">
            {{ item.text }} ({{ userProjectsCount }})
          </h3>
          <h3 v-else class="item-text d-flex justify-center">{{ item.text }} ({{ totalCount }})</h3>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <v-list-item-group v-else class="item-grp" color="primary" mandatory>
      <v-list-item v-ripple="false" class="list-item" depressed>
        <v-list-item-content>
          <h3 class="item-text d-flex justify-center">{{ items[0].text }} ({{ userProjectsCount }})</h3>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <div :class="selected" :style="movingSelectWidth ? `width: ${movingSelectWidth}px` : ``"></div>
  </v-container>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    userProjectsCount: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isExternalUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        { text: `My Projects`, count: 0, class: "user", value: "user" },
        { text: `All Projects`, count: 0, class: "total", value: "total" },
      ],
      selected: "moving-select active",
      movingSelectWidth: null,
    }
  },
  mounted() {
    window.addEventListener("resize", this.setMovingSelectWidth)
    this.initialize()
  },
  destroyed() {
    window.removeEventListener("resize", this.setMovingSelectWidth)
  },
  methods: {
    handleClick(item) {
      this.selected = `moving-select ${item.class}`
      this.$emit("clicked", item.value)
    },
    initialize() {
      this.setMovingSelectWidth()
    },
    setMovingSelectWidth() {
      const container = document.getElementById("project-switch-container")
      if (container) {
        const { width } = container.getBoundingClientRect()
        if (!this.isExternalUser) {
          this.movingSelectWidth = width / 2
        } else {
          this.movingSelectWidth = width - width * 0.05
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.item-grp {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-list-item--link::before {
  background-color: transparent;
}

.theme--light.v-list-item:hover::before {
  opacity: 0;
}

.list-item {
  padding-top: 2px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  transition-delay: 0.05s;
}

::v-deep {
  .v-list-item--active {
    .v-list-item__content {
      h3 {
        color: white !important;
      }
    }
  }
}

.item-text {
  color: var(--orange);
  font-weight: 400;
  line-height: 2.2rem;
  letter-spacing: 0.45px;
  transition: 0.15s;
}

.moving-select {
  position: absolute;
  height: 4.1rem;
  width: 27.1rem;
  border-radius: 0.5rem;
  background-color: var(--orange);
  transition: 0.15s;
  // margin: 0 1%;
}
.user {
  transform: translateX(0%);
  // left: 0%;
}
.total {
  // left: 33.3%;
  transform: translateX(96%);
}
.archived {
  // left: 65%;
  transform: translateX(199%);
}
.status-slider {
  margin: 2rem 0;
  padding: 0.35rem;
  border-radius: 5px;
  width: 56.7rem;
  height: 5.3rem;
  border: 2px solid var(--orange);
}

.external-status-slider {
  margin: 2rem 0;
  padding: 0.35rem;
  border-radius: 5px;
  width: calc(56.7rem / 2);
  height: 5.3rem;
  border: 2px solid var(--orange);
}
</style>
