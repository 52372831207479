/**
 * This is a hacky lil function that gives us the ability to map a simple range since javascript does not yet have proper ranges.
 * Obviously this does not support true ranges, just a simple incremental list with a start and an end.
 *
 * @example
 * ```
 * const multiplyByTwo = mapRange(0, 3, (index) => index * 2)
 * console.log(multiplyByTwo)   // [0, 2, 4]
 * ```
 *
 * @param start The range will begin with this value
 * @param end The range will end at this value (it is exclusive -- it will not include this value)
 * @param mapper A function which maps the range
 * @returns
 */
type Mapper<T> = (index: number) => T

export const mapRange = <T>(start: number, end: number, mapper: Mapper<T>) =>
  new Array(end - start).fill(true).map((value, index) => mapper(index + start))

export const mapFromZero = <T>(length: number, mapper: Mapper<T>) => mapRange(0, length, mapper)
