























import Vue from "vue"

export default Vue.extend({
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "100%",
    },
  },
})
