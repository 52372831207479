



















import Vue from "vue"
import "firebase/auth"
import AccountIcon from "./AccountIcon.vue"
import { isSuperAdmin, logoutAndDeleteDeviceSession } from "../../services/authService"

export default Vue.extend({
  components: {
    AccountIcon,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchQuery: "",
      isSuperAdmin: false,
    }
  },
  computed: {
    accountButtonTo(): null | Object {
      return this.isSuperAdmin ? null : { name: "AccountSettings" }
    },
  },
  mounted() {
    this.checkIsSuperAdmin()
  },
  methods: {
    signOut() {
      this.clearWorkspacesInLocalStorage()
      logoutAndDeleteDeviceSession().then(() => {
        this.$router.push("/login")
      })
    },
    async checkIsSuperAdmin() {
      this.isSuperAdmin = await isSuperAdmin()
    },
    clearWorkspacesInLocalStorage() {
      localStorage.removeItem(`currentWorkspace[${this.userId}]`)
    },
  },
})
