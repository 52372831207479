/* eslint-disable import/no-cycle */

import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { getCurrentAuthUser } from "@/services/getCurrentAuthUser"
import { resetWorkspace } from "@/util/workspaceUtils"
import Login from "../views/Login.vue"
import PrivacyPolicy from "../views/PrivacyPolicy.vue"
import TermsAndConditions from "../views/TermsAndConditions.vue"
import SignUp from "../views/Signup.vue"
import ForgotPassword from "../views/ForgotPassword.vue"
import Dashboard from "../views/Dashboard.vue"
import ProjectsContainer from "../components/Projects/ProjectsContainer.vue"
import Projects from "../views/Projects.vue"
import AddProject from "../views/AddProject.vue"
import EditProject from "../views/EditProject.vue"
import Photos from "../views/Photos.vue"
import ProjectDashboard from "../components/ProjectDashboard/ProjectDashboard.vue"
import ProjectDepartmentPhotos from "../views/ProjectDepartmentPhotos.vue"
import ProjectDeptCategoryPhotos from "../views/ProjectDeptCategoryPhotos.vue"
import NoDeptProjectPhotoDetail from "../components/NoDepartment/NoDeptProjectPhotoDetail.vue"
import ProjectPhotoDetail from "../views/ProjectPhotoDetail.vue"
import ProjectPhotoDetailMap from "../components/ProjectPhotoDetail/ProjectPhotoDetailMap.vue"
import ProjectPhotoDetailGallery from "../components/ProjectPhotoDetail/ProjectPhotoDetailGallery.vue"
import ProjectDetails from "../views/ProjectDetails.vue"
import ProjectTeam from "../views/ProjectTeam.vue"
import AccountSettings from "../views/AccountSettings.vue"
import RoleBasedDashboard from "../components/Dashboard/RoleBasedDashboard.vue"
import Users from "../views/Users.vue"
import CompanyAccount from "../views/CompanyAccount.vue"
import ViewMap from "../views/ViewMap.vue"
import ShareRoleBasedDashboard from "../components/Share/ShareRoleBasedDashboard.vue"
import ShareProjectDashboard from "../components/Share/ShareProjectDashboard.vue"
import ShareProjectDepartmentPhotos from "../components/Share/ShareProjectDepartmentPhotos.vue"
import ShareProjectDeptCategoryPhotos from "../components/Share/ShareProjectDeptCategoryPhotos.vue"
import ShareNoDeptProjectPhotoDetail from "../components/Share/ShareNoDeptProjectPhotoDetail.vue"
import ShareProjectPhotoDetail from "../components/Share/ShareProjectPhotoDetail.vue"
import ShareProjectPhotoDetailMap from "../components/Share/ShareProjectPhotoDetailMap.vue"
import ShareProjectPhotoDetailGallery from "../components/Share/ShareProjectPhotoDetailGallery.vue"
import ShareProjectDetails from "../components/Share/ShareProjectDetails.vue"
import SharePhotoRoleDashboard from "../components/Share/SharePhotoRoleDashboard.vue"
import SharePhotos from "../components/Share/SharePhotos.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/forgot-password/:forgotPasswordId",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "TOC",
    component: TermsAndConditions,
  },
  {
    path: "/",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "RoleBasedDashboard",
        component: RoleBasedDashboard,
      },
      {
        path: "/projects",
        name: "ProjectsContainer",
        component: ProjectsContainer,
        children: [
          {
            path: "/projects",
            name: "Projects",
            component: Projects,
          },
          {
            path: "/projects/:companyId/add-project",
            name: "AddProject",
            component: AddProject,
          },
          {
            path: "/projects/:companyId/edit/:projectId",
            name: "EditProject",
            component: EditProject,
          },
          {
            path: "/projects/:projectId/",
            name: "ProjectDashboard",
            component: ProjectDashboard,
            children: [
              {
                path: "/projects/:projectId/photos",
                name: "ProjectDepartmentPhotos",
                component: ProjectDepartmentPhotos,
              },
              {
                path: "/projects/:projectId/photos/departments/no-department",
                name: "NoDeptProjectPhotoDetail",
                component: NoDeptProjectPhotoDetail,
                children: [
                  {
                    path: "/projects/:projectId/photos/departments/no-department/no-dept-map",
                    name: "NoDeptProjectPhotoDetailMap",
                    component: ProjectPhotoDetailMap,
                  },
                  {
                    path: "/projects/:projectId/photos/departments/no-department/no-dept-gallery",
                    name: "NoDeptProjectPhotoDetailGallery",
                    component: ProjectPhotoDetailGallery,
                  },
                ],
              },
              {
                path: "/projects/:projectId/photos/departments/:departmentId/",
                name: "ProjectDeptCategoryPhotos",
                component: ProjectDeptCategoryPhotos,
              },
              {
                path: "/projects/:projectId/photos/departments/:departmentId/:categoryId?",
                name: "ProjectPhotoDetail",
                component: ProjectPhotoDetail,
                children: [
                  {
                    path: "/projects/:projectId/photos/departments/:departmentId/:categoryId?/map",
                    name: "ProjectPhotoDetailMap",
                    component: ProjectPhotoDetailMap,
                  },
                  {
                    path: "/projects/:projectId/photos/departments/:departmentId/:categoryId?/gallery",
                    name: "ProjectPhotoDetailGallery",
                    component: ProjectPhotoDetailGallery,
                  },
                ],
              },
              {
                path: "/projects/:projectId/details",
                name: "ProjectDetails",
                component: ProjectDetails,
              },
              {
                path: "/projects/:projectId/team",
                name: "ProjectTeam",
                component: ProjectTeam,
              },
            ],
          },
        ],
      },
      {
        path: "/account-settings",
        name: "AccountSettings",
        component: AccountSettings,
      },
      {
        path: "/companies/:companyId/users",
        name: "Users",
        component: Users,
        props: true,
      },
      {
        path: "/companies/:companyId",
        name: "Company",
        component: CompanyAccount,
        props: true,
      },
      {
        path: "/photos",
        name: "Photos",
        component: Photos,
      },
      {
        path: "/map",
        name: "ViewMap",
        component: ViewMap,
      },
    ],
  },
  {
    path: "/share/companies/:companyId/projects/:projectId",
    name: "ShareRoleBasedDashboard",
    component: ShareRoleBasedDashboard,
    children: [
      {
        path: "/share/companies/:companyId/projects/:projectId/",
        name: "ShareProjectDashboard",
        component: ShareProjectDashboard,
        children: [
          {
            path: "/share/companies/:companyId/projects/:projectId/",
            name: "ShareProjectDepartmentPhotos",
            component: ShareProjectDepartmentPhotos,
          },
          {
            path: "/share/companies/:companyId/projects/:projectId/departments/:departmentId/",
            name: "ShareProjectDeptCategoryPhotos",
            component: ShareProjectDeptCategoryPhotos,
          },
          {
            path: "/share/companies/:companyId/projects/:projectId/departments/no-department",
            name: "ShareNoDeptProjectPhotoDetail",
            component: ShareNoDeptProjectPhotoDetail,
            children: [
              {
                path: "/share/companies/:companyId/projects/:projectId/departments/no-department/no-dept-map",
                name: "ShareNoDeptProjectPhotoDetailMap",
                component: ShareProjectPhotoDetailMap,
              },
              {
                path: "/share/companies/:companyId/projects/:projectId/departments/no-department/no-dept-gallery",
                name: "ShareNoDeptProjectPhotoDetailGallery",
                component: ShareProjectPhotoDetailGallery,
              },
            ],
          },
          {
            path: "/share/companies/:companyId/projects/:projectId/departments/:departmentId/:categoryId?",
            name: "ShareProjectPhotoDetail",
            component: ShareProjectPhotoDetail,
            children: [
              {
                path: "/share/companies/:companyId/projects/:projectId/departments/:departmentId/:categoryId?/map",
                name: "ShareProjectPhotoDetailMap",
                component: ShareProjectPhotoDetailMap,
              },
              {
                path: "/share/companies/:companyId/projects/:projectId/departments/:departmentId/:categoryId?/gallery",
                name: "ShareProjectPhotoDetailGallery",
                component: ShareProjectPhotoDetailGallery,
              },
            ],
          },
          {
            path: "/share/companies/:companyId/projects/:projectId/details",
            name: "ShareProjectDetails",
            component: ShareProjectDetails,
          },
        ],
      },
    ],
  },
  {
    path: "/share/companies/:companyId/photo-collections/",
    name: "SharePhotoRoleDashboard",
    component: SharePhotoRoleDashboard,
    children: [
      {
        path: "/share/companies/:companyId/photo-collections/:photoCollectionId",
        name: "SharePhotos",
        component: SharePhotos,
      },
    ],
  },
]

const UNPROTECTED_ROUTES = [
  "Login",
  "PrivacyPolicy",
  "TOC",
  "SignUp",
  "ForgotPassword",
  "ShareRoleBasedDashboard",
  "ShareProjectsContainer",
  "ShareProjectDashboard",
  "ShareProjectDepartmentPhotos",
  "ShareProjectDeptCategoryPhotos",
  "ShareProjectPhotoDetailMap",
  "ShareProjectPhotoDetailGallery",
  "ShareProjectDetails",
  "SharePhotoRoleDashboard",
  "SharePhotos",
  "ShareNoDeptProjectPhotoDetail",
  "ShareNoDeptProjectPhotoDetailMap",
  "ShareNoDeptProjectPhotoDetailGallery",
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

const userMustBeLoggedIn = (routeName?: string | null) => !routeName || !UNPROTECTED_ROUTES.includes(routeName)

router.beforeEach(async (to, from, next) => {
  if (userMustBeLoggedIn(to.name) && getCurrentAuthUser() === null) {
    return next({ name: "Login" })
  }
  if (to.query.invitedToCompanyId) {
    const { projectId } = to.params
    const { invitedToCompanyId } = to.query
    await resetWorkspace(invitedToCompanyId)
    return next({
      name: "ProjectDepartmentPhotos",
      params: { projectId },
    })
  }
  return next()
})

export default router
