








import Vue from "vue"
import { getCssVariableValue } from "./util/getCssVariableValue"

export default Vue.extend({
  beforeMount() {
    // Sync color palette variables with the vuetify theme so we can use css variables and vuetify theme colors interchangeably
    this.$vuetify.theme.themes.light.primary = getCssVariableValue("--primary")
    this.$vuetify.theme.themes.light.secondary = getCssVariableValue("--secondary")
  },
})
